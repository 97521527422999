import { Timestamp } from "firebase/firestore";

export type SBM = {
    template: Template;
    schedules: Array<Timestamp | null>;
};

export enum MESSAGES_TYPES {
    RSVP = "RSVP",
    REMINDER = "REMINDER",
    THANKS = "THANKS",
}

export enum RSVP_TEMPLATES {
    BASIC_V1 = "rsvp_basic_test",
    // BASIC = "heena_rsvp_basic",
    // EXCITING = "heena_exciting_rsvp",
}
export enum REMINDER_TEMPLATES {
    BASIC = "reminder_basic",
    // BASIC = "heena_reminder_basic",
    // EXCITING = "heena_exciting_reminder",
}
export enum THANKS_TEMPLATES {
    BASIC = "thanks_basic",
    // BASIC = "heena_thanks_basic",
    // EXCITING = "thanks_exciting",
    // EXCITING = "heena_thanks_exciting",
}

export type Template = RSVP_TEMPLATES | REMINDER_TEMPLATES | THANKS_TEMPLATES;

export type EventData = {
    guests: { [key: string]: Guest };
    plan: PLANS;
    brideName: string | null;
    groomName: string | null;
    weddingDate: Timestamp | null;
    welcomeTime: string | null;
    ceremonyTime: string | null;
    functionHallName: string | null;
    functionHallAddress: string | null;
    payboxLink: string | null;
    inviteImage: string | null;
    rsvp: SBM;
    reminder: SBM;
    thanks: SBM;
    createdAt: Timestamp;
    owner: string;
    active: boolean;
    numOfContactsPaidFor?: number;
    paidPlan?: PLANS;
};

export type Guest = {
    name: string;
    // phone: string;
    validPhoneNumber: boolean;
    sent: boolean;
    expectedAmount: number;
    arriving: "yes" | "no" | "maybe" | null;
    amount: number | null;
    tableNumber: string | null;
    relationship: GUESTS_RELATIONSHIPS | null;
    sendRSVP: boolean;
};

export enum GUESTS_RELATIONSHIPS {
    FAMILY = "FAMILY",
    FRIEND = "FRIEND",
    WORK = "WORK",
    OTHER = "OTHER",
}

export enum PLANS {
    BASIC = "BASIC",
    PLUS = "PLUS",
    PREMIUM = "PREMIUM",
}

export type UserData = {
    email: string | null;
    eventId: string;
};

export type WeddingValues = {
    brideName: string | null;
    groomName: string | null;
    weddingDate: Timestamp | null;
    rsvpTemplate: RSVP_TEMPLATES;
    reminderTemplate: REMINDER_TEMPLATES;
    thanksTemplate: THANKS_TEMPLATES;
    functionHallName: string | null;
    functionHallAddress: string | null;
    welcomeTime: string | null;
    ceremonyTime: string | null;
    inviteImage: string | null;
    payboxLink: string | null;
};
