import {
    CheckIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import Calendar from "react-calendar";
import { PLANS } from "../types";
import { makeJerusalemTime, modulo } from "../utils";
import "./Calendar.css";
import CustomDisclosure from "./CustomDisclosure";
import PlanUpgrade from "./PlanUpgrade";

const ALLOWED_HOURS = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

export default function SchedulePicker({
    dateValue,
    weddingDate,
    title,
    show,
    minDate,
    maxDate,
    onChange,
    setPlan,
    id,
    disabled = false,
}: {
    dateValue: Timestamp | null | undefined;
    weddingDate: Timestamp;
    title: string;
    show: boolean;
    minDate: Date;
    maxDate: Date;
    onChange: (date: Date) => void;
    setPlan: (plan: PLANS) => void;
    id: string;
    disabled?: boolean;
}) {
    const daysBeforeWedding = dateValue
        ? Math.ceil(
              (new Date(
                  dateValue.toDate().getFullYear(),
                  dateValue.toDate().getMonth(),
                  dateValue.toDate().getDate()
              ).getTime() -
                  new Date(
                      weddingDate.toDate().getFullYear(),
                      weddingDate.toDate().getMonth(),
                      weddingDate.toDate().getDate()
                  ).getTime()) /
                  (1000 * 60 * 60 * 24)
          )
        : undefined;

    const localTimeOffset = new Date().getTimezoneOffset() / 60;
    const jerusalemOffset = moment.tz("Asia/Jerusalem").utcOffset() / 60;
    const hoursToSubtract = localTimeOffset + jerusalemOffset;

    const sentAlready = dateValue
        ? dateValue.toDate() < new Date() && disabled
        : false; //! handle better...

    return (
        <div className="pb-6 border-b border-slate-200/50">
            <div className="flex flex-col gap-4">
                <CustomDisclosure
                    disabled={sentAlready}
                    // initialOpen={id === "firstRSVP"}
                    button={
                        <label className="text-lg font-medium flex justify-between text-start">
                            <div>
                                <div className="flex gap-3 items-center">
                                    <h6>{title}</h6>{" "}
                                    {sentAlready ? (
                                        <div className="rounded-full bg-primary-light text-slate-50 flex gap-0.5 h-5 text-xs px-2 py-2 font-medium items-center justify-center">
                                            <div className="flex items-center">
                                                <CheckIcon className="h-3 w-3" />
                                                <CheckIcon className="h-3 w-3 -mr-2" />
                                            </div>
                                            <span>נשלח</span>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="flex gap-1 text-slate-600 text-base">
                                    {show ? (
                                        dateValue ? (
                                            <>
                                                {dateValue
                                                    ?.toDate()
                                                    ?.toLocaleDateString("he")}
                                                <span>&#8226;</span>
                                                <div className="">
                                                    {daysBeforeWedding === 0 ? (
                                                        <div>ביום האירוע</div>
                                                    ) : daysBeforeWedding ? (
                                                        <div>
                                                            {Math.abs(
                                                                daysBeforeWedding
                                                            ) === 1
                                                                ? null
                                                                : Math.abs(
                                                                      daysBeforeWedding
                                                                  )}{" "}
                                                            {Math.abs(
                                                                daysBeforeWedding
                                                            ) === 1
                                                                ? "יום"
                                                                : "ימים"}{" "}
                                                            {daysBeforeWedding >
                                                            0
                                                                ? "אחרי"
                                                                : "לפני"}{" "}
                                                            האירוע
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </>
                                        ) : (
                                            <div>בחרו תאריך לשליחה</div>
                                        )
                                    ) : (
                                        "יש לשדרג את החבילה"
                                    )}
                                </div>
                            </div>
                        </label>
                    }
                    children={
                        show ? (
                            <div className="flex flex-col gap-2 items-center">
                                <Calendar
                                    tileDisabled={({ date }) =>
                                        date.getDay() === 6
                                    }
                                    locale="he"
                                    minDate={minDate}
                                    className="rounded-lg font-poppins"
                                    maxDate={maxDate}
                                    next2Label={null}
                                    prev2Label={null}
                                    prevLabel={
                                        <ChevronRightIcon className="w-4 h-4 m-auto disabled:text-slate-100" />
                                    }
                                    nextLabel={
                                        <ChevronLeftIcon className="w-4 h-4 m-auto" />
                                    }
                                    onChange={(value) => {
                                        if (disabled) {
                                            alert(
                                                "לא ניתן לשנות את תאריך השליחה לאחר הפעלת האירוע"
                                            );
                                            return;
                                        }
                                        if (sentAlready) {
                                            alert(
                                                "זמן השליחה עבר כבר, לא ניתן לשנות אותו"
                                            );
                                            return;
                                        }
                                        const date = value as Date;
                                        const day = date.getDay();
                                        if (day === 6) {
                                            alert("לא ניתן לשלוח הודעות בשבת");
                                            return;
                                        }
                                        const hour =
                                            dateValue?.toDate().getHours() ?? 9;
                                        const jerusalemDate = makeJerusalemTime(
                                            date,
                                            day === 5 && hour > 13
                                                ? "13"
                                                : hour.toString()
                                        );
                                        onChange(jerusalemDate);
                                    }}
                                    value={
                                        dateValue
                                            ? new Date(dateValue.toDate())
                                            : null
                                    }
                                />
                                <div className="w-full max-w-[350px] bg-rose-50 rounded-lg p-2 flex gap-2">
                                    <label
                                        htmlFor={`${title}-time`}
                                        className="text-base font-medium"
                                    >
                                        שעת השליחה:
                                    </label>
                                    <select
                                        disabled={disabled}
                                        id={`${title}-time`}
                                        className="bg-rose-50 font-poppins"
                                        value={
                                            dateValue
                                                ? modulo(
                                                      dateValue
                                                          .toDate()
                                                          .getHours() +
                                                          hoursToSubtract,
                                                      24
                                                  )
                                                : 9
                                        }
                                        onChange={(e) => {
                                            if (!dateValue) return;
                                            const selectedTime = e.target.value;
                                            const date = makeJerusalemTime(
                                                dateValue.toDate(),
                                                selectedTime
                                            );
                                            const isFriday =
                                                date.getDay() === 5;
                                            if (
                                                isFriday &&
                                                Number(selectedTime) > 13
                                            ) {
                                                alert(
                                                    "ניתן לשלוח הודעות עד השעה 13:00 ביום שישי"
                                                );
                                                return;
                                            }
                                            onChange(date);
                                        }}
                                    >
                                        {ALLOWED_HOURS.map((hour) => (
                                            <option key={hour} value={hour}>
                                                {hour
                                                    .toString()
                                                    .padStart(2, "0") + ":00"}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {/* <p className="py-4 text-slate-700">
                                    יש לשדרג את החבילה בשביל שנשלח את ההודעה הזו
                                </p> */}
                                <div className="flex items-center justify-center">
                                    <PlanUpgrade setPlan={setPlan} />
                                </div>
                            </div>
                        )
                    }
                />
            </div>
        </div>
    );
}
