import { createContext } from "react";
import useEventData from "../hooks/useEventData";
import { EventData } from "../types";

type EventContextType = {
    event: (EventData & { eventId: string | undefined }) | undefined;
    loading: boolean;
    error: unknown;
};

export const EventContext = createContext<EventContextType>({
    event: undefined,
    loading: false,
    error: null,
});

export default function EventContextProvider({
    eventId,
    children,
}: {
    eventId: string | undefined;
    children: React.ReactNode;
}) {
    const { eventData, loading, error } = useEventData({ eventId });

    return (
        <EventContext.Provider
            value={{ event: { ...eventData, eventId }, loading, error }}
        >
            {children}
        </EventContext.Provider>
    );
}
