import {
    ArrowLeftIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { signInAnonymously } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import gsap from "gsap";
import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import Confetti from "react-confetti";
import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate } from "react-router";
import LabeledInput from "../components/LabeledInput";
import Link from "../components/Link";
import Loader from "../components/Loader";
import { registerUser } from "../db/utils";
import { auth } from "../firebase";
import useAlert from "../hooks/useAlert";
import useTrack from "../hooks/useTrack";
import {
    EventData,
    PLANS,
    REMINDER_TEMPLATES,
    RSVP_TEMPLATES,
    THANKS_TEMPLATES,
} from "../types";
import {
    addDaysToDate,
    changeUndefinedToNull,
    createSchedulesDates,
    localStorageDateToTimestamp,
    makeJerusalemTime,
} from "../utils";

export default function InitPage() {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const [showEndText, setShowEndText] = useState(false);
    const [showNameInputs, setShowNameInputs] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const [weddingDetails, setWeddingDetails] = useState<{
        weddingDate: Timestamp | null;
        brideName: string;
        groomName: string;
    }>({
        weddingDate: null,
        brideName: "",
        groomName: "",
    });
    const { isAlerted } = useAlert();

    // useLayoutEffect(() => {
    //     const ctx = gsap.context(() => {
    //         gsap.timeline()
    //             .from(["#init-heading"], {
    //                 autoAlpha: 0,
    //                 delay: 0.4,
    //                 duration: 0.9,
    //             })
    //             .from(["#init-paragraph"], {
    //                 autoAlpha: 0,
    //                 delay: 0.4,
    //                 duration: 0.9,
    //             })
    //             .from(
    //                 ["#init-form-names", "#init-form-date"],
    //                 {
    //                     autoAlpha: 0,
    //                     duration: 0.7,
    //                 },
    //                 "<"
    //             )
    //             .from(["#init-form-btn"], {
    //                 autoAlpha: 0,
    //                 duration: 0.7,
    //                 delay: 0.9,
    //             })
    //             .from("#init-paragraph-unferline", {
    //                 width: 0,
    //                 duration: 0.65,
    //                 delay: 0.5,
    //                 ease: "power2.out",
    //             });
    //     });

    //     return () => ctx.revert();
    // }, []);

    const { logEvent } = useTrack();

    useEffect(() => {
        const storedValues = localStorage.getItem("weddingValues");
        if (storedValues) {
            const { weddingDate, brideName, groomName } =
                JSON.parse(storedValues);
            setWeddingDetails({
                brideName,
                groomName,
                weddingDate: localStorageDateToTimestamp(weddingDate),
            });
            logEvent({
                data: {
                    event: "init_page_view",
                    details: changeUndefinedToNull({
                        brideName,
                        groomName,
                        weddingDate: weddingDate
                            ? localStorageDateToTimestamp(weddingDate)?.toDate()
                            : null,
                    }),
                },
            });
        } else {
            logEvent({
                data: {
                    event: "init_page_view",
                },
            });
        }
    }, [logEvent]);

    useEffect(() => {
        if (
            weddingDetails.weddingDate ||
            weddingDetails.brideName ||
            weddingDetails.groomName
        ) {
            localStorage.setItem(
                "weddingValues",
                JSON.stringify(weddingDetails)
            );
        }
    }, [weddingDetails]);

    const onValuesChange = (key: string, value: any) => {
        setWeddingDetails((prev) => ({ ...prev, [key]: value }));
    };

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        // const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!weddingDetails.weddingDate) {
                await logEvent({
                    data: {
                        event: "submit_partial_data",
                        details: changeUndefinedToNull({
                            weddingDate: null,
                        }),
                    },
                });
                await isAlerted("אנחנו חייבים לדעת מתי תאריך החתונה");
                return;
            }
            setShowLoader(true);
            logEvent({
                data: {
                    event: "submit_success",
                    brideName: weddingDetails.brideName ?? null,
                    groomName: weddingDetails.groomName ?? null,
                    weddingDate: weddingDetails.weddingDate?.toDate(),
                },
            });
            const userCredential = await signInAnonymously(auth);
            if (userCredential) {
                const { uid } = userCredential.user;
                const userData = {
                    email: null,
                };

                const plan = localStorage.getItem("plan") ?? PLANS.PLUS;

                const {
                    twoWeeksBefore,
                    oneWeekBefore,
                    oneDayAfter,
                    oneDayBefore,
                } = createSchedulesDates({
                    weddingDate: weddingDetails.weddingDate,
                });

                const eventData = {
                    groomName: weddingDetails.groomName?.trim() ?? null,
                    brideName: weddingDetails.brideName?.trim() ?? null,
                    weddingDate: weddingDetails.weddingDate,
                    createdAt: Timestamp.now(),
                    owner: uid,
                    plan,
                    guests: {},
                    rsvp: {
                        template: RSVP_TEMPLATES.BASIC_V1,
                        schedules: [
                            Timestamp.fromDate(twoWeeksBefore),
                            Timestamp.fromDate(oneWeekBefore),
                        ],
                    },
                    reminder: {
                        template: REMINDER_TEMPLATES.BASIC,
                        schedules: [Timestamp.fromDate(oneDayBefore)],
                    },
                    thanks: {
                        template: THANKS_TEMPLATES.BASIC,
                        schedules: [Timestamp.fromDate(oneDayAfter)],
                    },
                    active: false,
                } as EventData;

                const { eventId } = await registerUser({
                    eventData,
                    uid,
                    userData,
                });

                localStorage.removeItem("weddingValues");
                localStorage.removeItem("plan");
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                navigate(`/event/${eventId}`);
                setShowLoader(false);
                // setShowEndText(true);
                // setTimeout(() => {
                // navigate(`/event/${eventId}`);
                // }, 4500);
            }
        } catch (e) {
            console.error("submit_error", e);
            setShowLoader(false);
        }
    };

    const { weddingDate, brideName, groomName } = weddingDetails;

    if (showLoader) {
        return <Loader fullScreen />;
    }

    return (
        <div className="py-10 px-5 w-full h-screen bg-primary/60">
            {showNameInputs ? (
                <div className="w-full lg:w-[400px] lg:mx-auto h-full flex flex-col gap-5 items-center justify-center">
                    <div className="relative w-full flex flex-row items-center justify-center">
                        <button
                            className="absolute top-0 right-4 h-full flex items-center justify-center"
                            onClick={() => setShowNameInputs(false)}
                        >
                            <ChevronRightIcon className="w-6 h-6 text-slate-100" />
                        </button>
                        <h1 className="text-3xl font-semibold text-white">
                            מי מתחתן?
                        </h1>
                    </div>
                    <div
                        id="init-form-names"
                        className="flex flex-col w-full gap-7 text-white"
                    >
                        <LabeledInput
                            value={brideName ?? ""}
                            onChange={(e) =>
                                onValuesChange("brideName", e.target.value)
                            }
                            label="שם הכלה"
                            placeholder="שם הכלה"
                            maxLength={40}
                            id="brideName"
                            type="text"
                            lightMode
                        />
                        <LabeledInput
                            value={groomName ?? ""}
                            onChange={(e) =>
                                onValuesChange("groomName", e.target.value)
                            }
                            label="שם החתן"
                            placeholder="שם החתן"
                            maxLength={40}
                            id="groomName"
                            type="text"
                            lightMode
                        />
                    </div>
                    <button
                        id="init-form-btn"
                        className="flex w-full items-center justify-center gap-3 !mt-14 rounded-full bg-slate-50 px-3 py-2 text-primary/60 text-lg font-semibold text-center"
                        onClick={onSubmit}
                    >
                        סיום ומעבר לאפליקציה
                    </button>
                    <button
                        id="init-form-btn"
                        type="submit"
                        className="text-white w-full text-lg font-semibold"
                        onClick={onSubmit}
                    >
                        מעבר לאפליקציה בלי להכניס שמות
                    </button>
                </div>
            ) : (
                <div className="w-full lg:w-[400px] lg:mx-auto h-full flex flex-col gap-5 items-center justify-center">
                    <div className="w-full flex flex-col gap-2.5 items-center justify-center">
                        <h1 className="text-3xl font-semibold text-white">
                            מתי היום הגדול?
                        </h1>
                        <span className="text-slate-50">
                            שנדע מתי לשלוח עבורכם את אישורי ההגעה
                        </span>
                    </div>
                    <div className="relative w-full">
                        {isExploding ? (
                            <div className="absolute top-1/2 left-0 w-full flex justify-center items-center">
                                <ConfettiExplosion />
                            </div>
                        ) : null}
                        <Calendar
                            // tileDisabled={({ date }) => date.getDay() === 6}
                            defaultValue={addDaysToDate({
                                date: new Date(),
                                days: 30,
                            })}
                            locale="he"
                            minDate={new Date()}
                            className="rounded-lg font-poppins"
                            next2Label={null}
                            prev2Label={null}
                            prevLabel={
                                <ChevronRightIcon className="w-4 h-4 m-auto disabled:text-slate-100" />
                            }
                            nextLabel={
                                <ChevronLeftIcon className="w-4 h-4 m-auto" />
                            }
                            onChange={(value) => {
                                const date = value as Date;
                                const day = date.getDay();
                                if (day === 6) {
                                    alert("לא ניתן לשלוח הודעות בשבת");
                                    return;
                                }
                                const hour =
                                    weddingDate?.toDate().getHours() ?? 9;
                                const jerusalemDate = makeJerusalemTime(
                                    date,
                                    hour.toString()
                                );
                                onValuesChange(
                                    "weddingDate",
                                    Timestamp.fromDate(jerusalemDate)
                                );
                                setIsExploding(true);
                                setTimeout(() => {
                                    setIsExploding(false);
                                    setShowNameInputs(true);
                                }, 2000);
                            }}
                            value={
                                weddingDate
                                    ? new Date(weddingDate.toDate())
                                    : null
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <div className="font-assistant flex flex-col lg:items-center pt-24 px-4 sm:px-5 bg-primary/60 h-screen w-screen">
            {showEndText ? (
                <>
                    <Confetti />
                    <h1 className="text-[1.7rem] sm:text-3xl font-semibold text-white text-center mt-10">
                        הכל מוכן!
                    </h1>
                    <p className="mt-3 w-full text-slate-100 text-center font-medium text-xl">
                        מיד תועברו לעמוד האירוע שנוצר עבורכם
                    </p>
                </>
            ) : (
                <>
                    <h1
                        id="init-heading"
                        className="text-3xl sm:text-3xl font-bold text-white"
                    >
                        כל דבר טוב מתחיל בצעד קטן
                    </h1>
                    <p
                        id="init-paragraph"
                        className="text-lg text-slate-100 my-3"
                    >
                        הזינו
                        <span className="font-bold mx-1">3 פרטים</span>
                        והמערכת שלנו תתאים באופן אוטומטי את אישורי ההגעה
                        <span className="mr-1 relative">
                            המושלמים לכם
                            <span
                                id="init-paragraph-unferline"
                                className="h-1 border-t-2 absolute -bottom-0.5 right-0 w-full"
                            ></span>
                        </span>
                        {/* בעמוד הבא יופיעו אישורי ההגעה שלכם, עם הפרטים שתזינו כאן */}
                    </p>
                    <div className="w-full">
                        {showLoader ? (
                            <div className="mt-10 w-full flex flex-col items-center justify-center gap-4">
                                <Loader light />
                                <span className="text-slate-50 text-center text-lg font-medium">
                                    יוצרים את האישורים המושלמים עבורכם...
                                </span>
                            </div>
                        ) : (
                            <form
                                id="init-form"
                                className="flex flex-col space-y-8 mt-8 w-full lg:max-w-[500px] lg:mx-auto"
                                // onSubmit={onSubmit}
                            >
                                <div
                                    id="init-form-names"
                                    className="flex w-full gap-4 text-white"
                                >
                                    <LabeledInput
                                        value={brideName ?? ""}
                                        onChange={(e) =>
                                            onValuesChange(
                                                "brideName",
                                                e.target.value
                                            )
                                        }
                                        label="שם הכלה"
                                        placeholder="שם הכלה"
                                        maxLength={40}
                                        id="brideName"
                                        type="text"
                                        lightMode
                                    />
                                    <LabeledInput
                                        value={groomName ?? ""}
                                        onChange={(e) =>
                                            onValuesChange(
                                                "groomName",
                                                e.target.value
                                            )
                                        }
                                        label="שם החתן"
                                        placeholder="שם החתן"
                                        maxLength={40}
                                        id="groomName"
                                        type="text"
                                        lightMode
                                    />
                                </div>
                                <div
                                    id="init-form-date"
                                    className="w-full  min-w-[200px]"
                                >
                                    <label
                                        htmlFor="weddingDate"
                                        className="text-base font-normal leading-tight text-slate-50 after:border-gray-500"
                                    >
                                        תאריך החתונה
                                    </label>
                                    <input
                                        type="date"
                                        id="weddingDate"
                                        min={
                                            addDaysToDate({
                                                date: new Date(),
                                                days: 16,
                                            })
                                                .toISOString()
                                                .split("T")[0]
                                        }
                                        placeholder="תאריך החתונה"
                                        value={
                                            !weddingDate
                                                ? undefined
                                                : weddingDate
                                                      ?.toDate()
                                                      ?.toLocaleDateString(
                                                          "he",
                                                          {
                                                              year: "numeric",
                                                              month: "2-digit",
                                                              day: "2-digit",
                                                          }
                                                      )
                                                      .split(".")
                                                      .reverse()
                                                      .join("-")
                                        }
                                        onChange={async (e) => {
                                            const date = e.target.value
                                                ? new Date(e.target.value)
                                                : null;
                                            onValuesChange(
                                                "weddingDate",
                                                date
                                                    ? Timestamp.fromDate(date)
                                                    : null
                                            );
                                        }}
                                        className={`${
                                            !weddingDate
                                                ? "text-transparent lg:text-slate-100"
                                                : "text-slate-100"
                                        } picker peer h-9 text-right w-full border-b border-blue-gray-200 bg-transparent pt-1 pb-1.5 font-sans text-base font-normal outline outline-0 transition-all focus:border-slate-100 focus:border-b-2 focus:outline-0 disabled:bg-blue-gray-50`}
                                    />
                                </div>
                                <button
                                    id="init-form-btn"
                                    className="flex items-center justify-center gap-3 !mt-14 rounded-full bg-slate-50 px-3 py-2 text-primary/60 text-lg font-semibold text-center"
                                    type="submit"
                                >
                                    תראו לי מה יצרתם בשבילי!
                                </button>
                            </form>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

const WeddingDateStep = ({
    value,
    setValue,
}: {
    value: Date | null;
    setValue: (value: Date) => void;
}) => {
    return (
        <div className="flex flex-col gap-8 items-center pt-40 h-full w-full">
            <h1 className="text-2xl font-bold text-[#fff1f2]">
                מתי היום המיוחד שלכם?
            </h1>
            <Calendar
                // tileDisabled={({ date }) => date.getDay() === 6}
                locale="he"
                minDate={addDaysToDate({
                    date: new Date(),
                    days: 15,
                })}
                defaultActiveStartDate={addDaysToDate({
                    date: new Date(),
                    days: 60,
                })}
                className="rounded-lg font-poppins"
                next2Label={null}
                prev2Label={null}
                prevLabel={
                    <ChevronRightIcon className="w-4 h-4 m-auto disabled:text-slate-100" />
                }
                nextLabel={<ChevronLeftIcon className="w-4 h-4 m-auto" />}
                onChange={(value) => {
                    const date = value as Date;
                    const localDateString = date.toLocaleDateString("he-IL");
                    const [day, month, year] = localDateString
                        .split(".")
                        .map(Number);
                    const localDate = new Date(year, month - 1, day);
                    setValue(localDate);
                }}
                value={value}
            />
        </div>
    );
};

const BrideGroomStep = ({
    brideValue,
    groomValue,
    setBrideValue,
    setGroomeValue,
}: {
    brideValue: string;
    groomValue: string;
    setBrideValue: (value: string) => void;
    setGroomeValue: (value: string) => void;
}) => {
    const navigate = useNavigate();
    const { logEvent } = useTrack();
    const brideInputRef = useRef<HTMLInputElement>(null);

    // useEffect(() => {
    //     brideInputRef.current?.focus();
    // }, []);

    return (
        <div className="flex flex-col gap-8 items-center pt-40 h-full w-full">
            <h1 className="text-2xl font-bold text-[#fff1f2]">מי מתחתן?</h1>
            <form
                className="flex flex-col gap-8 items-center justify-center w-full lg:w-1/3 lg:mx-auto"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (!brideValue || !groomValue) {
                        alert("רק תגידו לנו מי מתחתן");
                        return;
                    }
                    logEvent({
                        data: {
                            event: "bride_groom_names_submit",
                            brideName: brideValue,
                            groomName: groomValue,
                        },
                    });
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                    navigate("?step=3");
                    // navigate("/new?step=0");
                }}
            >
                <LabeledInput
                    label="שם הכלה"
                    id="bride-name"
                    type="text"
                    forwardRef={brideInputRef}
                    className="text-black bg-transparent"
                    value={brideValue}
                    onChange={(e) => setBrideValue(e.target.value)}
                    lightMode
                    required
                />
                <LabeledInput
                    label="שם החתן"
                    id="groom-name"
                    type="text"
                    className="text-black bg-transparent"
                    value={groomValue}
                    lightMode
                    onChange={(e) => setGroomeValue(e.target.value)}
                    required
                />
                <button
                    className="flex gap-2 items-center justify-center text-2xl font-semibold mt-10"
                    type="submit"
                >
                    סיימתי, תראו לי מה יצא
                    <ArrowLeftIcon className="w-5 h-5 stroke-2" />
                </button>
            </form>
        </div>
    );
};

const FinishScreen = () => {
    const [canProceed, setCanProceed] = useState(false);
    const [headingText, setHeadingText] = useState(
        "יוצרים עבורכם את ההודעה המושלמת..."
    );

    const containerRef = useRef(null);

    useEffect(() => {
        let ctx = gsap.context(() => {
            gsap.timeline()
                .from(["#finish-heading-text"], {
                    autoAlpha: 0,
                    y: 50,
                    skewY: -2,
                    duration: 0.5,
                })
                .from(
                    ["#finish-screen-link"],
                    {
                        autoAlpha: 0,
                        duration: 1.5,
                    },
                    1
                );
        });

        return () => ctx.revert();
    }, [containerRef, headingText]);

    useEffect(() => {
        const a = setTimeout(() => {
            setHeadingText("בוחרים את תאריכי השליחה האופטימליים...");
        }, 3000);

        const b = setTimeout(() => {
            setHeadingText("האישורים שלכם מוכנים!");
            setCanProceed(true);
        }, 6000);

        return () => {
            clearTimeout(a);
            clearTimeout(b);
        };
    }, []);

    return (
        <div
            ref={containerRef}
            className="flex flex-col gap-5 items-center justify-center h-full w-full"
        >
            {canProceed ? <Confetti /> : null}
            <h1
                id="finish-heading-text"
                className="text-2xl font-semibold text-center"
            >
                {headingText}
                {canProceed ? (
                    <Link
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        }}
                        id="finish-screen-link"
                        className="flex gap-2 items-center justify-center text-xl font-semibold mt-4"
                        to="/new?step=0"
                    >
                        בואו תראו
                        <ArrowLeftIcon className="w-5 h-5 stroke-2" />
                    </Link>
                ) : null}
            </h1>
            <div className="mt-2">{canProceed ? null : <Loader />}</div>
        </div>
    );
};
