import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { WeddingValues } from "../types";
import { formatDate } from "../utils";

export const BasicRSVP = ({
    groomName,
    brideName,
    weddingDate,
    functionHallName,
    functionHallAddress,
    welcomeTime,
    payboxLink,
    brideNameInputRef,
    groomNameInputRef,
    weddingDateInputRef,
    functionHallNameInputRef,
    functionHallAddressInputRef,
}: Partial<WeddingValues> & {
    brideNameInputRef: React.RefObject<HTMLInputElement>;
    groomNameInputRef: React.RefObject<HTMLInputElement>;
    weddingDateInputRef: React.RefObject<HTMLInputElement>;
    functionHallNameInputRef: React.RefObject<HTMLInputElement>;
    functionHallAddressInputRef: React.RefObject<HTMLInputElement>;
}) => {
    return (
        <div className="p-1 leading-6">
            אורחים וחברים יקרים,
            <br />
            קיבלתם הזמנה לחתונה של{" "}
            <CoupleNames
                {...{
                    brideName,
                    groomName,
                    brideNameInputRef,
                    groomNameInputRef,
                }}
            />
            <br />
            <br />
            <Details
                {...{
                    weddingDate: weddingDate?.toDate(),
                    welcomeTime,
                    hallName: functionHallName,
                    hallAddress: functionHallAddress,
                    weddingDateInputRef,
                    payboxLink,
                }}
            />
            <br />
            {ending()}
        </div>
    );
};
export const ExcitingRSVP = ({
    groomName,
    brideName,
    weddingDate,
    functionHallName,
    functionHallAddress,
    welcomeTime,
    brideNameInputRef,
    groomNameInputRef,
    weddingDateInputRef,
    functionHallNameInputRef,
    functionHallAddressInputRef,
}: Partial<WeddingValues> & {
    brideNameInputRef: React.RefObject<HTMLInputElement>;
    groomNameInputRef: React.RefObject<HTMLInputElement>;
    weddingDateInputRef: React.RefObject<HTMLInputElement>;
    functionHallNameInputRef: React.RefObject<HTMLInputElement>;
    functionHallAddressInputRef: React.RefObject<HTMLInputElement>;
}) => {
    return (
        <div className="p-1 leading-6">
            איזו התרגשות!
            <br />
            הנכם מוזמנים לחתונה של{" "}
            <UnderlineEmpty
                forwardRef={brideNameInputRef}
                text={brideName}
                placeholder="שם הכלה"
            />{" "}
            <span
                className={`${groomName ? "font-semibold" : ""} inline-block`}
            >
                ו
                <UnderlineEmpty
                    forwardRef={groomNameInputRef}
                    text={groomName}
                    placeholder="שם החתן"
                />
            </span>{" "}
            שתתקיים בתאריך{" "}
            <UnderlineEmpty
                forwardRef={weddingDateInputRef}
                text={weddingDate?.toDate().toLocaleDateString("he-IL")}
                placeholder="תאריך החתונה"
            />
            {functionHallName && functionHallAddress ? (
                <div>
                    <span> האירוע יתקיים באולם {functionHallName}</span>
                    <span>
                        {" "}
                        <br /> בכתובת {functionHallAddress}
                    </span>
                </div>
            ) : null}
            {welcomeTime ? (
                functionHallName && functionHallAddress ? (
                    <span>בשעה {welcomeTime}</span>
                ) : (
                    <>
                        <br />
                        <span>האירוע יתקיים בשעה {welcomeTime}</span>
                    </>
                )
            ) : null}
            <div>
                נשמח שתבואו לחגוג איתנו - אנא אשרו הגעתכם בכפתורים המתאימים
            </div>
        </div>
    );
};

export const UnderlineEmpty = ({
    text,
    placeholder,
    forwardRef,
    bold = true,
}: {
    text?: string | null;
    placeholder: string;
    forwardRef?: React.RefObject<HTMLInputElement>;
    bold?: boolean;
}) => {
    return (
        <div className="inline" onClick={() => forwardRef?.current?.focus()}>
            {!text ? (
                <span className="text-slate-500/80 inline-block ml-0.5 cursor-pointer">
                    {placeholder}
                </span>
            ) : (
                <span className={`${bold ? "font-semibold" : ""} max-w-full`}>
                    {text}
                </span>
            )}
        </div>
    );
};

export const ending = () => `אנא אשרו הגעה בכפתורים למטה.
מצפים ומתרגשים לראותכם ❤️`;

export const Details = ({
    weddingDate,
    welcomeTime,
    hallName,
    hallAddress,
    weddingDateInputRef,
    payboxLink,
}: {
    weddingDate?: Date | null;
    welcomeTime?: string | null;
    hallName?: string | null;
    hallAddress?: string | null;
    weddingDateInputRef?: React.RefObject<HTMLInputElement>;
    payboxLink?: string | null;
}) => {
    const base = (
        <>
            החתונה תתקיים בתאריך{" "}
            {UnderlineEmpty({
                text: formatDate(weddingDate),
                placeholder: "תאריך החתונה",
                forwardRef: weddingDateInputRef,
                bold: false,
            })}
        </>
    );
    const time = welcomeTime ? (
        <>
            , בשעה{" "}
            {UnderlineEmpty({
                text: welcomeTime,
                placeholder: "שעת קבלת פנים",
                bold: false,
            })}
        </>
    ) : null;
    const hall =
        hallName && hallAddress ? (
            <>
                , ב-"
                {UnderlineEmpty({
                    text: hallName.trim(),
                    placeholder: "שם האולם",
                    bold: false,
                })}
                ", {hallAddress.trim()}
            </>
        ) : null;

    const paybox = payboxLink ? (
        <div className="flex gap-2 items-center max-w-full">
            {payboxLink.includes("%") ? (
                <ExclamationCircleIcon
                    title='הקישור לא יכול להכיל את התו "%"'
                    className="text-red-500 w-5 h-5 flex-shrink-0"
                />
            ) : null}
            <div className="mt-4 max-w-full">
                <span className="block text-start">
                    לנוחיותכם מצורף קישור להשארת מתנה באפליקציית PayBox:{" "}
                </span>
                <a
                    href={payboxLink}
                    className="underline max-w-full block overflow-x-auto text-start"
                    target="_blank"
                    rel="noreferrer"
                >
                    {payboxLink.trim()}
                </a>
            </div>
        </div>
    ) : null;
    return (
        <div>
            {base}
            {time}
            {hall}.{paybox}
        </div>
    );
};

export const CoupleNames = ({
    brideName,
    groomName,
    brideNameInputRef,
    groomNameInputRef,
    withEndEmoji = true,
}: {
    brideName?: string | null;
    groomName?: string | null;
    brideNameInputRef?: React.RefObject<HTMLInputElement>;
    groomNameInputRef?: React.RefObject<HTMLInputElement>;
    withEndEmoji?: boolean;
}) => {
    return (
        <>
            <UnderlineEmpty
                forwardRef={brideNameInputRef}
                text={brideName}
                placeholder="שם הכלה"
            />{" "}
            <span
                className={`${groomName ? "font-semibold" : ""} inline-block`}
            >
                ו
                <UnderlineEmpty
                    forwardRef={groomNameInputRef}
                    text={groomName}
                    placeholder="שם החתן"
                />
            </span>
            {withEndEmoji ? " 🥂" : null}
        </>
    );
};
