import {
    arrayUnion,
    doc,
    getDoc,
    setDoc,
    Timestamp,
    updateDoc,
} from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { TRACKS_COLLECTION } from "../constants";
import { db } from "../firebase";

export default function useTrack() {
    const [trackId, setTrackId] = useState<string | undefined>(undefined);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (trackId) return;
        const trId =
            searchParams.get("trId") ?? localStorage.getItem("trId") ?? uuid();
        localStorage.setItem("trId", trId);
        setTrackId(trId);
    }, [searchParams, trackId]);

    const logEvent = useCallback(
        async ({ data }: { data: object }) => {
            try {
                if (!trackId) return;
                const trackDoc = await getDoc(
                    doc(db, TRACKS_COLLECTION, trackId)
                );
                if (!trackDoc.exists()) {
                    await setDoc(doc(db, TRACKS_COLLECTION, trackId), {});
                }
                await updateDoc(doc(db, TRACKS_COLLECTION, trackId), {
                    events: arrayUnion({
                        ...data,
                        date: Timestamp.now(),
                    }),
                });
            } catch (e) {
                console.error("logEvent", e);
            }
        },
        [trackId]
    );
    return { logEvent };
}
