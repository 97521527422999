import { Dialog, Transition } from "@headlessui/react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
    EmailAuthProvider,
    linkWithCredential,
    linkWithPopup,
} from "firebase/auth";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { Fragment, useState } from "react";
import { USERS_COLLECTION } from "../constants";
import { auth, db, googleProvider } from "../firebase";
import LabeledInput from "./LabeledInput";
import Link from "./Link";
import Loader from "./Loader";

export default function RegisterAndLinkAccountPopup({
    buttonText = "התחברות ושמירת הפרטים",
    buttonClassName = "btn-primary w-full",
}: {
    buttonText?: string;
    buttonClassName?: string;
}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [registering, setRegistering] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const onRegister = async ({
        registerMethod,
    }: {
        registerMethod: "email" | "google";
    }) => {
        if (!auth.currentUser) {
            alert("אירעה שגיאה, אנא צרו איתנו קשר");
            return;
        }
        setRegistering(true);
        try {
            let userCredentials;
            if (registerMethod === "email") {
                if (!email || !password) {
                    alert("יש למלא כתובת אימייל וסיסמה");
                    setRegistering(false);

                    return;
                }
                if (password.length < 6) {
                    alert("הסיסמה צריכה להיות באורך של 6 תווים לפחות");
                    setRegistering(false);

                    return;
                }
                const authCredential = EmailAuthProvider.credential(
                    email,
                    password
                );
                userCredentials = await linkWithCredential(
                    auth.currentUser,
                    authCredential
                );
            } else if (registerMethod === "google") {
                userCredentials = await linkWithPopup(
                    auth.currentUser,
                    googleProvider
                );
            }

            if (userCredentials) {
                const { email, uid } = userCredentials.user;
                await updateDoc(doc(db, USERS_COLLECTION, uid), {
                    email,
                    linkedAt: Timestamp.now(),
                });
                setRegistering(false);
                setIsOpen(false);
                window.location.reload(); // to refresh the user state
            }
        } catch (error) {
            console.error(error);
            alert(
                "ההתחברות לא הצליחה, יש לבדוק את הפרטים שהזנתם. אם כבר נרשמתם בעבר - יש להתנתק ולהתחבר עם פרטי ההתחברות שלכם"
            );
        }
        setRegistering(false);
    };

    return (
        <>
            <button onClick={() => setIsOpen(true)} className={buttonClassName}>
                {buttonText}
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    dir="rtl"
                    as="div"
                    className="font-heebo relative z-50"
                    onClose={() => setIsOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-slate-50 p-6 text-start align-middle shadow-xl transition-all">
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        className="absolute top-3 left-3"
                                    >
                                        <XMarkIcon className="w-5 h-5" />
                                    </button>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-center text-2xl lg:text-3xl font-medium leading-6 text-gray-900"
                                    >
                                        הרשמה
                                    </Dialog.Title>
                                    <p className="mt-3">
                                        נרשמים בשנייה וכל הפרטים שלכם ישמרו גם
                                        לפעם הבאה
                                    </p>
                                    <div className="mt-10">
                                        <div className="flex flex-col gap-6 lg:gap-6">
                                            {registering ? (
                                                <Loader />
                                            ) : (
                                                <>
                                                    <button
                                                        onClick={() =>
                                                            onRegister({
                                                                registerMethod:
                                                                    "google",
                                                            })
                                                        }
                                                        type="button"
                                                        className="btn-primary gap-2"
                                                    >
                                                        <svg
                                                            className="w-4 h-4"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fab"
                                                            data-icon="google"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 488 512"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                                                            ></path>
                                                        </svg>
                                                        הרשמה עם Google
                                                    </button>
                                                    <div className=" text-slate-400 flex gap-3 items-center">
                                                        <hr className="w-full border-slate-400" />
                                                        <span className="w-20">
                                                            או
                                                        </span>
                                                        <hr className="w-full border-slate-400" />
                                                    </div>
                                                    <form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            onRegister({
                                                                registerMethod:
                                                                    "email",
                                                            });
                                                        }}
                                                        className="flex flex-col gap-4 lg:gap-6"
                                                    >
                                                        <LabeledInput
                                                            label="אימייל"
                                                            type="email"
                                                            placeholder="אימייל"
                                                            value={email}
                                                            id="email"
                                                            required
                                                            onChange={(e) =>
                                                                setEmail(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <LabeledInput
                                                            label="סיסמה"
                                                            id="password"
                                                            type="password"
                                                            autoComplete="current-password"
                                                            placeholder="סיסמה"
                                                            required
                                                            value={password}
                                                            onChange={(e) =>
                                                                setPassword(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <div>
                                                            <button
                                                                className="text-slate-900 w-full gap-2 bg-white focus:outline-none text-lg font-medium rounded-lg px-5 py-2.5 shadow-sm text-center inline-flex items-center justify-center"
                                                                type="submit"
                                                            >
                                                                <EnvelopeIcon className="w-5 h-5" />
                                                                הרשמה עם אימייל
                                                            </button>
                                                        </div>
                                                        <Link
                                                            to="/login"
                                                            className="text-xs text-slate-700 text-center"
                                                        >
                                                            רשומים כבר? לחצו כאן
                                                            להתחברות
                                                        </Link>
                                                    </form>
                                                </>
                                            )}
                                            <div className="text-center font-medium text-xs text-slate-400">
                                                הרשמה מהווה הסכמה
                                                <Link
                                                    to={`/terms`}
                                                    className="underline mx-1"
                                                >
                                                    לתנאי השימוש
                                                </Link>
                                                <Link
                                                    to={`/privacy`}
                                                    className="underline mx-1"
                                                >
                                                    ומדיניות הפרטיות
                                                </Link>
                                                שלנו
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
