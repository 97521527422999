import { useState } from "react";
import { isValidIsraeliPhoneNumber } from "../utils";
import LabeledInput from "./LabeledInput";

export default function PhoneInput({
    phone,
    setPhone,
}: {
    phone: string;
    setPhone: (phone: string) => void;
}) {
    const [error, setError] = useState(false);

    return (
        <div className="flex flex-col gap-1 w-full">
            <LabeledInput
                id="phone"
                label="מספר טלפון"
                type="tel"
                value={phone}
                placeholder="מספר טלפון"
                className="py-2 px-1 w-full text-left"
                onChange={(e) => {
                    const value = e.target.value?.replace(/\D/g, "") ?? "";
                    if (value.length > 10) return;
                    if (value.length === 10) {
                        setError(!isValidIsraeliPhoneNumber(value));
                    } else {
                        setError(false);
                    }
                    setPhone(value);
                }}
            />
            {error && <div>המספר צריך להיות מספר סלולר ישראלי</div>}
        </div>
    );
}
