import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import {
    useSendPasswordResetEmail,
    useSignInWithEmailAndPassword,
    useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import { USERS_COLLECTION } from "../constants";
import { registerUser } from "../db/utils";
import { auth, db, googleProvider } from "../firebase";
import useTrack from "../hooks/useTrack";
import { EventData, PLANS } from "../types";
import { localStorageDateToTimestamp } from "../utils";
import LabeledInput from "./LabeledInput";
import Link from "./Link";
import Loader from "./Loader";

export default function Register({ login = false }: { login?: boolean }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [registering, setRegistering] = useState(false);
    const [signInWithGoogle] = useSignInWithGoogle(auth);
    const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);
    const navigate = useNavigate();
    const [sendPasswordResetEmail, sendingEmailReset, errorEmailReset] =
        useSendPasswordResetEmail(auth);
    const { logEvent } = useTrack();

    useEffect(() => {
        logEvent({
            data: {
                event: "page_view",
                page: login ? "login" : "register",
            },
        });
    }, [logEvent, login]);

    const onRegister = async ({
        registerMethod,
    }: {
        registerMethod: "email" | "google";
    }) => {
        setRegistering(true);
        try {
            let userCredential;
            if (registerMethod === "email") {
                if (!email || !password) {
                    alert("יש למלא אימייל ושם משתמש");
                    setRegistering(false);

                    return;
                }
                if (password.length < 6) {
                    alert("הסיסמה צריכה להיות באורך של 6 תווים לפחות");
                    setRegistering(false);

                    return;
                }
                userCredential = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
            } else if (registerMethod === "google") {
                userCredential = await signInWithPopup(auth, googleProvider);
            }
            // create user, event and SBMs docs
            if (userCredential) {
                const { email, uid } = userCredential.user;
                // check if uid has event already - if yes -> don't save new values

                const userDoc = await getDoc(doc(db, USERS_COLLECTION, uid));
                if (userDoc.exists()) {
                    const resetEvent = window.confirm(
                        "כבר קיים אירוע למשתמש זה. האם למחוק אותו ולשמור את הפרטים החדשים שהזנת?"
                    );
                    if (!resetEvent) {
                        setRegistering(false);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                        navigate(`/event/${userDoc.data().eventId}`);
                        return;
                    }
                }

                const userData = {
                    email,
                };
                const weddingValues = JSON.parse(
                    localStorage.getItem("weddingValues") ?? "{}"
                );

                const schedules = JSON.parse(
                    localStorage.getItem("schedules") ?? "{}"
                );

                const plan = localStorage.getItem("plan") ?? PLANS.PLUS;

                const eventData = {
                    ...weddingValues,
                    weddingDate: localStorageDateToTimestamp(
                        weddingValues.weddingDate
                    ),
                    createdAt: Timestamp.now(),
                    owner: uid,
                    plan,
                    guests: {},
                    rsvp: {
                        template: weddingValues.rsvpTemplate,
                        schedules: [
                            localStorageDateToTimestamp(schedules.firstRSVP),
                            localStorageDateToTimestamp(schedules.secondRSVP),
                        ],
                    },
                    reminder: {
                        template: weddingValues.reminderTemplate,
                        schedules: [
                            localStorageDateToTimestamp(schedules.reminder),
                        ],
                    },
                    thanks: {
                        template: weddingValues.thanksTemplate,
                        schedules: [
                            localStorageDateToTimestamp(schedules.thanks),
                        ],
                    },
                    active: false,
                } as EventData;

                const { eventId } = await registerUser({
                    eventData,
                    uid,
                    userData,
                });

                localStorage.removeItem("weddingValues");
                localStorage.removeItem("schedules");
                localStorage.removeItem("plan");
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
                navigate(`/event/${eventId}`);
            }
            // redirect to /event
        } catch (error) {
            console.error(error);
            alert("ההתחברות לא הצליחה, יש לבדוק את הפרטים שהזנתם");
        }
        setRegistering(false);
    };

    const onLogin = async ({
        loginMethod,
    }: {
        loginMethod: "email" | "google";
    }) => {
        setRegistering(true);

        if (loginMethod === "email") {
            if (!email || !password) {
                alert("יש למלא אימייל ושם משתמש");
                setRegistering(false);

                return;
            }
            if (password.length < 6) {
                alert("הסיסמה צריכה להיות באורך של 6 תווים לפחות");
                setRegistering(false);

                return;
            }
            const userCredentioal = await signInWithEmailAndPassword(
                email,
                password
            );
            if (!userCredentioal) {
                alert("פרטי ההתחברות שגויים");
                setRegistering(false);

                return;
            }
        } else if (loginMethod === "google") {
            await signInWithGoogle();
        }
        setRegistering(false);
    };

    const onPasswordReset = async () => {
        if (!email) {
            alert("יש למלא את האימייל קודם");
            return;
        }
        try {
            await sendPasswordResetEmail(email);
        } catch (error) {
            console.error(error);
        }
    };

    if (errorEmailReset) {
        console.error(errorEmailReset);
    }

    return (
        <div className="flex flex-col gap-4 lg:gap-6 pt-5 min-h-[90vh] max-w-[400px] mx-auto">
            <div className="flex flex-col items-center justify-center gap-2">
                <h5 className="text-xl lg:text-3xl font-medium">
                    {login ? "התחברות לחשבון שלכם" : "מדהים, כמעט סיימנו!"}
                </h5>
                {login ? (
                    <Link to="/init" className="-mt-2 text-slate-700 text-sm">
                        לא רשומים עדיין? לחצו כאן להצטרפות
                    </Link>
                ) : null}
                {login ? null : (
                    <p className="">
                        עכשיו רק צריך להתחבר כדי שנשמור את הפרטים שלכם
                    </p>
                )}
            </div>
            <div className="flex flex-col gap-6 lg:gap-6 mt-10">
                {registering ? (
                    <Loader />
                ) : (
                    <>
                        <button
                            onClick={() =>
                                login
                                    ? onLogin({ loginMethod: "google" })
                                    : onRegister({ registerMethod: "google" })
                            }
                            type="button"
                            className="btn-primary gap-2"
                        >
                            <svg
                                className="w-4 h-4"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="google"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 488 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                                ></path>
                            </svg>
                            {login ? "התחברות" : "הרשמה"} עם Google
                        </button>
                        <div className=" text-slate-400 flex gap-3 items-center">
                            <hr className="w-full border-slate-400" />
                            <span className="w-20">או</span>
                            <hr className="w-full border-slate-400" />
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                login
                                    ? onLogin({ loginMethod: "email" })
                                    : onRegister({ registerMethod: "email" });
                            }}
                            className="flex flex-col gap-4 lg:gap-6"
                        >
                            <LabeledInput
                                label="אימייל"
                                type="email"
                                placeholder="אימייל"
                                value={email}
                                id="email"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <LabeledInput
                                label="סיסמה"
                                id="password"
                                type="password"
                                autoComplete="current-password"
                                placeholder="סיסמה"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {login ? (
                                sendingEmailReset ? (
                                    <Loader />
                                ) : errorEmailReset ? (
                                    <div>
                                        קרתה תקלה, יש לנסות שוב מאוחר יותר
                                    </div>
                                ) : (
                                    <button
                                        onClick={onPasswordReset}
                                        type="button"
                                        className="text-start underline text-sm"
                                    >
                                        שכחתי סיסמה
                                    </button>
                                )
                            ) : null}
                            <div>
                                <button
                                    className="text-slate-900 w-full gap-2 bg-white focus:outline-none text-lg font-medium rounded-lg px-5 py-2.5 shadow-sm text-center inline-flex items-center justify-center"
                                    type="submit"
                                >
                                    <EnvelopeIcon className="w-5 h-5" />
                                    {login ? "התחברות" : "הרשמה"} עם אימייל
                                </button>
                            </div>
                        </form>
                    </>
                )}
                {login ? null : (
                    <Link
                        to="/login"
                        className="text-slate-900  text-center -mt-2"
                    >
                        רשומים כבר? לחצו כאן להתחברות
                    </Link>
                )}
                <div className="text-center font-medium text-xs text-slate-400">
                    הרשמה מהווה הסכמה
                    <Link to={`/terms`} className="underline mx-1">
                        לתנאי השימוש
                    </Link>
                    <Link to={`/privacy`} className="underline mx-1">
                        ומדיניות הפרטיות
                    </Link>
                    שלנו
                </div>
            </div>
        </div>
    );
}
