import { useContext } from "react";
import { EventContext } from "../context/event";

export default function useEvent() {
    const ctx = useContext(EventContext);

    if (!ctx) {
        throw new Error("useEvent must be used within a ListContext.Provider");
    }

    const { event, loading, error } = ctx;
    return { event, loading, error };
}
