import { WeddingValues } from "../types";
import { CoupleNames, Details } from "./rsvp";

export const BasicReminder = ({
    groomName,
    brideName,
    weddingDate,
    functionHallName,
    functionHallAddress,
    welcomeTime,
    ceremonyTime,
    payboxLink,
}: Partial<WeddingValues>) => {
    return (
        <div className="p-1">
            שלום אורח/ת יקר/ה!
            <br />
            זוהי תזכורת לחתונה של{" "}
            <CoupleNames
                {...{
                    brideName,
                    groomName,
                }}
            />
            <br />
            <br />
            <Details
                {...{
                    weddingDate: weddingDate?.toDate(),
                    welcomeTime,
                    hallName: functionHallName,
                    hallAddress: functionHallAddress,
                    payboxLink,
                }}
            />
            <br />
            <div>מתרגשים מאוד לראותכם! 🙏</div>
        </div>
    );
};
export const ExcitingReminder = ({
    groomName,
    brideName,
    weddingDate,
    functionHallName,
    functionHallAddress,
    welcomeTime,
    ceremonyTime,
}: Partial<WeddingValues>) => {
    return (
        <div className="p-1">
            איזו התרגשות! <br />
            החתונה של <span className="font-semibold">{brideName}</span> ו
            <span className="font-semibold">{groomName}</span> מתקרב ונשמח מאוד
            לראותכם.
            <br />
            <div>
                האירוע יתקיים בתאריך{" "}
                <span className="underline">
                    {weddingDate
                        ? weddingDate.toDate().toLocaleDateString("he-IL")
                        : "תאריך החתונה"}
                </span>
                {functionHallName ? (
                    <div>
                        <span> באולם {functionHallName}</span>
                        {functionHallAddress ? (
                            <span>
                                {" "}
                                <br /> בכתובת {functionHallAddress}
                            </span>
                        ) : null}
                    </div>
                ) : null}
                {welcomeTime ? (
                    <div>
                        <span>שעת קבלת פנים: {welcomeTime}</span>
                    </div>
                ) : null}
                {/* {ceremonyTime ? (
                    <div>
                        <span>שעת חופה: {ceremonyTime}</span>
                    </div>
                ) : null} */}
            </div>
            <br />
            נתראה שם!
        </div>
    );
};

const CommonTemplate = ({
    groomName,
    brideName,
    weddingDate,
    functionHallName,
    functionHallAddress,
    welcomeTime,
    ceremonyTime,
}: Partial<WeddingValues>) => {
    return (
        <div>
            שיתקיים בתאריך{" "}
            <span className="font-semibold">
                {weddingDate
                    ? weddingDate.toDate().toLocaleDateString("he-IL")
                    : "תאריך החתונה"}
            </span>
            {functionHallName ? (
                <div>
                    <span> האירוע יתקיים באולם {functionHallName}</span>
                    {functionHallAddress ? (
                        <span>
                            {" "}
                            <br /> בכתובת {functionHallAddress}
                        </span>
                    ) : null}
                </div>
            ) : null}
            {welcomeTime ? (
                <div>
                    <span>שעת קבלת פנים: {welcomeTime}</span>
                </div>
            ) : null}
            {/* {ceremonyTime ? (
                <div>
                    <span>שעת חופה: {ceremonyTime}</span>
                </div>
            ) : null} */}
        </div>
    );
};
