import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useParams } from "react-router";
import GuestTab from "../../components/guests/GuestTab";
import Link from "../../components/Link";
import EventContextProvider from "../../context/event";

export default function Guests() {
    return (
        <EventLayout title="המוזמנים שלכם">
            <GuestTab />
        </EventLayout>
    );
}

export const EventLayout = ({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode;
}) => {
    const { eventId } = useParams();

    return (
        <EventContextProvider eventId={eventId}>
            <div className="w-full">
                <div className="px-5 flex w-full items-center justify-center bg-gradient-primary h-40">
                    <div className="flex w-full text-white items-center justify-center relative">
                        <Link
                            className="absolute top-2 -right-2 lg:right-2 flex gap-0 items-center"
                            to={`/event/${eventId}`}
                        >
                            <ChevronRightIcon className="w-5 h-5" />
                            {/* <span className="text-xs">ראשי</span> */}
                            <HomeIcon className="w-5 h-5" />
                        </Link>
                        <h1 className="text-3xl font-medium text-center">
                            {title}
                        </h1>
                    </div>
                </div>
                <div className="pt-5 min-h-[80vh] bg-slate-50 rounded-t-3xl h-full -mt-6 px-5 lg:px-10 z-10 relative">
                    {children}
                </div>
            </div>
        </EventContextProvider>
    );
};
