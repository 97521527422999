import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Loader from "./components/Loader";
import { getUser } from "./db/utils";
import { auth } from "./firebase";
import { PLANS, UserData } from "./types";

function App() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const urlPlan = searchParams.get("plan") as PLANS;

    useEffect(() => {
        const navigateUser = async () => {
            if (user) {
                const userDoc = await getUser({ uid: user.uid });
                const { eventId } = userDoc.data() as UserData;
                navigate(`/event/${eventId}`);
            } else if (!loading && !user && !error) {
                navigate("/init");
                localStorage.setItem("plan", urlPlan ?? PLANS.PLUS);
                // navigate("/new?step=0");
            }
        };
        navigateUser();
    }, [error, loading, navigate, urlPlan, user]);

    if (loading) return <Loader fullScreen />;

    if (error) return <div>Error</div>;

    return <Loader fullScreen />;
}

export default App;
