import { useEffect, useState } from "react";
import {
    useAuthState,
    useSignInWithEmailAndPassword,
    useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import Register from "../components/Register";
import { getUser, registerUser } from "../db/utils";
import { auth } from "../firebase";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signInWithGoogle] = useSignInWithGoogle(auth);
    const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);
    const [user] = useAuthState(auth);

    const navigate = useNavigate();

    useEffect(() => {
        const onLogin = async () => {
            try {
                if (user) {
                    let eventId;
                    const userDoc = await getUser({ uid: user.uid });
                    if (!userDoc.exists()) {
                        ({ eventId } = await registerUser({
                            uid: user.uid,
                            userData: {
                                email: user.email ?? null,
                            },
                        }));
                    } else {
                        ({ eventId } = userDoc.data());
                    }
                    if (!user.isAnonymous) {
                        // enables users who logged in anonymously to login to real account
                        navigate(`/event/${eventId}`);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        onLogin();
    }, [navigate, user]);

    return (
        <div className="pt-20 px-5 bg-slate-50">
            <Register login />
        </div>

        // <div>
        //     <form
        //         onSubmit={(e) => {
        //             e.preventDefault();
        //             onEmailRegister();
        //         }}
        //     >
        //         <div>
        //             <input
        //                 type="email"
        //                 placeholder="אימייל"
        //                 value={email}
        //                 onChange={(e) => setEmail(e.target.value)}
        //             />
        //         </div>
        //         <div>
        //             <input
        //                 type="password"
        //                 placeholder="סיסמה"
        //                 value={password}
        //                 onChange={(e) => setPassword(e.target.value)}
        //             />
        //         </div>
        //         <div>
        //             <button type="submit">התחברות</button>
        //         </div>
        //     </form>
        //     <button onClick={() => signInWithGoogle()}>גוגל</button>
        // </div>
    );
}
