import { doc, updateDoc } from "firebase/firestore";
import { EVENTS_COLLECTION } from "../../constants";
import { changePlan } from "../../db/utils";
import { db } from "../../firebase";
import useEvent from "../../hooks/useEvent";
import {
    PLANS,
    REMINDER_TEMPLATES,
    RSVP_TEMPLATES,
    THANKS_TEMPLATES,
    WeddingValues,
} from "../../types";
import EditMessages from "../EditMessages";
import Loader from "../Loader";

export default function MessagesTab() {
    const { event, loading } = useEvent();
    // const [weddingValues, setWeddingValues] = useState({} as WeddingValues);
    // const [schedules, setSchedules] = useState({} as Schedules);

    // useEffect(() => {
    //     if (!event) return;
    //     const {
    //         brideName,
    //         groomName,
    //         weddingDate,
    //         functionHallAddress,
    //         functionHallName,
    //         welcomeTime,
    //         ceremonyTime,
    //         inviteImage,
    //         rsvp,
    //         reminder,
    //         thanks,
    //     } = event;

    //     const rsvpTemplate = (rsvp.template as RSVP_TEMPLATES) ?? null;
    //     const RSVPRoundsSchedules = rsvp.schedules;

    //     const reminderSchedule = reminder.schedules[0] ?? null;
    //     const reminderTemplate =
    //         (reminder.template as REMINDER_TEMPLATES) ?? null;

    //     const thanksSchedule = thanks.schedules[0] ?? null;
    //     const thanksTemplate = (thanks.template as THANKS_TEMPLATES) ?? null;

    //     setWeddingValues({
    //         brideName,
    //         groomName,
    //         weddingDate,
    //         rsvpTemplate,
    //         reminderTemplate,
    //         thanksTemplate,
    //         functionHallName,
    //         functionHallAddress,
    //         inviteImage,
    //         welcomeTime,
    //         ceremonyTime,
    //     });

    //     setSchedules({
    //         firstRSVP: RSVPRoundsSchedules[0],
    //         secondRSVP: RSVPRoundsSchedules[1],
    //         reminder: reminderSchedule,
    //         thanks: thanksSchedule,
    //     });
    // }, [event]);

    if (loading) return <Loader fullScreen />;

    if (!event) return null;

    const {
        brideName,
        groomName,
        weddingDate,
        functionHallAddress,
        functionHallName,
        welcomeTime,
        ceremonyTime,
        inviteImage,
        rsvp,
        thanks,
        reminder,
        plan,
        eventId,
        active,
        payboxLink,
    } = event;

    const updateWeddingValues = async (values: WeddingValues) => {
        if (!eventId) return;
        await updateDoc(doc(db, EVENTS_COLLECTION, eventId), {
            brideName: values.brideName,
            groomName: values.groomName,
            weddingDate: values.weddingDate,
            functionHallName: values.functionHallName ?? null,
            functionHallAddress: values.functionHallAddress ?? null,
            welcomeTime: values.welcomeTime ?? null,
            ceremonyTime: values.ceremonyTime ?? null,
            inviteImage: values.inviteImage ?? null,
            payboxLink: values.payboxLink ?? null,
            rsvp: {
                template: values.rsvpTemplate,
                schedules: [rsvp.schedules[0], rsvp.schedules[1]],
            },
            reminder: {
                template: values.reminderTemplate,
                schedules: [reminder.schedules[0]],
            },
            thanks: {
                template: values.thanksTemplate,
                schedules: [thanks.schedules[0]],
            },
        });
    };

    return (
        <div className="max-w-[1440px] mx-auto">
            <EditMessages
                newCustomer={false}
                values={{
                    brideName,
                    groomName,
                    weddingDate,
                    functionHallAddress,
                    functionHallName,
                    welcomeTime,
                    ceremonyTime,
                    inviteImage,
                    rsvpTemplate: rsvp.template as RSVP_TEMPLATES,
                    reminderTemplate: reminder.template as REMINDER_TEMPLATES,
                    thanksTemplate: thanks.template as THANKS_TEMPLATES,
                    payboxLink,
                }}
                setValues={updateWeddingValues}
                // setValues={async (values: WeddingValues) => {
                //     if (!event.eventId) return;
                //     await updateDoc(
                //         doc(db, EVENTS_COLLECTION, event.eventId),
                //         values
                //     );
                // }}
                plan={plan}
                setPlan={async (plan: PLANS) => {
                    if (!event.eventId) return;
                    await changePlan({ eventId: event.eventId, plan });
                }}
                eventActive={active}
            />
        </div>
    );
}
