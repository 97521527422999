import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Timestamp } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EditMessages from "../components/EditMessages";
import Link from "../components/Link";
import Register from "../components/Register";
import Scheduler, { Schedules } from "../components/Scheduler";
import useAlert from "../hooks/useAlert";
import useTrack from "../hooks/useTrack";
import {
    PLANS,
    REMINDER_TEMPLATES,
    RSVP_TEMPLATES,
    THANKS_TEMPLATES,
    WeddingValues,
} from "../types";
import {
    addDaysToDate,
    createSchedulesDates,
    localStorageDateToTimestamp,
} from "../utils";

export default function New() {
    const [step, setStep] = useState(0);
    const [searchParams] = useSearchParams();
    // const urlPlan = searchParams.get("plan") as PLANS;
    const urlStep = searchParams.get("step");
    const [plan, setPlan] = useState<PLANS | undefined>();
    const [values, setValues] = useState({} as WeddingValues);
    const [schedules, setSchedules] = useState({} as Schedules);
    const [shouldUpdateSchedules, setShouldUpdateSchedules] = useState(false);
    const navigate = useNavigate();
    const { logEvent } = useTrack();
    const { isAlerted } = useAlert();

    useEffect(() => {
        const weddingValues = localStorage.getItem("weddingValues");
        const schedules = localStorage.getItem("schedules");

        logEvent({
            data: {
                event: "page_view",
                page: "new",
                plan: plan ?? null,
                step,
                weddingValues,
            },
        });

        if (weddingValues) {
            setValues((prev) => ({
                ...JSON.parse(weddingValues),
                weddingDate: localStorageDateToTimestamp(
                    JSON.parse(weddingValues).weddingDate
                ),
                rsvpTemplate: prev.rsvpTemplate ?? RSVP_TEMPLATES.BASIC_V1,
                reminderTemplate:
                    prev.reminderTemplate ?? REMINDER_TEMPLATES.BASIC,
                thanksTemplate: prev.thanksTemplate ?? THANKS_TEMPLATES.BASIC,
                functionHallName: prev.functionHallName ?? null,
                functionHallAddress: prev.functionHallAddress ?? null,
                welcomeTime: prev.welcomeTime ?? null,
                ceremonyTime: prev.ceremonyTime ?? null,
                inviteImage: prev.inviteImage ?? null,
            }));
        } else {
            setValues({
                brideName: null,
                groomName: null,
                weddingDate: null,
                rsvpTemplate: RSVP_TEMPLATES.BASIC_V1,
                reminderTemplate: REMINDER_TEMPLATES.BASIC,
                thanksTemplate: THANKS_TEMPLATES.BASIC,
                functionHallName: null,
                functionHallAddress: null,
                welcomeTime: null,
                ceremonyTime: null,
                inviteImage: null,
                payboxLink: null,
            });
        }

        if (schedules) {
            const { firstRSVP, secondRSVP, reminder, thanks } =
                JSON.parse(schedules);
            setSchedules({
                firstRSVP: localStorageDateToTimestamp(firstRSVP),
                secondRSVP: localStorageDateToTimestamp(secondRSVP),
                reminder: localStorageDateToTimestamp(reminder),
                thanks: localStorageDateToTimestamp(thanks),
            });
        } else if (weddingValues) {
            const weddingDateAsTimestamp = localStorageDateToTimestamp(
                JSON.parse(weddingValues).weddingDate
            );

            if (weddingDateAsTimestamp) {
                const weddingDateAsDate = weddingDateAsTimestamp.toDate();
                const twoWeeksBefore = addDaysToDate({
                    date: weddingDateAsDate,
                    days: -14,
                    weekdaysOnly: true,
                });

                const oneWeekBefore = addDaysToDate({
                    date: weddingDateAsDate,
                    days: -7,
                    weekdaysOnly: true,
                });
                const oneDayBefore = addDaysToDate({
                    date: weddingDateAsDate,
                    days: -1,
                    weekdaysOnly: true,
                });
                const oneDayAfter = addDaysToDate({
                    date: weddingDateAsDate,
                    days: 1,
                    weekdaysOnly: true,
                });
                if (weddingDateAsDate) {
                    setSchedules({
                        firstRSVP: Timestamp.fromDate(twoWeeksBefore),
                        secondRSVP: Timestamp.fromDate(oneWeekBefore),
                        reminder: Timestamp.fromDate(oneDayBefore),
                        thanks: Timestamp.fromDate(oneDayAfter),
                    });
                }
            }
        } else {
            setSchedules({
                firstRSVP: null,
                secondRSVP: null,
                reminder: null,
                thanks: null,
            });
        }

        setPlan((localStorage.getItem("plan") as PLANS) ?? PLANS.PLUS);
    }, [logEvent]);

    useEffect(() => {
        if (values && Object.keys(values).length > 0) {
            const existingWeddingValues = JSON.parse(
                localStorage.getItem("weddingValues") ?? "{}"
            );
            const { weddingDate: existingWeddingDate } = existingWeddingValues;
            if (existingWeddingDate !== values.weddingDate) {
                setShouldUpdateSchedules(true);
            }
            localStorage.setItem("weddingValues", JSON.stringify(values));
        }
    }, [values]);

    useEffect(() => {
        const localPlan = localStorage.getItem("plan");
        localStorage.setItem("plan", plan ?? localPlan ?? PLANS.PLUS);
    }, [plan]);

    useEffect(() => {
        let step = Number(urlStep ?? localStorage.getItem("step") ?? 0);
        step = isNaN(step) ? 0 : step > 2 ? 2 : step;
        setStep(step);

        // const weddingValues = JSON.parse(
        //     localStorage.getItem("weddingValues") ?? "{}"
        // );
        // const schedules = JSON.parse(localStorage.getItem("schedules") ?? "{}");
        // const { brideName, groomName, weddingDate } = weddingValues;
        // const weddingDetailsCompleted = brideName && groomName && weddingDate;

        // if (isEmptyObject(weddingValues) || !weddingDetailsCompleted) {
        //     setStep(0);
        //     return;
        // }

        // if (isEmptyObject(schedules)) {
        //     setStep(1);
        //     return;
        // }

        // setStep(2);
    }, [urlStep]);

    const steps = ["מה נשלח", "מתי נשלח", "למי נשלח"];

    const updateSchedules = (schedules: Schedules) => {
        setSchedules(schedules);
        localStorage.setItem("schedules", JSON.stringify(schedules));
    };

    const minDate = addDaysToDate({
        date: new Date(),
        days: 15,
    });

    const canProceedToScheduler = Boolean(
        values.brideName &&
            values.groomName &&
            values.weddingDate &&
            values.weddingDate.toDate() >= minDate // IOS not supporting min in date input
    );

    const progress = useMemo(() => {
        return step === 0 ? 0.04 : step === 1 ? 0.33 : 0.66;
    }, [step]);

    return (
        <div className="bg-slate-50 min-h-screen">
            <div className="bg-gradient-primary h-40 relative flex gap-1 flex-col items-center justify-center text-white w-full">
                {/* <div className="bg-gradient-primary h-40 pt-14 pb-5 relative flex gap-1 flex-col items-center justify-start text-white w-full"> */}
                {/* <div className="bg-gradient-primary h-56 pt-14 pb-5 relative flex gap-1 flex-col items-center justify-start text-white w-full"> */}
                {/* <div className="bg-gradient-to-br to-[#FF8C7F] from-[#F7CCBB] h-56 pt-14 pb-5 relative flex gap-1 flex-col items-center justify-start text-white w-full"> */}
                <h1 className="text-3xl font-medium text-center w-full">
                    {step === 0 ? (
                        "ההודעה שנשלח עבורכם"
                    ) : (
                        <div className="flex items-center justify-center relative w-full">
                            <button
                                onClick={() => {
                                    navigate(-1);
                                }}
                                // to={`/new?step=${step - 1}`}
                                className="p-2 absolute right-4 top-0"
                            >
                                <ChevronRightIcon className="w-5 h-5" />
                            </button>
                            {step === 1 ? "מתי נשלח כל דבר" : "הרשמה מהירה"}
                        </div>
                    )}
                </h1>
                {/* <p className="text-lg font-light text-center text-slate-100/90">
                    {step === 0
                        ? "מלאו את פרטי ההודעה שנשלח לאורחים שלכם"
                        : // ? "ההודעה המיוחדת שנשלח לאורחים שלכם"
                        step === 1
                        ? "בחרנו עבורכם את הזמנים המושלמים"
                        : "נרשמים ברגע וכל הפרטים שלכם ישמרו"}
                </p> */}
                {/* <div className="w-full px-10 py-4 max-w-[500px] mx-auto">
                    <ProgressBar progress={progress} />
                    <div className="flex flex-row justify-between items-center w-full">
                        {[0, 1, 2].map((i) => (
                            <div
                                key={steps[i]}
                                className={`${
                                    step < i || i > 1 ? "text-slate-100/80" : ""
                                } relative flex items-center justify-center flex-shrink-0`}
                            >
                                <div
                                    className={`${
                                        i <= step && i <= 1 // only 2 first steps
                                            ? "font-semibold text-slate-100"
                                            : "text-slate-100/80"
                                    } text-sm inline-flex items-center gap-0.5 flex-1 text-center mt-1.5`}
                                >
                                    {i < step ? (
                                        <CheckIcon className="w-[0.825rem] h-[0.825rem] stroke-2" />
                                    ) : null}
                                    {steps[i]}
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>

            <div className="bg-slate-50 rounded-t-3xl h-full -mt-6 px-5 lg:px-10 z-10 relative">
                {step === 0 ? (
                    <div>
                        <EditMessages
                            values={values}
                            setValues={setValues}
                            plan={plan}
                            setPlan={setPlan}
                        />
                        <Link
                            className="btn-primary mt-20 lg:mt-2 mx-auto"
                            onClick={async () => {
                                if (!canProceedToScheduler) {
                                    await isAlerted(
                                        "צריך קודם למלא את כל שדות החובה: שמות הכלה והחתן ותאריך החתונה. תאריך החתונה צריך להיות לפחות שבועיים מהיום"
                                    );
                                    return;
                                }
                                if (
                                    // Object.values(schedules).every((s) => !s) &&
                                    values.weddingDate &&
                                    shouldUpdateSchedules
                                ) {
                                    const weddingDateAsTimestamp =
                                        values.weddingDate;
                                    const weddingDateAsDate =
                                        weddingDateAsTimestamp.toDate();

                                    const {
                                        twoWeeksBefore,
                                        oneWeekBefore,
                                        oneDayAfter,
                                        oneDayBefore,
                                    } = createSchedulesDates({
                                        weddingDate: values.weddingDate,
                                    });
                                    if (weddingDateAsDate) {
                                        setSchedules({
                                            firstRSVP:
                                                Timestamp.fromDate(
                                                    twoWeeksBefore
                                                ),
                                            secondRSVP:
                                                Timestamp.fromDate(
                                                    oneWeekBefore
                                                ),
                                            reminder:
                                                Timestamp.fromDate(
                                                    oneDayBefore
                                                ),
                                            thanks: Timestamp.fromDate(
                                                oneDayAfter
                                            ),
                                        });

                                        localStorage.setItem(
                                            "schedules",
                                            JSON.stringify({
                                                firstRSVP:
                                                    Timestamp.fromDate(
                                                        twoWeeksBefore
                                                    ),
                                                secondRSVP:
                                                    Timestamp.fromDate(
                                                        oneWeekBefore
                                                    ),
                                                reminder:
                                                    Timestamp.fromDate(
                                                        oneDayBefore
                                                    ),
                                                thanks: Timestamp.fromDate(
                                                    oneDayAfter
                                                ),
                                            })
                                        );
                                        setShouldUpdateSchedules(false);
                                    }
                                }
                                // setStep(step + 1);
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                });
                                localStorage.setItem("step", "1");
                            }}
                            disabled={!canProceedToScheduler}
                            to={`/new?step=1`}
                        >
                            לצפייה בזמני המשלוח
                        </Link>
                        <div className="text-center mt-1.5 text-slate-700">
                            אפשר תמיד לערוך גם אחר כך
                        </div>
                    </div>
                ) : step === 1 ? (
                    <div>
                        <Scheduler
                            plan={plan}
                            schedules={schedules}
                            updateSchedules={updateSchedules}
                            weddingDate={values.weddingDate}
                            setPlan={setPlan}
                            onNextStep={() => setStep(step + 1)}
                        />
                        <div>
                            <Link
                                className="btn-primary gap-3  mt-14"
                                onClick={() => {
                                    // setStep(step + 1);
                                    window.scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: "smooth",
                                    });
                                    localStorage.setItem("step", "2");
                                }}
                                to={`/new?step=2`}
                            >
                                שמירת פרטי האירוע
                            </Link>
                            <div className="text-center mt-1.5 text-slate-700">
                                אפשר לשנות את זמני השליחה גם אחר כך
                            </div>
                        </div>
                    </div>
                ) : step === 2 ? (
                    <>
                        <Register />
                    </>
                ) : (
                    <div>אופס, משהו השתבש... יש לנסות שנית מאוחר יותר</div>
                )}
            </div>
        </div>
    );
}
