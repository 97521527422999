import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useRef, useState } from "react";
import * as XLSX from "xlsx";
import { EVENTS_COLLECTION } from "../constants";
import { db } from "../firebase";
import { EventData, Guest } from "../types";
import { convertPhoneToWhatsApp, isWhatsAppId } from "../utils";

function ExcelToJsonConverter({
    eventId,
    closeModal,
}: {
    eventId: string;
    closeModal: () => void;
}) {
    const [file, setFile] = useState<File | null>(null);
    const [jsonData, setJsonData] = useState<Array<object> | null>(null);
    const [error, setError] = useState("");
    const [nameColumn, setNameColumn] = useState("");
    const [phoneColumn, setPhoneColumn] = useState("");
    const [expectedAmountColumn, setExpectedAmountColumn] = useState("");
    const [uploadedColumns, setUploadedColumns] = useState<string[] | null>();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleConvert = (file: File | null) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const data = e.target!.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                const columns = json
                    .map((item: any) => Object.keys(item))
                    .flat()
                    .filter(
                        (item, index, self) =>
                            self.indexOf(item) === index && item !== "__EMPTY"
                    );
                setUploadedColumns(columns);
                setJsonData(json as Array<object>);
                // setJsonData(JSON.stringify(json, null, 2));
            };
            reader.readAsBinaryString(file);
        }
    };

    const handleSubmit = async () => {
        if (!jsonData) return;
        const guests: Array<Guest & { phone?: string | null }> = jsonData.map(
            (item: any) => ({
                name: item[nameColumn]?.toString() ?? null,
                phone: item[phoneColumn] ? item[phoneColumn].toString() : null,
                expectedAmount:
                    expectedAmountColumn &&
                    !isNaN(Number(item[expectedAmountColumn])) &&
                    Number(item[expectedAmountColumn]) > 0
                        ? Number(item[expectedAmountColumn])
                        : 1,
                arriving: null,
                sent: false,
                validPhoneNumber: false,
                amount: null,
                sendRSVP: true,
                tableNumber: null,
                relationship: null,
            })
        );

        const eventRef = doc(db, EVENTS_COLLECTION, eventId);

        const event = await getDoc(eventRef);
        const { guests: guestsObject } = event.data() as EventData;
        const newGuests: { [key: string]: Guest } = {};
        for (const guest of guests) {
            if (!guest.phone) continue;
            const wa_id = convertPhoneToWhatsApp(guest.phone.toString());
            if (wa_id && !guestsObject[wa_id]) {
                delete guest.phone;
                const isValidPhone = isWhatsAppId(wa_id);
                newGuests[wa_id] = guest as Guest;
                newGuests[wa_id].validPhoneNumber = isValidPhone;
            }
        }
        if (Object.keys(newGuests).length === 0) {
            setError("לא הצלחנו להוסיף אנשי קשר חדשים, בדקו את הפרטים שהזנתם");
            return;
        }
        const guestsToSave = { ...guestsObject, ...newGuests };

        await updateDoc(doc(db, EVENTS_COLLECTION, eventId), {
            guests: guestsToSave,
        });
        alert(`נוספו ${Object.keys(newGuests).length} אנשי קשר לאירוע`);
        closeModal();
    };

    const canSubmit = nameColumn && phoneColumn && nameColumn !== phoneColumn;

    return (
        <div>
            <p className="my-3 text-start text-slate-700">
                העלו קובץ אקסל עם אנשי הקשר שלכם ולאחר מכן בחרו את העמודות
                המתאימות
            </p>
            <div className="p-1.5 flex items-center gap-2 my-2 text-slate-700 text-start rounded-xl text-xs">
                <ExclamationCircleIcon className="h-5 w-5 flex-shrink-0 text-red-500/90 inline-block" />
                <div>
                    שימו לב - השורה הראשונה בקובץ נחשבת כותרת ולא תתווסף לאנשי
                    הקשר
                </div>
            </div>
            {uploadedColumns ? null : (
                <div>
                    <button
                        className="btn-primary"
                        onClick={() => fileInputRef.current?.click()}
                    >
                        העלאת קובץ
                    </button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        className="hidden"
                        accept=".xls,.xlsx"
                        onChange={(e) => {
                            setFile(e.target.files?.[0] ?? null);
                            handleConvert(e.target.files?.[0] ?? null);
                        }}
                    />
                </div>
            )}
            {error && <p className="text-red-500">{error}</p>}
            {/* <button onClick={handleConvert}>Convert</button> */}
            {uploadedColumns ? (
                <div>
                    <div className="flex justify-between items-center py-4">
                        <label className="text-start" htmlFor="nameColumn">
                            העמודה עם
                            <span className="font-semibold mx-1">
                                שמות אנשי הקשר:
                            </span>
                        </label>
                        <select
                            id="nameColumn"
                            value={nameColumn}
                            onChange={(e) => setNameColumn(e.target.value)}
                            className={`${
                                nameColumn ? "" : "text-slate-400"
                            } w-20 lg:w-28 focus:outline-none`}
                        >
                            <option value="" disabled>
                                בחירה
                            </option>
                            {uploadedColumns.map((column) => (
                                <option key={column} value={column}>
                                    {column}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex justify-between items-center py-4">
                        <label className="text-start" htmlFor="phoneColumn">
                            העמודה עם
                            <span className="font-semibold mx-1">הטלפונים</span>
                            שלהם:
                        </label>
                        <select
                            id="phoneColumn"
                            value={phoneColumn}
                            onChange={(e) => setPhoneColumn(e.target.value)}
                            className={`${
                                phoneColumn ? "" : "text-slate-400"
                            } w-20 lg:w-28 focus:outline-none`}
                        >
                            <option value="" disabled>
                                בחירה
                            </option>
                            {uploadedColumns.map((column) => (
                                <option key={column} value={column}>
                                    {column}
                                </option>
                            ))}
                        </select>
                    </div>
                    {uploadedColumns.length >= 3 ? (
                        <div className="flex justify-between items-center py-4">
                            <label
                                className="text-start"
                                htmlFor="expectedAmountColumn"
                            >
                                העמודה עם
                                <span className="font-semibold mx-1">
                                    כמות המוזמנים
                                </span>
                                (רשות):
                            </label>
                            <select
                                id="expectedAmountColumn"
                                value={expectedAmountColumn}
                                onChange={(e) =>
                                    setExpectedAmountColumn(e.target.value)
                                }
                                className={`${
                                    expectedAmountColumn ? "" : "text-slate-400"
                                } w-20 lg:w-28 focus:outline-none`}
                            >
                                <option value="" disabled>
                                    בחירה
                                </option>
                                {uploadedColumns.map((column) => (
                                    <option key={column} value={column}>
                                        {column}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : null}
                    <button
                        className="mt-5 btn-primary"
                        disabled={!canSubmit}
                        onClick={handleSubmit}
                    >
                        אישור
                    </button>
                </div>
            ) : null}
        </div>
    );
}

export default ExcelToJsonConverter;
