import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { SwipeableDrawer } from "@mui/material";
import { signOut } from "firebase/auth";
import { useCallback, useMemo, useState } from "react";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import EventContextProvider from "../context/event";
import { changePlan } from "../db/utils";
import { auth } from "../firebase";
import useUser from "../hooks/useUser";
import { PLANS } from "../types";
import RegisterAndLinkAccountPopup from "./AuthPopup";
import Link from "./Link";
import Logo from "./Logo";
import PlanUpgrade from "./PlanUpgrade";

export default function Navbar({
    showOnList = false,
}: {
    showOnList?: boolean;
}) {
    const location = useLocation();
    const { user } = useUser();
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const { eventId: eventIdFromParams } = useParams();
    const [searchParams] = useSearchParams();
    const eventId = eventIdFromParams ?? searchParams.get("eid") ?? undefined;

    const showDarkColors = useMemo(() => {
        const isMainEventPage = location.pathname === `/event/${eventId}`;
        const isPaymentPage = location.pathname === `/payment/${eventId}`;
        const isInitPage = location.pathname === "/init";
        const isLoginPage = location.pathname === "/login";
        const isContactPage = location.pathname.startsWith("/contact");
        const isTermsPage = location.pathname === "/terms";
        const isPrivacyPage = location.pathname === "/privacy";
        return (
            isMainEventPage ||
            isPaymentPage ||
            isLoginPage ||
            isContactPage ||
            isTermsPage ||
            isPrivacyPage ||
            // isInitPage ||
            showOnList
        );
    }, [location.pathname, eventId, showOnList]);

    const routes = [
        {
            title: "ראשי",
            href: `/event/${eventId}`,
        },
        {
            title: "מוזמנים",
            href: `/event/${eventId}/guests`,
        },
        {
            title: "הודעות",
            href: `/event/${eventId}/messages`,
            offset: 50,
        },
        {
            title: "תזמונים",
            href: `/event/${eventId}/scheduler`,
        },
        {
            title: "יצירת קשר",
            href: `/contact${eventId ? `/?eid=${eventId}` : ""}`,
        },
    ];

    const setPlan = useCallback(
        async (plan: PLANS) => {
            if (!eventId) {
                return localStorage.setItem("plan", plan);
            }
            return await changePlan({ eventId, plan });
        },

        [eventId]
    );

    return (
        <EventContextProvider eventId={eventId}>
            <nav
                dir="rtl"
                className="font-heebo absolute top-0 left-0 z-30 px-4 lg:px-10 py-1 flex justify-between items-center h-14 w-screen"
            >
                <Link to="/">
                    <Logo white={!showDarkColors} />
                </Link>
                <button onClick={() => setOpenDrawer(true)}>
                    <Bars3Icon
                        className={`h-6 w-6 ${
                            showDarkColors ? "text-primary" : "text-white"
                        }`}
                    />
                </button>

                <SwipeableDrawer
                    anchor="right"
                    swipeAreaWidth={0}
                    disableSwipeToOpen={true}
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    onOpen={() => setOpenDrawer(true)}
                >
                    <div className="font-heebo flex flex-col justify-between w-[80vw]  max-w-[500px] h-screen pb-12 reg:pb-20 px-6 pt-6">
                        <div className="flex justify-between items-center">
                            <button onClick={() => setOpenDrawer(false)}>
                                <XMarkIcon className="h-6 w-6 text-slate-900" />
                            </button>
                            <Logo white />
                        </div>
                        <div className=" flex flex-col gap-0 pt-10 items-end w-full h-full divide-y divide-slate-200/50">
                            {routes.map((route) => {
                                const isCurrent =
                                    location.pathname ===
                                    route.href.split("?")[0]; // remove query params

                                const disabled =
                                    !eventId && route.title !== "יצירת קשר";
                                return (
                                    <Link
                                        disabled={disabled}
                                        key={route.title}
                                        onClick={() => setOpenDrawer(false)}
                                        to={route.href}
                                        className={`${
                                            disabled
                                                ? "text-slate-300 font-normal"
                                                : isCurrent
                                                ? "text-primary hover:text-primary/80 font-medium"
                                                : "text-slate-900 hover:text-slate-600 font-medium"
                                        }
                                         hover:text-slate-600 text-end text-xl w-full py-3`}
                                    >
                                        {route.title}
                                    </Link>
                                );
                            })}
                        </div>
                        <div className="w-full flex flex-col gap-3">
                            <div onClick={() => setOpenDrawer(false)}>
                                <PlanUpgrade setPlan={setPlan} />
                            </div>
                            {user ? (
                                user.isAnonymous ? (
                                    <div onClick={() => setOpenDrawer(false)}>
                                        <RegisterAndLinkAccountPopup
                                            buttonText="התחברות"
                                            buttonClassName="w-full bg-white text-slate-900 p-2.5 rounded-xl shadow-sm border font-medium border-slate-900 text-lg"
                                        />
                                    </div>
                                ) : (
                                    <button
                                        onClick={async () => {
                                            await signOut(auth);
                                            navigate("/login");
                                            setOpenDrawer(false);
                                        }}
                                        className="bg-white text-slate-900 p-2.5 rounded-xl shadow-sm border font-medium border-slate-900 text-lg"
                                    >
                                        התנתקות
                                    </button>
                                )
                            ) : (
                                <Link
                                    onClick={() => setOpenDrawer(false)}
                                    className="btn-primary"
                                    to="/login"
                                >
                                    התחברות
                                </Link>
                            )}
                        </div>
                    </div>
                </SwipeableDrawer>
            </nav>
        </EventContextProvider>
    );
}
