import { Disclosure } from "@headlessui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    ExclamationCircleIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { he } from "date-fns/locale/he";
import { Timestamp } from "firebase/firestore";
import {
    getDownloadURL,
    ref as storageRef,
    uploadBytes,
} from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuid } from "uuid";
import demoImage from "../assets/images/wedding-couple.webp";
import { storage } from "../firebase";
import useAlert from "../hooks/useAlert";
import useTrack from "../hooks/useTrack";
import { BasicReminder } from "../messages-templates/reminder";
import { BasicRSVP } from "../messages-templates/rsvp";
import { ExcitingThanks } from "../messages-templates/thanks";
import {
    MESSAGES_TYPES,
    PLANS,
    REMINDER_TEMPLATES,
    RSVP_TEMPLATES,
    THANKS_TEMPLATES,
    Template,
    WeddingValues,
} from "../types";
import { addDaysToDate } from "../utils";
import LabeledInput from "./LabeledInput";
import Loader from "./Loader";
import PlanUpgrade from "./PlanUpgrade";
registerLocale("he", he);

export default function EditMessages({
    values,
    setValues,
    plan,
    setPlan,
    newCustomer = true,
    eventActive = false,
}: {
    values: WeddingValues;
    setValues: (updatedValues: WeddingValues) => void;
    plan?: PLANS;
    setPlan: (plan: PLANS) => void;
    newCustomer?: boolean;
    eventActive?: boolean;
}) {
    const [previewToShow, setPreviewToShow] = useState<MESSAGES_TYPES>(
        MESSAGES_TYPES.RSVP
    );
    const { isAlerted } = useAlert();

    const [imgUploading, setImgUploading] = useState(false);

    const { logEvent } = useTrack();

    useEffect(() => {
        logEvent({
            data: {
                event: "page_view",
                page: "edit_messages",
                newCustomer,
            },
        });
    }, [logEvent, newCustomer]);

    const onValuesChange = (key: string, value: string | Timestamp | null) => {
        const updatedValues = {
            ...values,
            [key]:
                typeof value === "string"
                    ? value.trim() === ""
                        ? null
                        : value
                    : value,
        };
        setValues(updatedValues);
    };

    const onImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            setImgUploading(true);
            const imageRef = storageRef(storage, `invites/${uuid()}`);
            const img = e.target.files?.[0];

            if (!img) return null;
            if (img.size > 5242879) {
                // WhatsApp limit
                await isAlerted(
                    "התמונה גדולה מדי, הגודל המקסימלי האפשרי הוא 5MB"
                );
                setImgUploading(false);
                return;
            }
            const snapshot = await uploadBytes(imageRef, img);
            const url = await getDownloadURL(snapshot.ref);
            onValuesChange("inviteImage", url);
        } catch (err) {
            console.error(err);
            await isAlerted("העלאת התמונה נכשלה, אנא נסה שנית");
        } finally {
            setImgUploading(false);
        }
    };

    const brideNameInputRef = useRef<HTMLInputElement>(null);
    const groomNameInputRef = useRef<HTMLInputElement>(null);
    const weddingDateInputRef = useRef<HTMLInputElement>(null);
    const functionHallNameInputRef = useRef<HTMLInputElement>(null);
    const functionHallAddressInputRef = useRef<HTMLInputElement>(null);
    const inviteImageRef = useRef<HTMLInputElement>(null);

    const {
        brideName,
        groomName,
        weddingDate,
        functionHallName,
        functionHallAddress,
        rsvpTemplate,
        reminderTemplate,
        thanksTemplate,
        inviteImage,
        welcomeTime,
        ceremonyTime,
        payboxLink,
    } = values;

    const onTemplateSelect = (template: Template, msgType: MESSAGES_TYPES) => {
        onValuesChange(
            msgType === MESSAGES_TYPES.RSVP
                ? "rsvpTemplate"
                : msgType === MESSAGES_TYPES.REMINDER
                ? "reminderTemplate"
                : "thanksTemplate",
            template as string
        );
    };

    const messagesStrings = {
        [MESSAGES_TYPES.RSVP]: "אישור הגעה",
        [MESSAGES_TYPES.REMINDER]: "תזכורת",
        [MESSAGES_TYPES.THANKS]: "תודה",
    };

    return (
        <div className="flex flex-col max-w-[700px] mx-auto">
            {!newCustomer ? (
                <div className="flex justify-between gap-1 w-full py-3">
                    {Object.values(MESSAGES_TYPES).map((message) => (
                        <button
                            key={message}
                            onClick={() => setPreviewToShow(message)}
                            className={`${
                                previewToShow === message
                                    ? "badge-active"
                                    : "badge"
                            } min-w-24 sm:min-w-28`}
                        >
                            {messagesStrings[message]}
                        </button>
                    ))}
                </div>
            ) : null}
            {(plan === PLANS.BASIC && previewToShow !== MESSAGES_TYPES.RSVP) ||
            (plan === PLANS.PLUS && previewToShow === MESSAGES_TYPES.THANKS) ? (
                <div className="py-4 flex flex-col gap-2 items-center justify-center">
                    <div className="text-center text-slate-900 font-semibold text-lg">
                        ההודעה לא כלולה בחבילה שלכם. שדרגו אותה
                    </div>
                    <PlanUpgrade setPlan={setPlan} />
                </div>
            ) : null}
            <div className="flex flex-col lg:flex-row-reverse lg:justify-between lg:gap-10 lg:mt-5">
                <div className={`${newCustomer ? "mt-4" : ""} mb-3`}>
                    <Preview
                        msgType={previewToShow}
                        groomName={groomName}
                        brideName={brideName}
                        weddingDate={weddingDate}
                        functionHallName={functionHallName}
                        functionHallAddress={functionHallAddress}
                        inviteImage={inviteImage}
                        welcomeTime={welcomeTime}
                        ceremonyTime={ceremonyTime}
                        payboxLink={payboxLink}
                        rsvpTemplate={rsvpTemplate}
                        reminderTemplate={reminderTemplate}
                        thanksTemplate={thanksTemplate}
                        onTemplateSelect={({ template }) =>
                            onTemplateSelect(template, previewToShow)
                        }
                        brideNameInputRef={brideNameInputRef}
                        groomNameInputRef={groomNameInputRef}
                        weddingDateInputRef={weddingDateInputRef}
                        functionHallNameInputRef={functionHallNameInputRef}
                        functionHallAddressInputRef={
                            functionHallAddressInputRef
                        }
                        onValuesChange={onValuesChange}
                        imgUploading={imgUploading}
                        onImageChange={onImageChange}
                    />
                </div>

                <Disclosure defaultOpen={true}>
                    {/* <Disclosure defaultOpen={!isMobile}> */}
                    {({ open }) => (
                        <div className="w-full lg:w-1/2">
                            <Disclosure.Button className="py-2 text-lg !mt-2 text-start rounded-md w-full flex justify-between items-center lg:items-start">
                                כל פרטי האירוע
                                <ChevronDownIcon
                                    className={`${
                                        open
                                            ? "rotate-180 transition-all"
                                            : "rotate-0 transition-all"
                                    } w-5 h-5`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="flex flex-col gap-4 pb-4">
                                <form
                                    className="flex flex-col space-y-5 mt-1 lg:mt-5 lg:w-full"
                                    // className="flex flex-col gap-5 mt-3"
                                    onSubmit={(e) => e.preventDefault()}
                                >
                                    <LabeledInput
                                        value={brideName ?? ""}
                                        onChange={(e) =>
                                            onValuesChange(
                                                "brideName",
                                                e.target.value
                                            )
                                        }
                                        label="שם הכלה"
                                        placeholder="שם הכלה"
                                        maxLength={40}
                                        id="brideName"
                                        type="text"
                                        disabled={eventActive}
                                        forwardRef={brideNameInputRef}
                                    />
                                    <LabeledInput
                                        value={groomName ?? ""}
                                        onChange={(e) =>
                                            onValuesChange(
                                                "groomName",
                                                e.target.value
                                            )
                                        }
                                        label="שם החתן"
                                        placeholder="שם החתן"
                                        maxLength={40}
                                        id="groomName"
                                        type="text"
                                        disabled={eventActive}
                                        forwardRef={groomNameInputRef}
                                    />
                                    <div className="w-full min-w-[200px]">
                                        {/* <div className="relative h-11 w-full min-w-[200px]"> */}
                                        <label
                                            htmlFor="weddingDate"
                                            className="text-sm font-normal leading-tight text-gray-500 after:border-gray-500"
                                            // className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
                                        >
                                            תאריך החתונה
                                        </label>
                                        <input
                                            type="date"
                                            disabled={eventActive}
                                            id="weddingDate"
                                            min={
                                                addDaysToDate({
                                                    date: new Date(),
                                                    days: 16,
                                                })
                                                    .toISOString()
                                                    .split("T")[0]
                                            }
                                            placeholder="תאריך החתונה"
                                            value={
                                                weddingDate
                                                    ?.toDate()
                                                    ?.toLocaleDateString("he", {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit",
                                                    })
                                                    .split(".")
                                                    .reverse()
                                                    .join("-")

                                                // weddingDate
                                                //     ?.toDate()
                                                //     ?.toISOString()
                                                //     ?.split("T")?.[0]
                                            }
                                            ref={weddingDateInputRef}
                                            onChange={async (e) => {
                                                if (!e.target.value) {
                                                    await isAlerted(
                                                        "חובה לבחור תאריך לאירוע"
                                                    );
                                                    return;
                                                }
                                                const date = new Date(
                                                    e.target.value
                                                );
                                                if (!newCustomer) {
                                                    await isAlerted(
                                                        "שימו לב לשנות גם את תאריכי משלוח ההודעות"
                                                    );
                                                }
                                                onValuesChange(
                                                    "weddingDate",
                                                    date
                                                        ? Timestamp.fromDate(
                                                              date
                                                          )
                                                        : null
                                                );
                                            }}
                                            className="picker peer h-9 text-right w-full border-b border-blue-gray-200 bg-transparent pt-1 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline outline-0 transition-all focus:border-primary focus:border-b-2 focus:outline-0 disabled:bg-blue-gray-50"
                                        />
                                    </div>

                                    <Disclosure
                                        defaultOpen={
                                            previewToShow !==
                                            MESSAGES_TYPES.RSVP
                                        }
                                    >
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="py-2 !mt-2 text-start rounded-md w-full flex justify-between items-center">
                                                    עוד פרטים (אופציונלי)
                                                    <ChevronDownIcon
                                                        className={`${
                                                            open
                                                                ? "rotate-180 transition-all"
                                                                : "rotate-0 transition-all"
                                                        } w-5 h-5`}
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="flex flex-col gap-4 pb-4">
                                                    {previewToShow ===
                                                    MESSAGES_TYPES.RSVP ? (
                                                        <>
                                                            <div>
                                                                <label htmlFor="inviteImage">
                                                                    תמונת ההזמנה
                                                                    שלכם
                                                                </label>
                                                            </div>

                                                            {inviteImage ? (
                                                                <button
                                                                    className="flex gap-1 items-center w-full text-red-500"
                                                                    onClick={() => {
                                                                        if (
                                                                            window.confirm(
                                                                                "בטוח למחוק את התמונה?"
                                                                            )
                                                                        ) {
                                                                            onValuesChange(
                                                                                "inviteImage",
                                                                                null
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <div>
                                                                        מחיקת
                                                                        התמונה
                                                                        הקיימת
                                                                    </div>
                                                                    <TrashIcon className="w-4 h-4" />
                                                                </button>
                                                            ) : (
                                                                <div>
                                                                    <button
                                                                        onClick={() =>
                                                                            inviteImageRef.current?.click()
                                                                        }
                                                                        className="text-primary flex gap-2 items-center"
                                                                    >
                                                                        {imgUploading ? (
                                                                            <Loader
                                                                                small
                                                                            />
                                                                        ) : (
                                                                            <>
                                                                                העלאת
                                                                                תמונה
                                                                                <PlusIcon className="w-5 h-5" />
                                                                            </>
                                                                        )}
                                                                    </button>
                                                                    <input
                                                                        ref={
                                                                            inviteImageRef
                                                                        }
                                                                        id="inviteImage"
                                                                        accept="image/png,image/jpeg"
                                                                        className="hidden"
                                                                        type="file"
                                                                        onChange={
                                                                            onImageChange
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : null}
                                                    <LabeledInput
                                                        value={
                                                            functionHallName ??
                                                            ""
                                                        }
                                                        onChange={(e) =>
                                                            onValuesChange(
                                                                "functionHallName",
                                                                e.target.value
                                                            )
                                                        }
                                                        forwardRef={
                                                            functionHallNameInputRef
                                                        }
                                                        label="שם האולם"
                                                        placeholder="שם האולם"
                                                        id="functionHallName"
                                                        type="text"
                                                        maxLength={40}
                                                    />
                                                    <LabeledInput
                                                        value={
                                                            functionHallAddress ??
                                                            ""
                                                        }
                                                        onChange={(e) =>
                                                            onValuesChange(
                                                                "functionHallAddress",
                                                                e.target.value
                                                            )
                                                        }
                                                        forwardRef={
                                                            functionHallAddressInputRef
                                                        }
                                                        label="כתובת האולם"
                                                        placeholder="כתובת האולם"
                                                        id="functionHallAddress"
                                                        type="text"
                                                        maxLength={100}
                                                    />

                                                    <div className="flex gap-1 items-center">
                                                        <label
                                                            htmlFor="welcomeTime"
                                                            className="w-28"
                                                        >
                                                            שעת קבלת פנים
                                                        </label>
                                                        <input
                                                            value={
                                                                welcomeTime ??
                                                                ""
                                                            }
                                                            onChange={(e) => {
                                                                onValuesChange(
                                                                    "welcomeTime",
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            placeholder="קבלת פנים"
                                                            id="welcomeTime"
                                                            type="time"
                                                            className="bg-transparent focus:outline-none"
                                                        />
                                                        {welcomeTime ? (
                                                            <button
                                                                onClick={() =>
                                                                    onValuesChange(
                                                                        "welcomeTime",
                                                                        null
                                                                    )
                                                                }
                                                            >
                                                                <XMarkIcon className="w-4 h-4 mx-2" />
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                    <div>
                                                        <LabeledInput
                                                            value={
                                                                payboxLink ?? ""
                                                            }
                                                            onChange={(e) =>
                                                                onValuesChange(
                                                                    "payboxLink",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            label="קישור ל-PAYBOX"
                                                            placeholder="קישור ל-PAYBOX"
                                                            id="payboxLink"
                                                            type="text"
                                                            maxLength={100}
                                                        />
                                                        {payboxLink ? (
                                                            <div className="flex items-center gap-2 mt-2">
                                                                <ExclamationCircleIcon className="text-blue-500 w-4 h-4 flex-shrink-0" />
                                                                <span className="text-sm text-slate-900">
                                                                    יש לוודא
                                                                    שהקישור תקין
                                                                    ומקושר
                                                                    לחשבון
                                                                    ה-PAYBOX
                                                                    שלכם
                                                                </span>
                                                            </div>
                                                        ) : null}
                                                        {payboxLink &&
                                                        payboxLink.includes(
                                                            "%"
                                                        ) ? (
                                                            <span className="text-sm text-red-500">
                                                                הקישור אינו יכול
                                                                להכיל את התו "%"
                                                            </span>
                                                        ) : null}
                                                    </div>

                                                    {/* <div className="flex gap-1">
                                    <label
                                        htmlFor="ceremonyTime"
                                        className="w-28"
                                    >
                                        שעת החופה
                                    </label>
                                    <input
                                        className="bg-transparent"
                                        value={ceremonyTime ?? ""}
                                        onChange={(e) => {
                                            onValuesChange(
                                                "ceremonyTime",
                                                e.target.value
                                            );
                                        }}
                                        placeholder="שעת החופה"
                                        id="ceremonyTime"
                                        type="time"
                                    />
                                </div> */}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </form>
                            </Disclosure.Panel>
                        </div>
                    )}
                </Disclosure>
            </div>
        </div>
    );
}

const Preview = ({
    groomName,
    brideName,
    weddingDate,
    functionHallName,
    functionHallAddress,
    inviteImage,
    welcomeTime,
    ceremonyTime,
    payboxLink,
    rsvpTemplate,
    reminderTemplate,
    thanksTemplate,
    onTemplateSelect,
    msgType,
    brideNameInputRef,
    groomNameInputRef,
    weddingDateInputRef,
    functionHallNameInputRef,
    functionHallAddressInputRef,
    onValuesChange,
    imgUploading,
    onImageChange,
}: Partial<WeddingValues> & {
    rsvpTemplate: RSVP_TEMPLATES;
    reminderTemplate: REMINDER_TEMPLATES;
    thanksTemplate: THANKS_TEMPLATES;
    onTemplateSelect: (args: { template: Template }) => void;
    msgType: MESSAGES_TYPES;
    brideNameInputRef: React.RefObject<HTMLInputElement>;
    groomNameInputRef: React.RefObject<HTMLInputElement>;
    weddingDateInputRef: React.RefObject<HTMLInputElement>;
    functionHallNameInputRef: React.RefObject<HTMLInputElement>;
    functionHallAddressInputRef: React.RefObject<HTMLInputElement>;
    onValuesChange: (key: string, value: string | Timestamp | null) => void;
    imgUploading: boolean;
    onImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    const [templateToShow, setTemplateToShow] = useState<Template | null>(null);

    const isRSVP = msgType === MESSAGES_TYPES.RSVP;
    const isReminder = msgType === MESSAGES_TYPES.REMINDER;
    const isThanks = msgType === MESSAGES_TYPES.THANKS;

    useEffect(() => {
        const initialTemplate = isRSVP
            ? rsvpTemplate
            : isReminder
            ? reminderTemplate
            : thanksTemplate;
        setTemplateToShow(initialTemplate);
    }, [isRSVP, isReminder, reminderTemplate, rsvpTemplate, thanksTemplate]);

    if (!templateToShow) return null;

    const rsvpTemplates = {
        [RSVP_TEMPLATES.BASIC_V1]: (
            <BasicRSVP
                groomName={groomName}
                brideName={brideName}
                weddingDate={weddingDate}
                functionHallName={functionHallName}
                functionHallAddress={functionHallAddress}
                welcomeTime={welcomeTime}
                payboxLink={payboxLink}
                brideNameInputRef={brideNameInputRef}
                groomNameInputRef={groomNameInputRef}
                weddingDateInputRef={weddingDateInputRef}
                functionHallNameInputRef={functionHallNameInputRef}
                functionHallAddressInputRef={functionHallAddressInputRef}
            />
        ),
        // [RSVP_TEMPLATES.EXCITING]: (
        //     <ExcitingRSVP
        //         groomName={groomName}
        //         brideName={brideName}
        //         weddingDate={weddingDate}
        //         functionHallName={functionHallName}
        //         functionHallAddress={functionHallAddress}
        //         welcomeTime={welcomeTime}
        //         brideNameInputRef={brideNameInputRef}
        //         groomNameInputRef={groomNameInputRef}
        //         weddingDateInputRef={weddingDateInputRef}
        //         functionHallNameInputRef={functionHallNameInputRef}
        //         functionHallAddressInputRef={functionHallAddressInputRef}
        //     />
        // ),
    };

    const reminderTemplates = {
        [REMINDER_TEMPLATES.BASIC]: (
            <BasicReminder
                groomName={groomName}
                brideName={brideName}
                weddingDate={weddingDate}
                functionHallName={functionHallName}
                functionHallAddress={functionHallAddress}
                welcomeTime={welcomeTime}
                ceremonyTime={ceremonyTime}
                payboxLink={payboxLink}
            />
        ),
        // [REMINDER_TEMPLATES.EXCITING]: (
        //     <ExcitingReminder
        //         groomName={groomName}
        //         brideName={brideName}
        //         weddingDate={weddingDate}
        //         functionHallName={functionHallName}
        //         functionHallAddress={functionHallAddress}
        //         welcomeTime={welcomeTime}
        //         ceremonyTime={ceremonyTime}
        //     />
        // ),
    };

    const thanksTemplates = {
        [THANKS_TEMPLATES.BASIC]: (
            <ExcitingThanks
                groomName={groomName}
                brideName={brideName}
                weddingDate={weddingDate}
            />
        ),
        // [THANKS_TEMPLATES.EXCITING]: (
        //     <ExcitingThanks
        //         groomName={groomName}
        //         brideName={brideName}
        //         weddingDate={weddingDate}
        //     />
        // ),
    };

    // const templates = isRSVP
    //     ? rsvpTemplates
    //     : isReminder
    //     ? reminderTemplates
    //     : thanksTemplates;
    return (
        <div
            className={`flex flex-col w-full items-center max-w-[350px] mx-auto ${
                isRSVP ? "" : "py-5 lg:py-0"
            }`}
        >
            <div className="flex gap-3 w-full items-center justify-between">
                {/* {!isThanks ? null : (
                    <button
                        className="w-full flex-1 h-10 flex items-center justify-start"
                        onClick={() => {
                            const templatesObject = isRSVP
                                ? RSVP_TEMPLATES
                                : isReminder
                                ? REMINDER_TEMPLATES
                                : THANKS_TEMPLATES;
                            const currentTemplateIndex = Object.values(
                                templatesObject
                            ).findIndex(
                                (template) => template === templateToShow
                            );
                            const nextTemplate =
                                currentTemplateIndex <
                                Object.values(templatesObject).length - 1
                                    ? Object.values(templatesObject)[
                                          currentTemplateIndex + 1
                                      ]
                                    : Object.values(templatesObject)[0];
                            setTemplateToShow(nextTemplate as Template);
                        }}
                    >
                        <ChevronRightIcon className="w-6 h-6 text-slate-700" />
                    </button>
                )} */}
                <div className=" mx-auto relative px-1.5 py-1.5 mb-3 mt-2 drop-shadow-md bg-white rounded-se-xl rounded-s-xl w-[78%]">
                    <div
                        className="h-4 w-4 absolute -left-4 bottom-0 bg-white drop-shadow-md"
                        style={{
                            clipPath: "polygon(100% 0, 0% 100%, 100% 100%)",
                        }}
                    ></div>
                    {isRSVP ? (
                        <div className="relative h-44 w-full rounded-lg">
                            <div
                                style={{
                                    backgroundImage: `url(${
                                        inviteImage ?? demoImage
                                    })`,
                                }}
                                className={`w-full h-full bg-cover bg-center rounded-xl`}
                                // className="w-full h-full bg-contain bg-white bg-no-repeat bg-center rounded-xl"
                            >
                                {imgUploading ? (
                                    <div className="grid place-content-center h-full bg-slate-100/50">
                                        <Loader />
                                    </div>
                                ) : inviteImage ? (
                                    <div>
                                        <button
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        "בטוח למחוק את התמונה?"
                                                    )
                                                ) {
                                                    onValuesChange(
                                                        "inviteImage",
                                                        null
                                                    );
                                                }
                                            }}
                                            className="absolute top-2 left-2 text-red-500 rounded-full bg-slate-100/70 p-1 w-7 h-7 flex items-center justify-center"
                                            // className="absolute top-2 right-2 rounded-lg p-1.5 bg-slate-300/50"
                                        >
                                            <TrashIcon className="w-5 h-5" />
                                        </button>
                                    </div>
                                ) : (
                                    <div className="flex  flex-col items-center relative justify-end h-full w-full rounded-xl">
                                        <span className="text-slate-900 h-full bg-slate-100/60 py-1 text-center w-full rounded-xl">
                                            <label
                                                htmlFor="inviteImage-preview"
                                                className="flex flex-col items-center cursor-pointer h-full w-full justify-center gap-1 text-sm text-white"
                                            >
                                                <PlusIcon className="w-12 h-12 stroke-[1]" />
                                            </label>
                                            <input
                                                id="inviteImage-preview"
                                                type="file"
                                                accept="image/png,image/jpeg"
                                                className="hidden"
                                                onChange={onImageChange}
                                            />
                                            <span className="absolute left-2 bottom-1 text-white/80 text-xs">
                                                *דוגמה
                                            </span>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                    <div className="flex flex-col relative pt-1 text-base">
                        <div>
                            {isRSVP
                                ? rsvpTemplates[
                                      templateToShow as RSVP_TEMPLATES
                                  ]
                                : isReminder
                                ? reminderTemplates[
                                      templateToShow as REMINDER_TEMPLATES
                                  ]
                                : thanksTemplates[
                                      templateToShow as THANKS_TEMPLATES
                                  ]}
                        </div>
                        {/* <div>{templates[templateToShow]}</div> */}
                    </div>
                    <div className="text-gray-400 flex gap-0.5">
                        <div className="flex">
                            <CheckIcon className="w-4 h-4" />
                            <CheckIcon className="-mr-3 w-4 h-4" />
                        </div>
                        <span className="text-xs">
                            {new Date().getHours().toString().padStart(2, "0")}:
                            {new Date()
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}
                        </span>
                    </div>
                </div>
                {/* {!isThanks ? null : (
                    <button
                        className="flex-1 w-full h-10 flex items-center justify-end"
                        onClick={() => {
                            const templatesObject = isRSVP
                                ? RSVP_TEMPLATES
                                : isReminder
                                ? REMINDER_TEMPLATES
                                : THANKS_TEMPLATES;
                            const currentTemplateIndex = Object.values(
                                templatesObject
                            ).findIndex(
                                (template) => template === templateToShow
                            );
                            const prevTemplate =
                                currentTemplateIndex > 0
                                    ? Object.values(templatesObject)[
                                          currentTemplateIndex - 1
                                      ]
                                    : Object.values(templatesObject)[
                                          Object.values(templatesObject)
                                              .length - 1
                                      ];
                            setTemplateToShow(prevTemplate as Template);
                        }}
                    >
                        <ChevronLeftIcon className="w-6 h-6 text-slate-700" />
                    </button>
                )} */}
            </div>
            {(isRSVP && templateToShow !== rsvpTemplate) ||
            (isReminder && templateToShow !== reminderTemplate) ||
            (isThanks && templateToShow !== thanksTemplate) ? (
                <div className="flex-1 w-full h-10 flex items-center justify-center text-sm">
                    <div className="flex flex-row-reverse gap-2 items-center">
                        <button
                            className="w-40 flex gap-2 items-center justify-center text-slate-700"
                            onClick={(e) =>
                                onTemplateSelect({
                                    template: templateToShow,
                                })
                            }
                        >
                            <div className="w-3 h-3 rounded-full border border-slate-700" />
                            <div>שימוש בנוסח זה</div>
                        </button>
                        {/* // <div className="flex gap-1 w-full items-center text-slate-500 justify-center">
                        //     <CheckIcon className="w-4 h-4" />
                        //     <span>נוסח נבחר (אפשר לשנות גם אח"כ)</span>
                        // </div> */}
                    </div>
                </div>
            ) : null}
        </div>
    );
};
