import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { EVENTS_COLLECTION } from "../constants";
import { db } from "../firebase";
import { EventData } from "../types";

export default function useEventData({
    eventId,
}: {
    eventId: string | undefined;
}) {
    const [value, loading, error] = useDocumentData(
        eventId ? doc(db, EVENTS_COLLECTION, eventId) : undefined
    );

    return { eventData: value as EventData, loading, error };
}
