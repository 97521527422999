import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React from "react";

export default function CustomDisclosure({
    button,
    children,
    initialOpen,
    disabled = false,
}: {
    button: React.ReactNode;
    children: React.ReactNode;
    initialOpen?: boolean;
    disabled?: boolean;
}) {
    return (
        <Disclosure defaultOpen={initialOpen}>
            <Disclosure.Button
                disabled={disabled}
                className="w-full flex justify-between items-center "
            >
                {button}
                <ChevronDownIcon
                    className={`${
                        disabled ? "text-slate-300" : ""
                    } ui-open:rotate-180 ui-open:transform transition-all ui-not-open:rotate-0 ui-not-open:transform w-6 h-6`}
                />
            </Disclosure.Button>
            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-100 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Disclosure.Panel>{children}</Disclosure.Panel>
            </Transition>
        </Disclosure>
    );
}
