import { useEffect, useState } from "react";
import { MdCheck, MdOutlineContentCopy } from "react-icons/md";

const CopyToClipboardButton = ({
    handleClick,
}: {
    handleClick: () => Promise<void>;
}) => {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (copied) {
            timeout = setTimeout(() => {
                setCopied(false);
            }, 2000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [copied]);

    return (
        <button
            className=""
            onClick={() => {
                handleClick().then(() => {
                    setCopied(true);
                });
            }}
        >
            {copied ? (
                <MdCheck className="w-4 h-4 text-green-500" />
            ) : (
                <MdOutlineContentCopy className="w-4 h-4" />
            )}
        </button>
    );
};

export default CopyToClipboardButton;
