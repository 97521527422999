import { useLocation } from "react-router";
import Link from "./Link";

export default function Footer() {
    const location = useLocation();

    const isInitPage = location.pathname === "/init";

    return (
        <footer
            className={`font-heebo ${
                isInitPage ? "bg-primary/60 text-slate-50" : "bg-slate-50"
            } flex flex-col gap-2 h-24 pb-3 pt-6 items-center justify-center px-3 sm:px-5`}
        >
            <div className="flex gap-5 lg:gap-10 items-center justify-center sm:justify-between text-sm">
                <Link to="/privacy" className="">
                    מדיניות פרטיות
                </Link>
                <a
                    // className="underline"
                    href="https://wa.me/972587501168"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    צור קשר
                </a>
                <Link to="/terms" className="">
                    תנאי שימוש
                </Link>
            </div>
            <div className="flex gap-1 font-heebo items-center justify-center pb-2 text-xs lg:text-sm">
                CinCin RSVP {new Date().getFullYear()} &copy; All Rights
                Reserved
            </div>
        </footer>
    );
}
