import {
    Dialog,
    Disclosure,
    Menu,
    Popover,
    Transition,
} from "@headlessui/react";
import {
    ArrowDownTrayIcon,
    EllipsisVerticalIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";
import {
    CheckIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    MagnifyingGlassIcon,
    MinusIcon,
    PlusIcon,
    UserIcon,
    XMarkIcon,
} from "@heroicons/react/24/solid";
import { Switch } from "@mui/material";
import {
    collection,
    deleteDoc,
    deleteField,
    doc,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { isBrowser } from "react-device-detect";
import { useCollection } from "react-firebase-hooks/firestore";
import { FaFileExcel, FaWhatsapp } from "react-icons/fa6";
import { IoMdInformationCircle } from "react-icons/io";
import * as XLSX from "xlsx";
import {
    CONTACTS_UPLOADER_PHONE_NUMBER,
    CONTACTS_UPLOADERS_COLLECTION,
    EVENTS_COLLECTION,
    EXTRA_CONTACT_THRESHOLD,
} from "../../constants";
import { changePlan } from "../../db/utils";
import { db } from "../../firebase";
import useAlert from "../../hooks/useAlert";
import useConfirm from "../../hooks/useConfirm";
import useEvent from "../../hooks/useEvent";
import useTrack from "../../hooks/useTrack";
import { Notice } from "../../routes/payment";
import { Guest, PLANS } from "../../types";
import {
    changeUndefinedToNull,
    convertPhoneToWhatsApp,
    filterGuests,
    FILTERS,
    formatPhoneNumber,
    getStatsFromGuests,
    isValidIsraeliPhoneNumber,
    isWhatsAppId,
} from "../../utils";
import CopyToClipboardButton from "../CopyToClipboardButton";
import ExcelToJsonConverter from "../ExcelToJsonConverter";
import Link from "../Link";
import Loader from "../Loader";
import PhoneInput from "../PhoneInput";
import AddGuestForm from "./AddGuestForm";

export default function GuestTab() {
    const { event, loading } = useEvent();
    const [filterBy, setFilterBy] = useState<keyof typeof FILTERS>("all");
    const [guestsToShow, setGuestsToShow] = useState<Array<
        Guest & { phone: string }
    > | null>(null);
    const [searchInput, setSearchInput] = useState("");

    const { logEvent } = useTrack();

    useEffect(() => {
        logEvent({
            data: {
                event: "page_view",
                page: "guests",
            },
        });
    }, [logEvent]);

    useEffect(() => {
        if (!event || !event.guests) return;
        const guests = Object.entries(event.guests).map(([phone, guest]) => {
            return { phone, ...guest };
        });
        setGuestsToShow(filterGuests({ guests, filter: filterBy }));
    }, [event, filterBy]);

    const {
        eventId,
        guests: guestsObject,
        plan,
        active,
        numOfContactsPaidFor,
    } = event ?? {};

    const guestsToExport = useMemo(() => {
        return !guestsObject
            ? null
            : Object.entries(guestsObject).map(([phone, guest]) => {
                  return {
                      שם: guest.name,
                      טלפון: formatPhoneNumber({ phoneNumber: phone }),
                      סטטוס:
                          guest.arriving === "yes"
                              ? "מגיע"
                              : guest.arriving === "no"
                              ? "לא מגיע"
                              : guest.arriving === "maybe"
                              ? "אולי"
                              : "לא ידוע",
                      "מספר מוזמנים": guest.expectedAmount ?? 0,
                      "מספר מגיעים": guest.amount ?? 0,
                      קרבה: guest.relationship ?? "לא נבחר",
                      שולחן: guest.tableNumber ?? "לא נבחר",
                      נשלח: guest.sent ? "כן" : "לא",
                  };
              });
    }, [guestsObject]);

    if (loading) return <Loader fullScreen />;

    if (!event || !event.eventId) return <div>אופס, נראה שקרתה תקלה...</div>;

    const setPlan = (plan: PLANS) => {
        changePlan({ plan, eventId: eventId! });
    };

    const setGuestBySearch = () => {
        const searchInputNoDash = searchInput.replace(/-/g, "");
        const guestsFromSearch = guests.filter(
            (g) =>
                (g.name && g.name.includes(searchInput)) ||
                (g.phone &&
                    g.phone.includes(
                        searchInputNoDash.startsWith("0")
                            ? searchInputNoDash.slice(1)
                            : searchInputNoDash.startsWith("972")
                            ? searchInputNoDash.slice(3)
                            : searchInputNoDash
                    ))
        );
        setGuestsToShow(guestsFromSearch);
    };

    const numOfContactToSend = guestsObject
        ? Object.values(guestsObject).reduce(
              (acc, guest) => (guest.sendRSVP ? acc + 1 : acc),
              0
          )
        : 0;

    const includedGuestsExceeded =
        active &&
        numOfContactToSend > numOfContactsPaidFor! + EXTRA_CONTACT_THRESHOLD;

    const {
        guests,
        numOfGuests,
        numOfContacts,
        numOfArriving,
        numOfMaybe,
        numOfNotArriving,
        numOfNotAnswered,
    } = getStatsFromGuests({
        guestsObject,
    });

    const stats = [
        { title: "מוזמנים", value: numOfGuests, id: "all" },
        { title: "רשומות", value: numOfContacts, id: "contacts" },
        { title: "לא ענו", value: numOfNotAnswered, id: "notAnswered" },
        { title: "מגיעים", value: numOfArriving, id: "arriving" },
        { title: "אולי", value: numOfMaybe, id: "maybe" },
        { title: "לא מגיעים", value: numOfNotArriving, id: "notArriving" },
    ];

    const downloadExcel = (data: object[] | null) => {
        if (!data) return;
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "מוזמנים לחתונה.xlsx");
    };

    return (
        <div className="max-w-[1440px] mx-auto">
            {guests.length > 0 ? (
                <div className="flex flex-col gap-3">
                    <div className="flex flex-col items-center justify-between w-full pt-2">
                        <div className="flex items-center w-full gap-2.5">
                            <div className="grid grid-cols-3 justify-center items-center text-center gap-0 w-full">
                                {stats.map(({ title, value, id }, index) => (
                                    <button
                                        key={id}
                                        onClick={() => {
                                            setFilterBy(
                                                id as keyof typeof FILTERS
                                            );
                                            setSearchInput("");
                                        }}
                                        className={`flex flex-col justify-center items-center space-y-0 p-2 ${
                                            filterBy === id
                                                ? "bg-primary-light/90 text-slate-50"
                                                : "text-slate-900"
                                        } ${
                                            index === 0
                                                ? "rounded-tr-2xl"
                                                : index === 2
                                                ? "rounded-tl-2xl"
                                                : index === 3
                                                ? "rounded-br-2xl"
                                                : index === 5
                                                ? "rounded-bl-2xl"
                                                : ""
                                        }`}
                                    >
                                        <span
                                            className={`text-sm ${
                                                filterBy === id
                                                    ? "text-slate-100"
                                                    : "text-slate-600"
                                            } font-medium`}
                                        >
                                            {title}
                                        </span>
                                        <span className="text-3xl font-poppins font-semibold">
                                            {value}
                                        </span>
                                    </button>
                                ))}
                                <Popover className="fixed z-10 bottom-5 right-[calc(50%-2.5rem)] flex items-center justify-center w-20 h-20">
                                    {({ open }) => (
                                        <>
                                            <Popover.Button
                                                className={`text-white p-2 flex bg-gradient-primary justify-center items-center rounded-full text-lg font-medium`}
                                            >
                                                <PlusIcon
                                                    className={`w-10 h-10 text-white ${
                                                        open
                                                            ? "rotate-45"
                                                            : "rotate-0"
                                                    } transition-all duration-300 delay-100`}
                                                />
                                            </Popover.Button>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="opacity-0 translate-y-1"
                                                enterTo="opacity-100 translate-y-0"
                                                leave="transition ease-in duration-150"
                                                leaveFrom="opacity-100 translate-y-0"
                                                leaveTo="opacity-0 translate-y-1"
                                            >
                                                <Popover.Panel className="absolute bottom-20 shadow-md bg-white p-6 rounded-xl m-6 w-80">
                                                    <UploadGuests />
                                                </Popover.Panel>
                                            </Transition>
                                        </>
                                    )}
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <Notice show={Boolean(includedGuestsExceeded)}>
                        <div className="flex flex-col gap-2">
                            יש יותר רשומות ממה שכלול בחבילה שלכם, יש לפנות אלינו
                            לביצוע השינוי
                            <Link
                                to={`/contact/?eid=${eventId}`}
                                className="underline"
                            >
                                לביצוע השינוי
                                <ChevronLeftIcon className="h-4 w-4 inline-block" />
                            </Link>
                        </div>
                    </Notice>
                    {filterBy === "notAnswered" ? (
                        <div className="flex gap-4 items-center rounded-xl p-1 w-full">
                            <div>
                                רשומות שלא ענו:{" "}
                                <span className="font-semibold">
                                    {numOfNotAnswered}
                                </span>
                            </div>
                            <div>
                                מוזמנים שלא ענו:{" "}
                                <span className="font-semibold">
                                    {guestsToShow
                                        ? guestsToShow.reduce(
                                              (acc, guest) =>
                                                  acc +
                                                  (guest.expectedAmount ?? 1),
                                              0
                                          )
                                        : 0}
                                </span>
                            </div>
                        </div>
                    ) : null}
                    <div className="flex items-center h-12 gap-2">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                setGuestBySearch();
                            }}
                            className="h-full flex items-center w-full rounded-full p-1 bg-slate-200/30 focus-within:bg-slate-200/60"
                        >
                            <input
                                type="search"
                                className="bg-transparent w-full px-2 focus:outline-none"
                                value={searchInput}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setSearchInput(value);
                                    if (value === "") {
                                        setGuestsToShow(
                                            filterGuests({
                                                guests,
                                                filter: filterBy,
                                            })
                                        );
                                    }
                                }}
                                placeholder="חיפוש לפי שם או טלפון"
                            />
                            <button
                                type="submit"
                                disabled={!searchInput}
                                aria-label="חיפוש"
                                className="bg-slate-400 disabled:bg-slate-300 rounded-full h-10 w-10 shrink-0 grid place-content-center text-white"
                            >
                                <MagnifyingGlassIcon className="w-5 h-5" />
                            </button>
                        </form>
                        <Menu as="div" className="relative">
                            <Menu.Button>
                                <EllipsisVerticalIcon className="w-7 h-7 text-slate-900" />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="cursor-pointer bg-white p-1.5 rounded-md shadow-sm absolute z-10 left-0 w-40 origin-top-left">
                                    <Menu.Item
                                        as="div"
                                        className="flex p-2.5 font-medium gap-2 items-center justify-between"
                                        onClick={
                                            () => downloadExcel(guestsToExport)
                                            // exportToExcel(
                                            // jsonGuestsToExport,
                                            // "מוזמנים לחתונה"
                                            // )
                                        }
                                    >
                                        ייצוא לאקסל
                                        <ArrowDownTrayIcon className="w-5 h-5 inline-block" />
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>

                    <GuestTable guests={guestsToShow} />
                </div>
            ) : (
                <div className="mt-5 max-w-[500px] mx-auto">
                    <UploadGuests />
                </div>
            )}
        </div>
    );
}

const GuestTable = React.memo(
    ({ guests }: { guests: Array<Guest & { phone: string }> | null }) => {
        const { event, loading } = useEvent();

        if (loading || !guests) return <Loader />;

        if (!event) return <div>האירוע לא נמצא</div>;

        const { eventId } = event;

        if (!eventId) return <div>האירוע לא נמצא</div>;

        return (
            <div className="relative">
                {guests.length === 0 ? (
                    <div className="text-center py-5">אין מידע להציג</div>
                ) : (
                    <ul className="flex flex-col gap-2 w-full overflow-auto pt-2 border-t">
                        {guests.map((guest, index) => {
                            return (
                                <li
                                    key={guest.phone}
                                    className="flex gap-4 py-3 items-start border-b border-slate-200/50"
                                >
                                    {/* <span className="w-4">{index + 1}. </span> */}
                                    <GuestsRow
                                        eventId={eventId}
                                        guest={guest}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    }
);

const GuestsRow = ({
    guest,
    eventId,
}: {
    guest: Guest & { phone: string };
    eventId: string;
}) => {
    const [values, setValues] = useState<Guest & { phone: string }>({
        ...guest,
    });

    const { phone } = guest;
    const {
        name,
        sent,
        arriving,
        validPhoneNumber,
        expectedAmount,
        amount,
        sendRSVP,
        relationship,
        tableNumber,
    } = values;

    useEffect(() => {
        const {
            name,
            phone,
            sent,
            arriving,
            validPhoneNumber,
            expectedAmount,
            amount,
            sendRSVP,
            relationship,
            tableNumber,
        } = guest;

        setValues({
            name,
            phone,
            sent,
            arriving,
            validPhoneNumber,
            expectedAmount,
            amount,
            sendRSVP,
            relationship: relationship ?? null,
            tableNumber: tableNumber ?? null,
        });
    }, [guest]);

    const { event, loading: eventDataLoading } = useEvent();
    const { isConfirmed } = useConfirm();
    const { isAlerted } = useAlert();

    const hasValuesChanged = useMemo(() => {
        return Object.entries(values).some(([key, value]) => {
            return guest[key as keyof (Guest & { phone: string })] !== value;
        });
    }, [guest, values]);

    const updateGuestValue = async ({
        key,
        value,
    }: {
        key: string;
        value: string | number | boolean | null;
    }) => {
        setValues((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const onChangeSubmit = async () => {
        if (!event || !event.guests || !eventId) return;
        const hasPhoneChanged = values.phone !== phone;
        if (hasPhoneChanged) {
            const wa_id = convertPhoneToWhatsApp(values.phone);
            const alreadyExists = Boolean(event.guests[wa_id]);
            if (alreadyExists) {
                await isAlerted("איש קשר עם מספר טלפון זה כבר קיים");
                return;
            }

            const isValid = isWhatsAppId(wa_id);
            const guestToSave: Guest & { phone?: string } = {
                ...guest,
                ...values,
                validPhoneNumber: isValid,
            };
            delete guestToSave.phone;
            await updateDoc(doc(db, EVENTS_COLLECTION, eventId), {
                [`guests.${phone}`]: deleteField(),
                [`guests.${wa_id}`]: changeUndefinedToNull(guestToSave),
            });
        } else {
            await updateDoc(doc(db, EVENTS_COLLECTION, eventId), {
                [`guests.${phone}`]: changeUndefinedToNull({
                    ...guest,
                    ...values,
                }),
            });
        }
    };

    const onChangeCancel = () => {
        const {
            name,
            phone,
            sent,
            arriving,
            validPhoneNumber,
            expectedAmount,
            amount,
            sendRSVP,
            relationship,
            tableNumber,
        } = guest;
        setValues({
            name,
            phone,
            sent,
            arriving,
            validPhoneNumber,
            expectedAmount,
            amount,
            sendRSVP,
            relationship,
            tableNumber,
        });
    };

    const deleteGuest = async () => {
        if (await isConfirmed("בטוח למחוק את איש הקשר?")) {
            await updateDoc(doc(db, EVENTS_COLLECTION, eventId), {
                [`guests.${phone}`]: deleteField(),
            });
        }
    };

    return (
        <div className={`flex items-center gap-4 w-full focus:outline-none`}>
            <Disclosure
                as="div"
                className="flex flex-col w-full focus:outline-none"
            >
                {({ open, close }) => (
                    <>
                        <Disclosure.Button className="flex items-center focus:outline-none justify-between w-full pb-2">
                            <div className="flex gap-3 text-center items-center">
                                <div
                                    className={`w-10 h-10 flex items-center justify-center bg-slate-300 rounded-full`}
                                >
                                    <div className={`w-6 h-6 text-slate-50`}>
                                        <UserIcon />
                                    </div>
                                </div>
                                <div className="flex flex-col  items-start text-right">
                                    <div className="w-56 font-medium">
                                        {guest.name ?? "שם חסר"}
                                    </div>
                                    <div className="flex gap-2 items-center text-sm text-slate-600">
                                        <div className="">
                                            מוזמנים: {guest.expectedAmount}
                                        </div>
                                        {/* <span className="text-xs">&bull;</span> */}
                                        <div className="flex gap-1 items-center">
                                            סטטוס:
                                            <div
                                                className={` h-3 w-3 rounded-full ${
                                                    guest.arriving === "yes"
                                                        ? guest.amount ===
                                                          guest.expectedAmount
                                                            ? "bg-green-500"
                                                            : "bg-green-300"
                                                        : guest.arriving ===
                                                          "no"
                                                        ? "bg-red-500"
                                                        : guest.arriving ===
                                                          "maybe"
                                                        ? "bg-yellow-500"
                                                        : "bg-slate-400"
                                                } bg-opacity-40`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {validPhoneNumber ? (
                                    <ChevronDownIcon
                                        className={`w-6 h-6 p-1 text-slate-900 ${
                                            open ? "rotate-180" : "rotate-90"
                                        } transition-all`}
                                    />
                                ) : (
                                    <IoMdInformationCircle className="h-6 w-6 text-red-500" />
                                )}
                            </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className="bg-slate-100/70 py-3 rounded-xl flex flex-col gap-4">
                            <div className="flex pr-4 w-full items-start text-sm">
                                <div className="flex flex-col w-48 gap-4 items-start">
                                    <div className="flex gap-3">
                                        <label
                                            htmlFor={`${phone}-name`}
                                            className="font-semibold w-10"
                                        >
                                            שם
                                        </label>
                                        <input
                                            value={name ?? undefined}
                                            type="text"
                                            id={`${phone}-name`}
                                            placeholder="שם"
                                            className="bg-transparent underline max-w-32 focus:outline-none text-slate-700"
                                            onChange={(e) =>
                                                updateGuestValue({
                                                    key: "name",
                                                    value: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex gap-3">
                                        <label
                                            htmlFor={`${phone}-phone`}
                                            className={`font-semibold w-10 ${
                                                validPhoneNumber
                                                    ? ""
                                                    : "text-red-500"
                                            }`}
                                        >
                                            טלפון
                                        </label>
                                        <div className="flex gap-1">
                                            <input
                                                value={formatPhoneNumber({
                                                    phoneNumber: values.phone,
                                                })}
                                                type="tel"
                                                id={`${phone}-phone`}
                                                placeholder="טלפון"
                                                className={`text-sm  underline w-[5.5rem] text-right bg-transparent focus:outline-none 
                                                ${
                                                    validPhoneNumber
                                                        ? "text-slate-700"
                                                        : "text-red-500"
                                                }
                                                `}
                                                onChange={(e) =>
                                                    updateGuestValue({
                                                        key: "phone",
                                                        value: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-3 items-center">
                                        <label
                                            htmlFor={`${phone}-arriving`}
                                            className="font-semibold w-10"
                                        >
                                            סטטוס
                                        </label>
                                        <select
                                            value={arriving ?? ""}
                                            className={`${
                                                arriving ? "" : "text-slate-400"
                                            } flex items-start underline bg-transparent text-slate-700`}
                                            onChange={async (e) => {
                                                await updateGuestValue({
                                                    key: "arriving",
                                                    value:
                                                        e.target.value ===
                                                        "לא נבחר"
                                                            ? null
                                                            : e.target.value ??
                                                              null,
                                                });
                                                if (
                                                    e.target.value === "yes" &&
                                                    (amount === 0 ||
                                                        amount === null)
                                                ) {
                                                    await updateGuestValue({
                                                        key: "amount",
                                                        value:
                                                            expectedAmount ?? 1,
                                                    });
                                                }
                                            }}
                                        >
                                            <option
                                                value={undefined}
                                                className=""
                                            >
                                                לא נבחר
                                            </option>
                                            <option value="yes">מגיע</option>
                                            <option value="no">לא מגיע</option>
                                            <option value="maybe">אולי</option>
                                        </select>
                                        <div
                                            className={`rounded-full -mr-2 w-4 h-4 bg-opacity-50 ${
                                                arriving === "yes"
                                                    ? "bg-green-500"
                                                    : arriving === "no"
                                                    ? "bg-red-500"
                                                    : arriving === "maybe"
                                                    ? "bg-yellow-500"
                                                    : "bg-slate-400"
                                            }`}
                                        />
                                    </div>
                                    <div className="flex gap-3">
                                        <label
                                            htmlFor={`${phone}-relationship`}
                                            className="font-semibold w-10"
                                        >
                                            קרבה
                                        </label>
                                        <select
                                            value={relationship ?? undefined}
                                            id={`${phone}-relationship`}
                                            className="bg-transparent underline max-w-32 focus:outline-none text-slate-700"
                                            onChange={(e) =>
                                                updateGuestValue({
                                                    key: "relationship",
                                                    value:
                                                        e.target.value ?? null,
                                                })
                                            }
                                        >
                                            <option
                                                value={undefined}
                                                className=""
                                            >
                                                לא נבחר
                                            </option>
                                            <option value="family">
                                                משפחה
                                            </option>
                                            <option value="friend">
                                                חברים
                                            </option>
                                            <option value="work">עבודה</option>
                                            <option value="other">אחר</option>
                                        </select>
                                    </div>

                                    <div className="flex gap-3">
                                        <label
                                            htmlFor={`${phone}-tableNumber`}
                                            className="font-semibold w-10"
                                        >
                                            שולחן
                                        </label>
                                        <input
                                            value={tableNumber ?? undefined}
                                            type="text"
                                            id={`${phone}-tableNumber`}
                                            placeholder="לא נבחר"
                                            className="bg-transparent underline max-w-32 focus:outline-none text-slate-700"
                                            onChange={(e) =>
                                                updateGuestValue({
                                                    key: "tableNumber",
                                                    value: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col w-20 gap-4 items-start">
                                    <div className="flex gap-3">
                                        <label
                                            htmlFor={`${phone}-expectedAmount`}
                                            className="font-semibold w-10 flex-shrink-0"
                                        >
                                            מוזמנים
                                        </label>
                                        <div className="flex items-center gap-1 mr-2">
                                            <button
                                                onClick={() => {
                                                    updateGuestValue({
                                                        key: "expectedAmount",
                                                        value:
                                                            expectedAmount - 1,
                                                    });
                                                }}
                                                disabled={expectedAmount <= 1}
                                                className="disabled:text-slate-300"
                                            >
                                                <MinusIcon className="w-4 h-4" />
                                            </button>
                                            <input
                                                value={expectedAmount ?? ""}
                                                type="number"
                                                id={`${phone}-expectedAmount`}
                                                placeholder=""
                                                onFocus={(e) => {
                                                    if (
                                                        e.target.value === "0"
                                                    ) {
                                                        e.target.value = "";
                                                    }
                                                }}
                                                min={1}
                                                className="bg-transparent w-8 lg:w-7 text-center underline focus:outline-none text-slate-700"
                                                onChange={(e) =>
                                                    updateGuestValue({
                                                        key: "expectedAmount",
                                                        value:
                                                            e.target.value &&
                                                            e.target.value !==
                                                                "0" &&
                                                            e.target.value !==
                                                                ""
                                                                ? Number(
                                                                      e.target
                                                                          .value
                                                                  )
                                                                : 1,
                                                    })
                                                }
                                            />
                                            <button
                                                onClick={() => {
                                                    updateGuestValue({
                                                        key: "expectedAmount",
                                                        value:
                                                            expectedAmount + 1,
                                                    });
                                                }}
                                            >
                                                <PlusIcon className="w-4 h-4" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex gap-3">
                                        <label
                                            htmlFor={`${phone}-amount`}
                                            className="font-semibold w-10"
                                        >
                                            מגיעים
                                        </label>
                                        <input
                                            value={amount ? Number(amount) : ""}
                                            type="number"
                                            min={0}
                                            onFocus={(e) => {
                                                if (e.target.value === "0") {
                                                    e.target.value = "";
                                                }
                                            }}
                                            id={`${phone}-amount`}
                                            placeholder=""
                                            className="bg-transparent max-w-20 underline focus:outline-none text-slate-700"
                                            onChange={(e) =>
                                                updateGuestValue({
                                                    key: "amount",
                                                    value:
                                                        e.target.value &&
                                                        e.target.value !==
                                                            "0" &&
                                                        e.target.value !== ""
                                                            ? Number(
                                                                  e.target.value
                                                              )
                                                            : null,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex gap-3 items-center">
                                        <label
                                            htmlFor={`sendRSVP-${phone}`}
                                            className="font-semibold w-10 flex-shrink-0"
                                        >
                                            כלול בשליחה
                                        </label>
                                        <Switch
                                            size="small"
                                            color="error"
                                            checked={sendRSVP}
                                            id={`sendRSVP-${phone}`}
                                            onChange={async () => {
                                                if (
                                                    !sendRSVP &&
                                                    !validPhoneNumber
                                                ) {
                                                    alert(
                                                        "מספר הטלפון לא תקין, יש לשנות אותו קודם"
                                                    );
                                                    return;
                                                }
                                                await updateGuestValue({
                                                    key: "sendRSVP",
                                                    value: !sendRSVP,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="flex gap-3">
                                        <div className="font-semibold w-10">
                                            נשלח?
                                        </div>
                                        {sent ? "כן" : "לא"}
                                    </div>

                                    <div className="flex gap-3">
                                        <div className="font-semibold w-10">
                                            מחיקה
                                        </div>
                                        <button
                                            className="grid place-content-center"
                                            onClick={deleteGuest}
                                        >
                                            <TrashIcon className="w-5 h-5 text-red-500" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {hasValuesChanged ? (
                                <div className="w-full flex gap-4 justify-center items-center mt-3">
                                    <button
                                        className="flex items-center gap-1 justify-center bg-green-500 p-1 text-white h-9 w-28 rounded-lg"
                                        onClick={async () => {
                                            await onChangeSubmit();
                                            close();
                                        }}
                                    >
                                        שמירה
                                        <CheckIcon className="w-4 h-4" />
                                    </button>
                                    <button
                                        className="flex items-center gap-1 justify-center bg-red-500 p-1 text-white h-9 w-28 rounded-lg"
                                        onClick={onChangeCancel}
                                    >
                                        ביטול
                                        <XMarkIcon className="w-5 h-5" />
                                    </button>
                                </div>
                            ) : null}
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>
    );
};

const UploadContactsViaWhatsapp = ({ eventId }: { eventId: string }) => {
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState<string>("972");
    const [phoneNumberError, setPhoneNumberError] = useState<
        string | undefined
    >();

    const handlePhoneSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isValidIsraeliPhoneNumber(phone)) {
            alert("מספר טלפון לא תקין");
            return;
        }
        const wa_id = `${countryCode}${phone.slice(1)}`; // remove "0" from "05..."
        await setDoc(doc(db, CONTACTS_UPLOADERS_COLLECTION, wa_id), {
            eventId,
        });
        setPhone("");
    };

    return (
        <form className="flex flex-row gap-2" onSubmit={handlePhoneSubmit}>
            <PhoneInput phone={phone} setPhone={setPhone} />
            <button
                disabled={!phone || !countryCode || phone.length < 10}
                className="w-14 text-rose-800 disabled:text-slate-300 flex items-center justify-end"
                type="submit"
            >
                <PlusIcon className="w-6 h-6 my-auto stroke-2" />
            </button>
        </form>
    );
};

const UploadGuests = () => {
    const [dialogToOpen, setDialogToOpen] = useState<
        "whatsapp" | "excel" | "manual" | null
    >(null);
    return (
        <>
            <WhatsappUploadPopup
                isOpen={dialogToOpen === "whatsapp"}
                setIsOpen={(isOpen) => {
                    if (!isOpen) {
                        setDialogToOpen(null);
                    }
                }}
            />
            <ExcelUploadPopup
                isOpen={dialogToOpen === "excel"}
                setIsOpen={(isOpen) => {
                    if (!isOpen) {
                        setDialogToOpen(null);
                    }
                }}
            />
            <ManualUploadPopup
                isOpen={dialogToOpen === "manual"}
                setIsOpen={(isOpen) => {
                    if (!isOpen) {
                        setDialogToOpen(null);
                    }
                }}
            />
            <div className="text-center">
                <h2 className="text-xl font-medium ">הוספת מוזמנים לרשימה</h2>
                <p className="text-slate-700 mt-0">
                    הוסיפו כאן את כל המוזמנים. הכי נוח דרך הוואטסאפ
                </p>
                <div className="flex flex-col gap-5 mt-5">
                    <button
                        className="bg-green-500 font-medium flex items-center justify-center gap-2 text-white p-2 rounded-full w-full text-center"
                        onClick={() => {
                            setDialogToOpen("whatsapp");
                        }}
                    >
                        הוספה דרך WhatsApp
                        <FaWhatsapp className="h-4 w-4" />
                    </button>
                    <button
                        className="bg-blue-500 font-medium flex items-center justify-center gap-2 text-white p-2 rounded-full w-full text-center"
                        onClick={() => {
                            setDialogToOpen("excel");
                        }}
                    >
                        הוספה דרך Excel
                        <FaFileExcel className="h-4 w-4" />
                    </button>
                    <button
                        className="border-slate-900 border font-mdeium text-slate-900 p-2 rounded-full w-full text-center"
                        onClick={() => {
                            setDialogToOpen("manual");
                        }}
                    >
                        הוספה ידנית
                    </button>
                </div>
            </div>
        </>
    );
};

const WhatsappUploadPopup = ({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}) => {
    const { event, loading: eventLoading } = useEvent();
    const [value, loading, error] = useCollection(
        event && event.eventId
            ? query(
                  collection(db, CONTACTS_UPLOADERS_COLLECTION),
                  where("eventId", "==", event.eventId)
              )
            : undefined
    );

    const submitRef = useRef<HTMLAnchorElement>(null);
    if (!event || !event.eventId) return null;

    function closeModal() {
        setIsOpen(false);
    }

    async function removeUploader(uploaderId: string) {
        const uploaderRef = doc(db, CONTACTS_UPLOADERS_COLLECTION, uploaderId);
        await deleteDoc(uploaderRef);
    }

    const contactsUploaderWhatsAppLink = `${
        isBrowser ? "https://wa.me/" : "whatsapp://"
    }send?phone=${CONTACTS_UPLOADER_PHONE_NUMBER}&text=${encodeURI("היי")}`;

    const areThereUploaders = value && value.docs.length > 0;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                initialFocus={submitRef}
                dir="rtl"
                as="div"
                className="relative z-10"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 font-heebo overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md min-h-60 relative transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all">
                                <button
                                    className="absolute left-4 top-4"
                                    onClick={closeModal}
                                >
                                    <XMarkIcon className="w-5 h-5" />
                                </button>
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl font-medium leading-6 text-gray-900"
                                >
                                    שליחת אנשי קשר מהוואטסאפ
                                </Dialog.Title>
                                <>
                                    <p className="text-sm mt-1 text-gray-500 mx-auto w-4/5">
                                        פשוט שולחים לנו אנשי קשר כמו שהייתם
                                        שולחים איש קשר לחבר בוואטסאפ
                                    </p>
                                    <div className="mt-5 text-start font-medium">
                                        מאילו מספרים תשלחו אלינו אנשי קשר:
                                        <div className="mt-3 text-start font-medium">
                                            <UploadContactsViaWhatsapp
                                                eventId={event?.eventId}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-5 ">
                                        {areThereUploaders ? (
                                            <ul className="flex flex-col gap-3 mt-5">
                                                {value.docs.map(
                                                    (doc, index, self) => {
                                                        const uploader = doc.id;
                                                        return (
                                                            <li
                                                                key={uploader}
                                                                className={`flex w-full py-1 justify-between`}
                                                            >
                                                                {index + 1}.{" "}
                                                                {formatPhoneNumber(
                                                                    {
                                                                        phoneNumber:
                                                                            uploader,
                                                                    }
                                                                )}
                                                                <button
                                                                    onClick={() =>
                                                                        removeUploader(
                                                                            uploader
                                                                        )
                                                                    }
                                                                >
                                                                    <XMarkIcon className="w-5 h-5 text-red-500" />
                                                                </button>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        ) : (
                                            <div className="text-start mb-3">
                                                לא קיימים מספרים. הוסיפו מספר
                                                טלפון ממנו תשלחו אנשי קשר
                                            </div>
                                        )}
                                    </div>

                                    {areThereUploaders ? (
                                        <div className="flex flex-col mt-5 gap-5 ">
                                            <a
                                                ref={submitRef}
                                                className="btn-primary"
                                                href={
                                                    contactsUploaderWhatsAppLink
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                התחלת העלאה
                                            </a>
                                            <div className="flex flex-col justify-between gap-1 items-center p-3 w-full bg-slate-200/80 text-black rounded-md ">
                                                <div className="flex justify-between gap-4 items-center w-full">
                                                    <CopyToClipboardButton
                                                        handleClick={() =>
                                                            navigator.clipboard.writeText(
                                                                contactsUploaderWhatsAppLink
                                                            )
                                                        }
                                                    />
                                                    <a
                                                        className="w-full text-sm text-end break-all"
                                                        href={
                                                            contactsUploaderWhatsAppLink
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {
                                                            contactsUploaderWhatsAppLink
                                                        }
                                                    </a>
                                                </div>
                                                <p className="text-xs text-slate-600">
                                                    קישור להתחלת שיחה עם בוט
                                                    אנשי הקשר
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <button
                                            disabled
                                            className="btn-primary"
                                        >
                                            התחלת העלאה
                                        </button>
                                    )}
                                </>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

const ExcelUploadPopup = ({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}) => {
    const { event, loading: eventLoading } = useEvent();
    function closeModal() {
        setIsOpen(false);
    }

    if (!event || !event.eventId) return null;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                dir="rtl"
                as="div"
                className="relative z-10 font-heebo"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md relative transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all">
                                <button
                                    className="absolute left-4 top-4"
                                    onClick={closeModal}
                                >
                                    <XMarkIcon className="w-5 h-5" />
                                </button>
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl font-semibold leading-6 text-gray-900"
                                >
                                    העלאת קובץ Excel
                                </Dialog.Title>
                                <>
                                    <ExcelToJsonConverter
                                        eventId={event.eventId}
                                        closeModal={closeModal}
                                    />
                                </>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

const ManualUploadPopup = ({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}) => {
    const { event, loading: eventLoading } = useEvent();
    function closeModal() {
        setIsOpen(false);
    }

    if (!event || !event.eventId) return null;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                dir="rtl"
                as="div"
                className="relative z-10 font-heebo"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md relative transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all">
                                <button
                                    className="absolute left-4 top-4"
                                    onClick={closeModal}
                                >
                                    <XMarkIcon className="w-5 h-5" />
                                </button>
                                <AddGuestForm />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
