// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCxNMMxKsl9duOq5TOo1bXOXA6JHqvs6jI",
    authDomain: "cincin-fe20a.firebaseapp.com",
    databaseURL:
        "https://cincin-fe20a-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "cincin-fe20a",
    storageBucket: "cincin-fe20a.appspot.com",
    messagingSenderId: "1027286511319",
    appId: "1:1027286511319:web:989f1707e307cc2ebe8a92",
    measurementId: "G-3CS5QSN1KB",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
const analytics = getAnalytics(app);

export const getBillDetails = httpsCallable(functions, "getBillDetails");
export const makePayment = httpsCallable(functions, "makePayment");
// connectFunctionsEmulator(functions, "localhost", 5000);

export const googleProvider = new GoogleAuthProvider();
