import MessagesTab from "../../components/messages/MessagesTab";
import { EventLayout } from "./guests";

export default function Messages() {
    return (
        <EventLayout title="ההודעות שנשלח">
            <MessagesTab />
        </EventLayout>
    );
}
