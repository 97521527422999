import SchedulerTab from "../../components/scheduler/SchedulerTab";
import { EventLayout } from "./guests";

export default function SchedulerPage() {
    return (
        <EventLayout title="תזמון ההודעות">
            <SchedulerTab />
        </EventLayout>
    );
}
