import {
    collection,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { EVENTS_COLLECTION, SBMS_COLLECTION } from "../../constants";
import { changePlan } from "../../db/utils";
import { db } from "../../firebase";
import useEvent from "../../hooks/useEvent";
import { MESSAGES_TYPES, PLANS } from "../../types";
import Loader from "../Loader";
import Scheduler, { Schedules } from "../Scheduler";

export default function SchedulerTab() {
    const { event, loading } = useEvent();

    if (loading) return <Loader fullScreen />;

    if (!event) return null;

    const { weddingDate, rsvp, thanks, reminder, plan, eventId, active } =
        event;

    const schedules = {
        firstRSVP: rsvp.schedules[0],
        secondRSVP: rsvp.schedules[1],
        reminder: reminder.schedules[0],
        thanks: thanks.schedules[0],
    };

    const updateSchedules = async (schedules: Schedules) => {
        if (!eventId) return;
        await updateDoc(doc(db, EVENTS_COLLECTION, eventId), {
            rsvp: {
                schedules: [schedules.firstRSVP, schedules.secondRSVP],
                template: rsvp.template,
            },
            reminder: {
                schedules: [schedules.reminder],
                template: reminder.template,
            },
            thanks: {
                schedules: [schedules.thanks],
                template: thanks.template,
            },
        });
        if (active) {
            // update SBMs schedules as well
            const eventSBMs = await getDocs(
                query(
                    collection(db, SBMS_COLLECTION),
                    where("eventId", "==", eventId)
                )
            );

            const rsvpSBM = eventSBMs.docs.find(
                (sbm) => sbm.data().type === MESSAGES_TYPES.RSVP
            ); // must exist
            const reminderSBM = eventSBMs.docs.find(
                (sbm) => sbm.data().type === MESSAGES_TYPES.REMINDER
            ); // might exist
            const thanksSBM = eventSBMs.docs.find(
                (sbm) => sbm.data().type === MESSAGES_TYPES.THANKS
            ); // might exist

            if (rsvpSBM) {
                const { isSecondRound } = rsvpSBM.data();
                await updateDoc(doc(db, SBMS_COLLECTION, rsvpSBM.id), {
                    scheduledTo: isSecondRound
                        ? schedules.secondRSVP
                        : schedules.firstRSVP,
                });
            }
            if (reminderSBM) {
                await updateDoc(doc(db, SBMS_COLLECTION, reminderSBM.id), {
                    scheduledTo: schedules.reminder,
                });
            }
            if (thanksSBM) {
                await updateDoc(doc(db, SBMS_COLLECTION, thanksSBM.id), {
                    scheduledTo: schedules.thanks,
                });
            }
        }
    };

    // const updateSchedules = async (schedules: any) => {
    //     if (!event.eventId) return;
    //     await updateDoc(doc(db, EVENTS_COLLECTION, event.eventId), {
    //         rsvp: {
    //             ...rsvp,
    //             schedules: [schedules.firstRSVP, schedules.secondRSVP],
    //         },
    //         reminder: {
    //             ...reminder,
    //             schedules: [schedules.reminder],
    //         },
    //         thanks: {
    //             ...thanks,
    //             schedules: [schedules.thanks],
    //         },
    //     });
    // };

    return (
        <div className="max-w-[1440px] mx-auto">
            <Scheduler
                plan={plan}
                schedules={schedules}
                updateSchedules={updateSchedules}
                weddingDate={weddingDate}
                setPlan={async (plan: PLANS) => {
                    if (!event.eventId) return;
                    await changePlan({ eventId: event.eventId, plan });
                }}
                eventActive={active}
            />
        </div>
    );
}
