export default function Terms() {
    return (
        <div className="flex flex-col gap-5 py-20 px-5 lg:px-10">
            <h1 className="text-center text-3xl">
                תנאי שימוש באתר, צ'אטבוט ובשירות "צ'ין צ'ין אישורי הגעה - CinCin
                RSVP"
            </h1>
            <h3 className="text-lg py-3">
                התקנון ותנאי השימוש הנ"ל נכתבו בלשון זכר אך הינם והכתוב בהם
                מתייחסים לנשים וגברים כאחד.
            </h3>
            <p>
                הנכם משתמשים וגולשים באתר CinCin RSVP אשר מספק שירותי אישורי
                הגעה באמצעות פלטפורמה דיגיטלית וצ'אטבוט . (יחד להלן:
                "השירותים").
                <br />
                <br />
                בעל האתר ומפעילו הינו IS Chat Solutions ע.מ. 311508873 (להלן:
                "האתר" ו/או "מפעיל/ת האתר" ו/או "מפעיל/ת השירות" ו/או "החברה").
                <br />
                <br />
                תנאי השימוש באתר מגדירים את היחסים בין האתר לבין כל גולש ו/או
                משתמש (להלן "הלקוח" ו/או "המשתמש"). השימוש באתר ובתכנים המוצגים
                בו מכל מכשיר שהוא כפוף לתנאי השימוש ומחייב את קריאת התקנון
                ומדיניות הפרטיות.
                <br />
                <br />
                כל שימוש באתר ובתכנים המוצגים בו, כולל העיון והגלישה בו, וכן כל
                פעולה בו, כולל רכישה באמצעותו, נכפים לתנאי השימוש המפורטים להלן.
                נא לקרוא בעיון ובקפידה את התנאים המפורטים. אין חובה להסכים
                לתנאים אלו, אך במקרה של אי הסכמה, משתמש נדרש לסיים את השימוש
                באתר מיד, ואסור עליו לבצע כל שימוש נוסף. התחלת השימוש באתר מהווה
                הסכמה ואישור לתנאים המפורטים כאמור.
                <br />
                <br />
                השימוש באתר מהווה את ההסכמה של המשתמש לכל תנאיו, ללא הגבלה או
                הסתייגות. בעת ביצוע שימוש באתר, המשתמש מתחייב שלא יגיעו ממנו, או
                מטעמו, טענות או תביעות בצורה ישירה או עקיפה, נגד האתר ו/או בעליו
                ו/או מפעיליו ו/או מנהליו ואו מי מטעמו.
                <br />
                <br />
                תנאי השימוש באתר משתמשים בלשון זכר לצורך נוחות בלבד, ומתכוונים
                לנשים וגברים כאחד.
                <br />
                <br />
                כותרות הפרקים מובאות לנוחות והתמצאות בתנאי השימוש ואינן מיועדות
                לשמש לצורך פרשנותו.
                <br />
                <br />
                האתר שומר לו את הזכות להציע, לשנות, ולהחליף מבצעים והטבות. כמו
                כן, האתר שומר לו את הזכות להפסיק את המבצעים וההטבות בכל עת וללא
                צורך בהודעה מוקדמת. לא תינתן האפשרות לכפל מבצעים למעט במקרים בהם
                יצוין אחרת על ידי מפעילת האתר.
                <br />
                <br />
                הגישה והשימוש באתר האינטרנט כולל שימוש בכל התכנים, המידע
                והשירותים המוצגים בו, הינם כפופים לתנאי השימוש המפורטים להלן
                ולהוראות כל דין החל במדינת ישראל.
                <br />
                <br />
                סרטוני הדרכה, תמונות והצגת השירותים מוצגים למטרות המחשה בלבד
                ואין להם תוקף של חוזה או התחייבות מכיוון מפעילת האתר.
                <br />
                <br />
                השימוש באתר ובתכנים שבו מיועד לשימוש פרטי ואינו מסחרי, למעט
                במקרים בהם נקבע אחרת על ידי מפעילת האתר. אסור להעתיק, לשכפל,
                להפיץ, למכור או להציג מוצרים באתר ללא רשות מראש ובכתב מצד מפעילת
                האתר.
                <br />
                <br />
                כל אדם או חברה המבצע/ת פעולה כלשהי באתר, לרבות אדם המבצע הרשמה
                לשירות לעיל ולהלן: ("הלקוח") מצהיר בעת ביצוע הפעולה כי קרא תנאי
                שימוש אלו, הבין אותן וכי הוא מסכים לתנאים ולהוראות.
                <br />
                <br />
                <b>תקנון</b>
                <br />
                <br />
                1. כללי
                <br />
                1.1 משמעות התקנון - גלישה באתר ו/או כניסה אליו ו/או שימוש
                בשירותים המוצעים בו, מבטאת ומהווה הסכמה מצדך לתנאי תקנון זה
                ולאמור בו, והם יחולו על כל פעולה שביצעת ותבצע באתר. אם אינך
                מסכים לתנאי השימוש, כולם או חלקם, אינך רשאי לגלוש ו/או לעשות כל
                שימוש באתר לכל מטרה שהיא.
                <br />
                1.2 כוחו הנורמטיבי של התקנון - בכל מקרה של סתירה בין התקנון לבין
                כל פרסום אחר, מכל סוג ומין, תגברנה הוראות תקנון זה, והן אשר
                תחייבנה את הגולש ביחסיו עם הנהלת האתר.
                <br />
                <br />
                2. הגדרות
                <br />
                2.1 "גלישה באתר" - שימוש מכל סוג ומכל דרך קיימת ועתידית וביניהם
                באמצעות מחשב ו/או באמצעות טלפון סלולארי ו/או מכשירים אחרים. עצם
                השימוש באתר ותכניו כפוף לתנאים המפורטים בתקנון האתר, ועצם הכניסה
                מעיד כי הגולש קרא בעיון את התקנון והבין את המשמעויות הישירות
                והעקיפות שבו והביע הסכמתו ואישור לאמור בתקנון זה.
                <br />
                2.2 "גולש" - כל אדם ו/או תאגיד ו/או גוף כלשהו, בין אם מדובר
                בלקוח ובין אם מדובר בבעל עסק, חברה ו/או כל הגדרה אחרת, המבצעים
                גלישה באתר כמוגדר לעיל.
                <br />
                2.3 "מידע אישי/פרטים אישיים" - משמעו כל מידע מזהה של הגולש,
                לרבות שמו הפרטי ושם משפחתו, מספר הטלפון הנייח והנייד, כתובת
                מגורים, כתובת דואר אלקטרוני, כתובת מתחם אישי (Domain name) וכל
                מידע מזהה אחר אשר נמצא באתר ובכל האינטרנט.
                <br />
                <br />
                3. הגבלות שימוש באתר
                <br />
                3.1 כל גולש מוזמן להשתמש באתר, כל עוד הוא מקבל על עצמו את תנאי
                תקנון זה. על גולשים מתחת לגיל 18 לקרוא את התנאים המיוחדים החלים
                לגבי קטינים בתקנון זה.
                <br />
                3.2 הנהלת האתר רשאית למנוע השתתפותו של כל גולש בכל אמצעי העומד
                לרשותה, בחלק מהשירותים או בכולם, לתקופה קצובה ובלתי קצובה, בהתאם
                לשיקול דעתה הבלעדי ומכל סיבה שהיא, ללא חובה להסביר או לנמק החלטה
                זו. גולש אשר השתתפותו נמנעה כאמור, לא יהיה רשאי לשוב ולהשתתף תחת
                שם משתמש אחר בתקופת המניעה.
                <br />
                3.3 אין לבצע כל שימוש מסחרי שאינו אישי באתר ובכלל זה נאסר על
                הגולש לפרסם את כתובת האתר בכל אתר אינטרנט או בכל פרסום מסחרי מכל
                סוג שהוא למטרת שימוש מסחרי, ללא רשות מפורשת בכתב של הנהלת האתר.
                ככל שברצונך לבצע באתר כל שימוש אחר, לרבות העלאת חומרים שיווקיים
                וכדומה יש לפנות להנהלת האתר.
                <br />
                3.4 המשתמש מצהיר ומתחייב כי לא יעשה כל שימוש בשירות על מנת להפיץ
                כל תוכן פוגעני ו/או גזעני ו/או מטריד ו/או הפוגע בפרטיותו של אדם
                כלשהו ו/או כל תוכן הנוגד כל דין. האתר יהא רשאי על פי שיקול דעת
                והמלא והבלעדי להפסיק את השירות ו/או למנוע שימוש בשירות ו/או
                לחדול מלהעביר הודעות במסגרת השירות בכל מקרה של חשש לשימוש לרעה
                או במקרה של חשש להפרת הוראות הדין וזאת ללא כל הודעה מראש.
                <br />
                <br />
                4. זכויות קנייניות
                <br />
                4.1 כל הזכויות באתר, לרבות זכויות הקניין הרוחני בשירותים הנלווים
                הינם של האתר בלבד.
                <br />
                4.2 להסרת ספקות, האתר הינו הבעלים הבלעדי במאגרי הנתונים של האתר,
                התמונות, הטקסטים וכל חומר אחר הכלול בו. מובהר כי אין להעתיק,
                להפיץ, לשכפל, לשנות, לפרסם, לשדר, להעביר, למכור, להפיץ, ליצור
                יצירות נגזרות, להציג בפומבי, להעמיד לרשות הציבור או למסור לצד
                שלישי את המידע באתר ו/או כל חלק ממנו, לרבות עשיית שימוש מסחרי
                כלשהו במידע באתר, אלא בהיתר מראש ובכתב של הנהלת האתר. למרות זאת,
                החברה לא תהיה אחראית כלפי שום הגולש במקרה בו תעלה טענה או קביעה
                של פגיעה או הפרה כלשהי של זכויות קניין רוחני כלשהן בקשר עם רשות
                השימוש המוענקת לו באתר.
                <br />
                4.3 יודגש כי האתר מאפשר לבעלי עסקים שונים המופיעים באתר, אפשרות
                גישה ישירה לאתר באופן שיוזנו נתונים באופן עצמאי על ידי אותם בעלי
                עסקים. הגם שלאתר אין ולא תהיה אחריות כלשהי לגבי אותם תכנים, אין
                בכך כדי לפגוע בזכויות הקניין של האתר בנוגע לכל מידע המופיע בו
                וזכויות השימוש באותו מידע.
                <br />
                4.4 בעלי עסקים ו/או לקוחות ו/או כל גורם עסקי אחר מוגדר ביחסיו עם
                האתר ובין אם לאו, מכל סוג ומין שהוא, בין באם הזינו מידע באופן
                עצמאי לאתר ובין אם באמצעות האתר, לא יוכלו לטעון לזכויות קניין
                כלשהם ביחס לחומרים שיועלו לאתר והם מקנים זכויות אלו לאתר או מי
                מטעמו במלואן, כך שהאתר יוכל לעשות בהם כל שימוש שימצא לנכון וזאת
                אלא אם כן הגדירו זאת הצדדים אחרת בכתב.
                <br />
                4.5 האתר ו/או בעליו יהיו הבעלים הבלעדיים של כל מידע שנאסף באתר
                לרבות היקף השימוש בו, זהות המשתמשים בו, אופן השימוש בו ו/או כל
                מידע אחר הנוגע לגלישה באתר ומסד הנתונים שיצטבר ביחס לכך. האתר
                יהיה הבעלים הבלעדיים של מידע זה ויוכל לעשות כל שימוש במידע
                שיצטבר במסד הנתונים שייאסף באתר לרבות סיחור במידע או העברתו כלפי
                צדדים שלישיים, בין בתמורה ובין אם לאו. לגולש לא תהיה כל התנגדות
                לאופן השימוש במידע זה ובעצם כניסתו לאתר הוא מאשר את השימוש במידע
                זה.
                <br />
                4.6 כל המפר תקנון זה ישפה את הנהלת האתר, עובדיו, מנהליו, בעלי
                מניותיו או מי מטעמם בגין כל נזק, הפסד, אבדן רווח, הוצאה לרבות
                שכר טרחת עורך דין והוצאות משפט שיגרמו כתוצאה מהפרה זו.
                <br />
                <br />
                5. רישום לאתר ולשירותיו
                <br />
                5.1 אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך יתכנו
                בשל שיקולים טכניים, תקלות צד ג' או אחרים, הפרעות בזמינות האתר.
                ולכן איננו יכולים להתחייב כי האתר יהיה זמין לכם בכל עת ולא יינתן
                כל פיצוי כספי או אחר בשל הפסקת פעילות האתר.
                <br />
                5.2 קישורים לאתרים חיצוניים אינם מהווים ערובה כי מדובר באתרים
                בטוחים, איכותיים או אמינים וביקור בהם נעשה על דעתכם האישית בלבד
                ונמצאים בתחום האחריות הבלעדי של הגולש באתר.
                <br />
                5.3 מובהר כי ככל שהפרטים שנמסרו על ידי הגולש אינם נכונים, האתר
                שומר לעצמו את הזכות, בהתאם לשיקול דעתו הבלעדי, למנוע מגולש גישה
                לאתר או לאסור עליו שימוש באתר ובשירותיו.
                <br />
                <br />
                6. שימוש באתר על ידי קטינים (מתחת לגיל 18)
                <br />
                השימוש באתר מותר לקטין וזאת כל עוד לא מבוצעת על ידו פעולה האסורה
                על פי דין, אך השימוש לקטינים כפוף לאישור הוריהם או האפוטרופוס
                החוקי שלהם. עצם השימוש באתר, כמוהו כהצהרה שההורה ו/או האפוטרופוס
                אישר את שימוש הקטין באתר, לאחר שקרא בעיון את תנאי תקנון זה. כאשר
                קטין משתמש באתר, האתר פטור מכל אחריות בקשר לכך ולא תהיינה לו כל
                תביעות, טענות או דרישת כלפי האתר.
                <br />
                <br />
                7. העדר אחריות האתר ו/או מי מטעמו
                <br />
                7.1 כללי- העדר אחריות
                <br />
                7.1.1 הגולש מצהיר כי האתר אינו נושא באחריות והוא מוותר על כל
                תביעה, טענה ודרישה כנגד האתר ו/או הפועל מטעמה. הגולש פוטר ומשחרר
                את האתר ו/או מי מטעמו באופן מוחלט ובלתי חוזר מאחריות לכל סוג
                נזק, אי נוחות, אבדן מכל סוג, עוגמת נפש, הפסד, הוצאות, פיצוי
                נזיקי מכל סוג שהוא וכדומה, שנגרמו במישרין ובעקיפין לרכוש כל גולש
                שהוא או כל צד שלישי בשל שימוש בתכנים ובשרותי האתר (לרבות אם האתר
                לא מבצע את שירותיו במסגרת החבילה שנרכשה מכל סיבה שהיא).
                <br />
                7.1.2 הגולש מצהיר כי אין האתר ובעליו אחראיים לתוכן ו/או לזמינות
                האתר או לזמינות אתרים מקושרים ובכל מכשיר קצה בו הם מופיעים, וכי
                הוא פוטר את האתר ו/או מי מטעמו מאחריות לפיצוי כלשהו בגין
                עיכובים, חוסר יכולת להשתמש באתר ובשירותיו ומחדירה בלתי מורשית
                למחשבים וכדומה, אף כאשר נודע לאתר על אפשרות של נזק פוטנציאלי או
                קיים.
                <br />
                7.1.3 הגולש מתחייב כי במידה ויפר זכויות על פי כל דין או הוראה
                מהוראות תקנון זה, האתר מסיר מעצמו אחריות והגולש יישא באחריות
                הבלעדית והמלאה לתביעה. בכלל האמור, הגולש יהיה אחראי כלפי האתר
                ביחס לכל תביעה ו/או הפרה שתוגש כנגדו ביחס לחומרים שיועלו באתר על
                ידי גולשים. אלו ישפו את החברה בגין כל תביעה שתוגש כנגדה לרבות
                הוצאות ושכ”ט עו”ד או כל הוצאה אחרת שתיגרם להנהלת האתר וכן בגין
                כל פגיעה בשמו הטוב ובמוניטין של האתר ו/או של מפעילו.
                <br />
                7.1.4 האתר ו/או בעליו אינו אחראי לתגובות התכנים באתר או לשימוש
                שיעשו אחרים בתכני הגולשים כגון פגיעה בזכויות קניין רוחני.
                <br />
                7.1.5 באתר זה עשוי לעשות שימוש בקבצי קוקיז (במיוחד עבור משתמשים
                רשומים ומנויים) ובממשקי סטטיסטיקה פנימיים בכדי לשמור תיעוד
                סטטיסטי אנונימי של גולשים וניתוח תנועת הגולשים, הרגלי גלישה באתר
                וניתוח קליקים וזמן שהייה.
                <br />
                7.2 העדר אחריות האתר לתכני צד ג’ והגולשים
                <br />
                7.2.1 האתר לא יישא בכל אחריות לתוכן והמידע שיפורסמו באתר. יובהר
                למען הסר ספק, כי האתר אינו מביע תמיכה, מעודד, מסכים או נותן חסות
                במפורש ו/או בכלל לתכנים ו/או לשירותים המוצעים על ידי אחרים. כמו
                כן, האתר אינו נותן חסות, מעודד, מציע, מסכים או מביע דעה לגבי
                נכונות ו/או דיוק ו/או רמת איכות התכנים של כל מידע, פרסומות,
                שירותים, מוצרים, דעות ועמדות המוצגים באתר מטעם הגולשים וצדדים
                שלישיים.
                <br />
                7.2.2 כל הסתמכות המשתמש על כל תוכן, מידע, תמונות, פרסומות,
                מוצרים, סרטונים, דעות ועמדות המוצגים או המפורסמים באתר זה
                ובאתרים אליהם ישנה אפשרות הפניה, לרבות במידע, תוכן, דעות ועמדות
                המוצגים ומפורסמים שם, נעשה על פי שיקול דעתו הבלעדי של הגולש ועל
                אחריותו הבלעדית. האתר אינו אחראי לכל תוצאה שתיגרם מהשימוש באתר
                צד ג’ וההסתמכות עליו.
                <br />
                7.3 העדר אחריות האתר לעסקאות עם צד ג’ ובין קהילת הגולשים
                <br />
                7.3.1 האתר אינו צד לכל עסקה בין הגולשים לבין עצמם ו/או בין גולש
                ללקוח עסקי, והוא לא יישא באחריות לשירותים ולמוצרים שיוצעו באתר
                או באתרים אליהם הגיעו הגולשים באמצעות קישורים מהאתר ולרכישות
                שיבוצעו בעקבות כך. הגולש מוותר בזאת על כל תביעה מכל סוג שהוא
                כלפי הנהלת האתר בעניין זה.
                <br />
                <br />
                8. תלונות בנוגע לתוכן
                <br />
                8.1 בכל סוגיה הנוגעות לתוכן ושימוש באתר, יש לפנות להנהלת האתר
                בעמוד "צור קשר" לצורך הגשת תלונה בנוגע לתכנים. יש לציין בפנייה
                קישור (לינק) למיקום המדויק בו מופיע התוכן האמור וכן לציין פרטים
                מדויקים ליצירת קשר.
                <br />
                8.2 על אף שהאתר ישתדל לטפל בבעיה בהקדם האפשרי, הוא אינו מתחייב
                למועד גמר הטיפול. גם הפניית התלונה למעלה התכנים באתר שהנו צד
                שלישי תחשב כטיפול מלא ומקיף בתלונה.
                <br />
                <br />
                9. הדין החל וסמכות שיפוט
                <br />
                9.1 על תקנון זה ועל כל הנובע ממנו או הכרוך בו, יחולו אך ורק דיני
                מדינת ישראל והוא יפורש על פיהם. מקום השיפוט הבלעדי לכל עניין
                הנוגע לתקנון זה או לשימוש באתר הינו בבתי המשפט המוסמכים בתל-אביב
                בלבד.
                <br />
                <br />
                <b>השירותים</b>
                <br />
                10. ההרשמה והשירות באתר ניתנים בחינם לפי מודל "נסה לפני שאתה
                קונה" (Try Before You Buy), מודל זה מאפשר לך להשתמש בשירות עד
                לגבול מסוים של מידע, לפני שתחליט אם ברצונך לשדרג לחבילה בתשלום.
                ייתכן שחלק מהפונקציות של השירות יהיו זמינות רק בחבילות בתשלום.
                <br />
                <br />
                11. באמצעות הפלטפורמה, הודעות אישורי הגעה יישלחו לאורחים
                המוזמנים לאירוע באמצעות ה-WhatsApp של המשתמש ו/או ה-WhatsApp של
                מפעילת האתר ו/או SMS ו/או שיחות טלפון על ידי מוקד אנושי - מובהר
                כי השימוש באמצעים אלו מותר רק למטרות אישורי הגעה ואסור להשתמש
                בהם לצורך ספאם או שימושים אחרים. במקרה בו יעשה שימוש כאמור על
                ידי המשתמש הוא יהיה חייב בשיפוי החברה בעבור כל נזק שנגרם עבורה
                בין במישרין ובין בעקיפין בגין שימוש חורג כאמור.
                <br />
                <br />
                12. שימוש בשירותי ההתקשרות לאורחי האירוע הינו למטרות אישורי הגעה
                והינו בהתאם לימים ושעות פעילות המוקד הטלפוני. כל שליחת הודעה מכל
                סוג בימי מנוחה, ערבי חג, ימי זיכרון וכיפור הינן על אחריותו
                הבלעדית של המשתמש.
                <br />
                <br />
                13. כחלק מההודעות שנשלחות לאורח (הזמנה לאירוע, תזכורת לאירוע
                וכו') ו/או בקישור ההזמנה הדיגיטלית של האירוע, עשוי להיות קישור
                להענקת מתנה באשראי לאירוע. יובהר כי הודעות אלו אינן הודעות
                שיווקיות מכל סוג. החברה אינה אחראית ואינה מספקת פלטפורמה לפרסום
                הודעות שיווק. המטרה העיקרית היא לתווך את תהליך הענקת המתנה
                מהאורחים לבעלי האירוע. טענות מסוג זה יופנו למפעיל האירוע
                (המשתמש) והוא יצטרך לשפות את מפעילת האתר בגין תלונה כאמור.
                <br />
                <br />
                14. המשתמש באתר יכול לשתף ולקשר תכנים מהשירות באמצעות רשתות
                חברתיות שונות ושירותים של צדדים שלישיים, כמו פייסבוק ואינסטגרם.
                שימוש ברשתות חברתיות ושירותי צד ג' יתבצע על אחריות המשתמש בהתאם
                למדיניות של הרשתות ו/או הצדדים השלישיים, ואינו קשור למפעיל
                השירות.
                <br />
                <br />
                15. בעת שימוש בשליחת הודעות מחשבון ה-WhatsApp האישי של המשתמש
                יובהר כי במצבים בהם נשלחת הודעה שמכילה קישור לאורח שמעולם לא
                התכתבתם איתו בעבר, WhatsApp לא תאפשר לחיצה על אותו קישור (יופיע
                בפורמט שאינו לחיץ). בנוסף הוא יראה מתחת להודעה שקיבל התראה מ-
                WhatsApp המבקשת ממנו לאשר לה שהוא מעונין לקבל את התוכן שנשלח לו.
                האורח יכול לאשר ל- WhatsApp באחת מארבע הדרכים הבאות:
                <br />
                א. ללחוץ על המשך / CONTINUE בהתראה שמוצגת לו.
                <br />
                ב. להשיב להודעה שנשלחה לו, לא משנה באיזה תוכן.
                <br />
                ג. ללחוץ על כפתור "הפוך קישור ללחיץ" (זמין רק בשליחה אוטומטית
                מהמערכת).
                <br />
                ד. להוסיף את מספר השולח לאנשי הקשר שלו בטלפון.
                <br />
                <br />
                16. שירות אישורי הגעה בתשלום ישולמו מראש על ידי המשתמש. כדי לקבל
                את השירות, יידרש המשתמש, בעל האירוע, להירשם באתר או באפליקציה
                ולמלא את הפרטים הנדרשים. כמו כן, נדרש לוודא את המועד הרצוי לקבלת
                השירות מצד החברה לפני זמן השירות.
                <br />
                במקרים של שירותי שליחת הודעות וביצוע שיחות (כפי שהוגדרו להלן),
                החברה תתחיל לספק את השירות במועד שהוזן לצורך אישור הגעה, באמצעות
                הודעות או שיחות טלפוניות.
                <br />
                <br />
                18. נוסח ההודעות שישלחו למוזמנים באמצעות חשבון ה-WhatsApp של
                החברה יהיה קבוע מראש ולא יהיה ניתן לשינוי או עריכה על ידי
                המשתמש.
                <br />
                בתוך נוסח המסרונים יוטמעו באופן אוטומטי פרטי האירוע כפי שהוזנו
                על ידי המשתמש. ייתכנו עיכובים בעת שליחת ההודעות, ככל שישנם
                עומסים על השרתים ו/או גורמים חיצוניים שעשויים להשפיע על תהליך
                שליחת ההודעות.
                <br />
                <br />
                19. החברה אינה אחראית אם פניה אינה מגיעה ליעדה או אם אין אפשרות
                לכל אדם לאשר או לעדכן את סטטוס הגעתו, מכל סיבה שהיא.
                <br />
                <br />
                20. המשתמש מסכים לאפשר לחברה להעביר לאולם האירוע ולכל מי מטעמו
                או נותן שירות אחר הקשור לאירוע, את מספר המוזמנים שאישרו את הגעתם
                לאירוע.
                <br />
                <br />
                קיימת למשתמש ("בעל האירוע") האפשרות לבטל את תצוגת "שם האורח"
                בהזמנה האישית דרך עמוד ההגדרות של האירוע.
                <br />
                <br />
                22. בשירות בו מתבצעת שליחה של הודעות ב-WhatsApp על ידי המערכת,
                על המשתמש לספק את רשימת המוזמנים באמצעות קובץ אקסל ("Excel")
                בתבנית ייעודית ו/או באמצעות הוספה מאנשי הקשר של המכשיר הסלולרי
                (באמצעות שימוש באפליקציה בלבד ובאישור גישה לאנשי הקשר) ו/או
                בהוספה ידנית על ידי מילוי פרטי המוזמנ/ת דרך המערכת.
                <br />
                <br />
                23. המשתמש נדרש לבדוק במערכת כי הרשימה שהועלתה למערכת היא תקינה
                ומעודכנת לשביעות רצונו.
                <br />
                <br />
                25. בעת רכישה של חבילות הפרימיום המערכת תקבע באופן אוטומטי את
                שלבי תהליך אישורי ההגעה ותציב מועד לביצוע כל פעולה בהתאם למועד
                האירוע כפי שהוזן במערכת על ידי הלקוח. קיימת האפשרות למשתמש לשנות
                מועדים אלו מלבד מועדי קיום שיחות טלפוניות על ידי נציגים אנושיים,
                ככל וידרש שינוי במועדי ביצוע השיחות יש לפנות לחברה.
                <br />
                לחברה קיימת הזכות לא לבצע שינוי במועדי ביצוע השיחות בהתאם ללו"ז
                המוקד אך ייעשה מאמץ להיענות לבקשת זמני הלקוח.
                <br />
                <br />
                26. במצב של חריגה בכמות הרשומות של החבילה שנרכשה מבין החבילות
                תינתן האפשרות לשלם עבור הרשומות שחורגות מהחבילה בלבד לפי התעריף
                הבא:
                <br />
                עבור חבילת "נחמד וטוב" - 0.6 ש"ח לרשומה
                <br />
                עבור חבילת "מושלם" - 0.5 ש"ח לרשומה
                <br />
                עבור חבילת "פרימיום" - 0.4 ש"ח לרשומה
                <br />
                <br />
                <b>מדיניות ביטולים</b>
                <br />
                1. האמור בסעיף זה כפוף לחוק הגנת הצרכן התשמ״א 1981 (להלן:
                "החוק").
                <br />
                2. על המשתמש אשר מזמין את השירות היכולת לבטל את העסקה שביצע,
                הזיכוי יבוצע בכרטיס האשראי שבו בוצעה העסקה. הזיכוי יתבצע במקרים
                ובתנאים הבאים:
                <br />
                ככל ולקוח יהיה מעוניין לבטל את ההזמנה של השירות או מוצר, ביטול
                ההזמנה יהיה כרוך בעמלת דמי טיפול של 5% או 100 ₪, הזול מבניהם
                בתוך 14 ימי עסקים מיום ההזמנה, זיכוי כספי יינתן עפ״י האמור בחוק.
                כאשר חלק מהשירות סופק ללקוח קרי קבלת אישורי הגעה דרך הפלטפורמה,
                דמי הביטול יהיו בגובה של השירות המסוים שסופק, ולא פחות מדמי
                הביטול הקבועים בחוק. בכל אופן, לא יינתן זיכוי כספי על תשלום לאחר
                14 ימי עסקים ממועד ביצוע העסקה. תשלום עבור שירות הנעקת מתנה
                באשראי לא ניתן לזיכוי והתשלום לא יוחזר ללקוח בהתאם לחוק הגנת
                הצרכן (מידע נוסף ניתן למצוא בתנאי השימוש של שירות הענקת מתנה
                באשראי).
                <br />
                3. בירורים לגבי עסקאות וכן הודעה על ביטול עסקה יכולה להתבצע באחת
                משתי האפשרויות כדלקמן: במייל idan.schiller@gmail.com, בפניה
                מקוונת ב-WhatsApp למספר 058-7501168.
                <br />
                <br />
                <b>שינוי במדיניות הפרטיות</b>
                <br />
                המפעילה של האתר שומרת על הזכות לבצע עדכונים במדיניות הפרטיות
                מתוך שיקול דעתה הבלעדי וללא כל הודעה מראש למשתמשים. שינויים אלו
                ייכנסו לתוקף תוך 48 שעות מרגע העדכון. המפעילה רשאית להודיע
                למשתמשים על כל שינוי במדיניות הפרטיות על פי שיקול דעתה. במקרה של
                שינויים מהותיים, היא תפרסם הודעה בעמוד הבית של האתר, וזאת 30
                ימים לפני כניסתם לתוקף. המשך השימוש באתר מהווה את ההסכמה לנוסח
                המעודכן של מדיניות הפרטיות. אם המשתמש אינו מסכים עם הנוסח
                המעודכן, עליו להפסיק את השימוש באתר או באפליקציה.
                <br />
                <br />
                <b>עדכון בתאריך 01.05.2024</b>
            </p>{" "}
            {/* <ol>
                <li>
                    <h4 className="text-xl py-3">קדימון</h4>
                    <p>
                        אתר "See Ypu There RSVP" (להלן "האתר") הוא אתר המשתמש
                        כאתר (רשימת קניות בתוך הוואטסאפ) ייצוגי עבור "See Ypu
                        There RSVP" והנך מוזמן לקחת בו חלק בכפוף להסכמתך לתנאי
                        השימוש אשר יפורטו להלן
                    </p>
                    <p>האתר, מוצע למשתמש כמות שהוא&nbsp;(‘As Is’).</p>
                    <p>
                        בנוסף השימוש באתר זה על כל תכניו והשירותים המוצעים בו,
                        הורדות של קבצים, מדיה כגון תמונות וסרטונים והתכנים
                        השונים המוצעים לקהל המבקרים עשויים להשתנות מעת לעת או
                        בהתאם לסוג התוכן.
                    </p>
                    <p>
                        הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש
                        המוצגים להלן מעת לעת וללא התראה או אזכור מיוחד בערוצי
                        האתר השונים.
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">קניין רוחני</h4>
                    <p>
                        האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר,
                        קבצי מדיה לרבות גרפיקות, סרטונים, תמונות, טקסטים, קבצים
                        המוצעים להורדה וכל חומר אחר אשר מוצג באתר שייכים במלואם
                        לאתר הנ"ל ומהווים קניין רוחני בלעדי של אתר "See Ypu
                        There RSVP" ואין לעשות בהם שימוש ללא אישור כתוב מראש
                        מאתר "See Ypu There RSVP".
                    </p>
                    <p>
                        בנוסף אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או לעבד
                        פיסות קוד, גרפיקות, סרטונים, סימנים מסחריים או כל מדיה
                        ותוכן אחר מבלי שיש ברשותכם אישור כתוב מראש.
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">תוכן האתר</h4>
                    <p>
                        אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך
                        יתכנו בשל שיקולים טכניים, תקלות צד ג או אחרים, הפרעות
                        בזמינות האתר. ולכן איננו יכולים להתחייב כי האתר יהיה
                        זמין לכם בכל עת ולא יינתן כל פיצוי כספי או אחר בשל הפסקת
                        השירות / הורדת האתר.
                    </p>
                    <p>
                        קישורים לאתר חיצוניים אינם מהווים ערובה כי מדובר באתרים
                        בטוחים, איכותיים או אמינים וביקור בהם נעשה על דעתכם
                        האישית בלבד ונמצאים בתחום האחריות הבלעדי של המשתמש באתר.
                    </p>
                    <p>
                        התכנים המוצעים באתר הינם בבעלותם הבלעדית של "See Ypu
                        There RSVP" ואין לעשות בהם שימוש אשר נועד את האמור
                        בתקנון זה (ראה סעיף 3) למעט במקרים בהם צוין אחרת או
                        במקרים בהם צוין כי זכויות היוצרים שייכים לגוף חיצוני.
                        במקרים אלו יש לבדוק מהם תנאי השימוש בקישור המצורף ולפעול
                        על פי המצוין באתר החיצוני לו שייכים התכנים.
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">ניהול משתמשים ומבקרים באתר</h4>
                    <p>
                        הנהלת האתר שומרת לעצמה את הזכות לחסום כל משתמש ובין אם
                        על ידי חסימת כתובת הIP של המחשב שלו, כתובת הMACID של
                        המחשב שלו או אפילו בהתאם למדינת המוצא ללא צורך לספק
                        תירוץ אשר מקובל על הגולש.
                    </p>
                    <p>
                        צוות האתר / הנהלת האתר יעשה כל שביכולתו להגן על פרטי
                        המשתמשים הרשומים באתר / מנויים הרשומים באתר. במקרים בהם
                        יעלה בידיו של צד שלישי להשיג גישה למידע מוסכם בזאת כי
                        לגולשים, משתמשים וחברים באתר לה תהה כל תביעה, טענה או
                        דרישה כלפי צוות האתר "See Ypu There RSVP".
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">גילוי נאות</h4>
                    <p>
                        באתר זה עשוי לעשות שימוש בקבצי קוקיז (במיוחד עבור
                        משתמשים רשומים ומנויים) ובממשקי סטטיסטיקה פנימיים בכדי
                        לשמור תיעוד סטטיסטי אנונימי של גולשים וניתוח תנועת
                        הגולש/ים, הרגלי גלישה באתר וניתוח קליקים וזמן שהייה.
                    </p>
                    <p>
                        בכל העת ולבד מאשר גולשים המחוברים לאתר המידע הנשמר הוא
                        אנונימי לחלוטין ואין בו את שם הגולש או כל פרט מזהה אחר.
                    </p>
                </li>
                <li>
                    <h4 className="text-xl py-3">איזור שיפוט</h4>
                    <p>
                        בעת שאתם עושים שימוש באתר ובמקרה בו התגלעה כל מחולקת אתם
                        מסכימים להלן כי האמור לעיל נמצא תחת סמכות השיפוט הבלעדי
                        של החוק הישראלי תוך שימוש במערכת בתי המשפט הישראליים
                        בלבד במחוז תל אביב.
                    </p>
                </li>
            </ol> */}
        </div>
    );
}
