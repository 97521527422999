import { PLANS } from "./types";

export const EVENTS_COLLECTION = "events";
export const CONTACTS_UPLOADERS_COLLECTION = "contacts-uploaders";
export const SBMS_COLLECTION = "SBMs";
export const USERS_COLLECTION = "users";
export const TRACKS_COLLECTION = "tracks-v3";
// export const TRACKS_COLLECTION = "tracks";

export const EXTRA_CONTACT_THRESHOLD = 0;
export const MINIMUM_PRICE = 109;

export const CONTACTS_UPLOADER_PHONE_NUMBER = "17723233697";
export const CUSTOMER_SERVICE_PHONE_NUMBER = "972587501168";

export const PLANS_CONFIG = {
    [PLANS.BASIC]: {
        price: 0.6,
        // price: 199,
        // guests: 150,
        // pricePerExtraGuest: 0.6,
        hebName: "הבסיסית",
        // description: "פשוט אישורי הגעה נוחים",
        features: [
            "סבב אישורי הגעה אחד בוואטסאפ",
            "הוספת לינק למתנה ב-PayBox",
            // "150 רשומות",
            // "₪0.6 לכל רשומה נוספת",
            "הוספת תמונה משלכם להודעה",
            "מענה נוח עם כפתורים",
            "העלאת אנשי קשר דרך הוואטסאפ",
        ],
    },
    [PLANS.PLUS]: {
        price: 0.7,
        // price: 239,
        // guests: 200,
        // pricePerExtraGuest: 0.5,
        hebName: "המתקדמת",
        // description: "כל מה שצריך ליום הגדול",
        features: [
            "2 סבבי אישורי הגעה בוואטסאפ",
            "הודעת תזכורת עם כל הפרטים לפני האירוע",
            // "200 רשומות",
            // "₪0.5 לכל רשומה נוספת",
            "ביטול חינם עד שבוע לפני השליחה",
            "הוספת לינק למתנה ב-PayBox",
            "הוספת תמונה משלכם להודעה",
            "מענה נוח עם כפתורים",
            "העלאת אנשי קשר דרך הוואטסאפ",
        ],
    },
    [PLANS.PREMIUM]: {
        price: 0.8,
        // price: 299,
        // guests: 250,
        // pricePerExtraGuest: 0.4,

        hebName: "המושלמת",
        // description: "ברמות של כלה",
        features: [
            "2 סבבי אישורי הגעה בוואטסאפ",
            "הודעת תזכורת עם כל הפרטים לפני האירוע",
            "הודעת תודה לאחר האירוע",
            // "250 רשומות",
            // "₪0.4 לכל רשומה נוספת",
            "ביטול חינם עד שבוע לפני השליחה",
            "הוספת לינק למתנה ב-PayBox",
            "הוספת תמונה משלכם להודעה",
            "מענה נוח עם כפתורים",
            "העלאת אנשי קשר דרך הוואטסאפ",
        ],
    },
};

export const NIS = "₪";
