const LabeledInput = ({
    label,
    id,
    outerStyle = "w-full flex flex-row items-center gap-1",
    className,
    labelClassName = "",
    type,
    value,
    placeholder,
    onChange,
    accept,
    forwardRef,
    required = false,
    maxLength,
    disabled = false,
    autoComplete = "on",
    lightMode = false,
}: {
    label: string;
    id: string;
    outerStyle?: string;
    className?: string;
    labelClassName?: string;
    type: string;
    value: string;
    placeholder?: string;
    accept?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    forwardRef?: React.RefObject<HTMLInputElement>;
    required?: boolean;
    maxLength?: number;
    disabled?: boolean;
    autoComplete?: string;
    lightMode?: boolean;
}) => {
    return (
        <div className={`rounded-xl ${outerStyle}`}>
            <div className="relative h-11 w-full">
                <input
                    type={type}
                    ref={forwardRef}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    id={id}
                    accept={accept}
                    required={required}
                    maxLength={maxLength}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    className={`peer ${
                        lightMode ? "placeholder:text-slate-200" : ""
                    } h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 placeholder:opacity-0 focus:placeholder:opacity-100 placeholder:font-heebo`}
                />
                <label
                    htmlFor={id}
                    className={`after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight 
                    ${
                        lightMode ? "text-slate-50" : "text-gray-500"
                    } transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2  after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight ${
                        lightMode
                            ? "peer-focus:text-slate-100 peer-focus:after:border-slate-100 after:border-slate-100"
                            : "peer-focus:text-gray-900 peer-focus:after:border-primary after:border-primary"
                    } peer-focus:after:scale-x-100  peer-disabled:peer-placeholder-shown:text-blue-gray-500`}
                >
                    {label}
                </label>
            </div>
            {/* <label
                htmlFor={id}
                className={`${labelClassName} block text-sm font-medium text-gray-900 w-40`}
            >
                {label}
            </label>
            <input
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={`${className} bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block p-2.5 disabled:bg-slate-100 w-full ${
                    type === "radio" ? "ring-0" : ""
                }`}
                id={id}
                accept={accept}
            /> */}
        </div>
    );
};

export default LabeledInput;
