import { he } from "date-fns/locale/he";
import { Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import { registerLocale } from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { useCallback, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import useTrack from "../hooks/useTrack";
import { PLANS } from "../types";
import { addDaysToDate } from "../utils";
import SchedulePicker from "./SchedulePicker";
registerLocale("he", he);

const TOMORROW = new Date(new Date().setDate(new Date().getDate() + 1));

export type Schedules = {
    firstRSVP: Timestamp | null;
    secondRSVP?: Timestamp | null;
    reminder?: Timestamp | null;
    thanks?: Timestamp | null;
};

export default function Scheduler({
    plan,
    weddingDate,
    schedules,
    updateSchedules,
    setPlan,
    onNextStep,
    eventActive = false,
}: {
    plan?: PLANS;
    schedules: Schedules;
    weddingDate: Timestamp | null;
    updateSchedules: (schedules: Schedules) => void;
    setPlan: (plan: PLANS) => void;
    onNextStep?: () => void;
    eventActive?: boolean;
}) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localTimeOffset = new Date().getTimezoneOffset() / 60;
    const jerusalemOffset = moment.tz("Asia/Jerusalem").utcOffset() / 60;
    const hoursToSubtract = localTimeOffset + jerusalemOffset;

    const { logEvent } = useTrack();

    useEffect(() => {
        logEvent({
            data: {
                event: "page_view",
                page: "scheduler",
            },
        });
    }, [logEvent]);

    const onScheduleChange = useCallback(
        async (
            key: "firstRSVP" | "secondRSVP" | "reminder" | "thanks",
            date: Date
        ) => {
            return await updateSchedules({
                ...schedules,
                [key]: Timestamp.fromDate(date),
            });
        },
        [schedules, updateSchedules]
    );

    if (!weddingDate) {
        return <div>נא להגדיר תאריך לאירוע</div>;
    }

    const { firstRSVP, secondRSVP, reminder, thanks } = schedules;

    const inputsConfig = {
        firstRSVP: {
            value: firstRSVP,
            disabled: eventActive,
            minDate: TOMORROW,
            maxDate: addDaysToDate({
                date: weddingDate.toDate(),
                days: -2,
            }),
            onChange: async (date: Date) => {
                const isBeforeSecondRSVP = secondRSVP
                    ? date < secondRSVP.toDate() &&
                      !moment(date).isSame(moment(secondRSVP.toDate()), "day")
                    : true;
                if (!isBeforeSecondRSVP && plan !== PLANS.BASIC) {
                    if (
                        window.confirm(
                            "שינוי זה יגרום גם לשינוי בתאריך שליחת הסבב השני"
                        )
                    ) {
                        await updateSchedules({
                            ...schedules,
                            firstRSVP: Timestamp.fromDate(date),
                            secondRSVP: Timestamp.fromDate(
                                addDaysToDate({ date, days: 1 })
                            ),
                        });
                    }
                } else {
                    onScheduleChange("firstRSVP", date);
                }
            },
            show: true,
            title: "אישורי הגעה - סבב ראשון",
        },
        secondRSVP: {
            value: secondRSVP,
            disabled: eventActive,

            minDate: firstRSVP
                ? addDaysToDate({
                      date: firstRSVP.toDate(),
                      days: 1,
                  })
                : new Date(),
            maxDate: addDaysToDate({
                date: weddingDate.toDate(),
                days: -1,
            }),
            onChange: (date: Date) => onScheduleChange("secondRSVP", date),
            show: plan === PLANS.PLUS || plan === PLANS.PREMIUM,
            title: "אישורי הגעה - סבב שני",
        },
        reminder: {
            value: reminder,
            disabled: eventActive,

            minDate: addDaysToDate({
                date: weddingDate.toDate(),
                days: -2,
            }),
            maxDate: weddingDate.toDate(),
            onChange: (date: Date) => onScheduleChange("reminder", date),
            show: plan === PLANS.PLUS || plan === PLANS.PREMIUM,
            title: "הודעת תזכורת",
        },
        thanks: {
            value: thanks,
            disabled: eventActive,

            minDate: addDaysToDate({
                date: weddingDate.toDate(),
                days: 1,
            }),
            maxDate: addDaysToDate({
                date: weddingDate.toDate(),
                days: 3,
            }),
            onChange: (date: Date) => onScheduleChange("thanks", date),
            show: plan === PLANS.PREMIUM,
            title: "הודעת תודה",
        },
    };

    // if (tz !== "Asia/Jerusalem") {
    //     return <div>ניתן להגדיר זמנים רק אם נמצאים על שעון ישראל</div>;
    // }

    return (
        <div className="flex flex-col gap-3 min-h-[63vh] lg:w-3/5 lg:mx-auto">
            {/* {tz !== "Asia/Jerusalem" ? (
                <div>
                    <span>כל הזמנים מוצגים בשעון ישראל </span>
                </div>
            ) : null} */}
            <div className="pt-6 flex flex-col gap-10">
                {Object.entries(inputsConfig).map(
                    ([
                        key,
                        {
                            value,
                            minDate,
                            maxDate,
                            onChange,
                            show,
                            title,
                            disabled,
                        },
                    ]) => (
                        <SchedulePicker
                            key={key}
                            id={key}
                            dateValue={value}
                            {...{
                                minDate,
                                maxDate,
                                onChange,
                                show,
                                title,
                                weddingDate,
                                setPlan,
                                disabled,
                            }}
                        />
                    )
                )}
            </div>
        </div>
    );
}
