import { Timestamp, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { EVENTS_COLLECTION, USERS_COLLECTION } from "../constants";
import { db } from "../firebase";
import {
    EventData,
    PLANS,
    REMINDER_TEMPLATES,
    RSVP_TEMPLATES,
    THANKS_TEMPLATES,
    UserData,
} from "../types";

export const changePlan = ({
    plan,
    eventId,
}: {
    plan: PLANS;
    eventId: string;
}) => {
    return updateDoc(doc(db, EVENTS_COLLECTION, eventId), {
        plan,
    });
};

export const registerUser = async ({
    eventData,
    uid,
    userData,
}: {
    eventData?: EventData;
    uid: string;
    userData: Partial<UserData>;
}) => {
    const eventId = uuid();
    const eventDataToSave = eventData ?? {
        weddingDate: null,
        createdAt: Timestamp.now(),
        owner: uid,
        plan: PLANS.PLUS,
        active: false,
        brideName: null,
        groomName: null,
        welcomeTime: null,
        ceremonyTime: null,
        functionHallName: null,
        functionHallAddress: null,
        inviteImage: null,
        guests: {},
        rsvp: {
            template: RSVP_TEMPLATES.BASIC_V1,
            schedules: [null, null],
        },
        reminder: {
            template: REMINDER_TEMPLATES.BASIC,
            schedules: [null],
        },
        thanks: {
            template: THANKS_TEMPLATES.BASIC,
            schedules: [null],
        },
    };

    await setDoc(doc(db, USERS_COLLECTION, uid), {
        ...userData,
        createdAt: Timestamp.now(),
        eventId,
    });

    await setDoc(doc(db, EVENTS_COLLECTION, eventId), eventDataToSave);
    return { eventId };
};

export const getUser = async ({ uid }: { uid: string }) => {
    const userDoc = await getDoc(doc(db, USERS_COLLECTION, uid));
    return userDoc;
};
