import React from "react";
// import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";

export default function Link({
    to,
    id,
    className,
    children,
    onClick,
    disabled,
}: {
    to: string;
    id?: string;
    className?: string;
    children: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
}) {
    return disabled ? (
        <button id={id} onClick={onClick} className={className}>
            {children}
        </button>
    ) : (
        <RouterLink id={id} to={to} className={className} onClick={onClick}>
            {children}
        </RouterLink>
    );

    // const navigate = useNavigate();
    // return (
    //     <div
    //         onClick={() => {
    //             onClick && onClick();
    //             navigate(to);
    //         }}
    //         className={`${className} cursor-pointer`}
    //     >
    //         {children}
    //     </div>
    // );
}
