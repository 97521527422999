import {
    ArrowLeftIcon,
    ChartBarIcon,
    ChatBubbleLeftIcon,
    CheckCircleIcon,
    ClockIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useMemo } from "react";
import CountUp from "react-countup";
import { HiUsers } from "react-icons/hi";
import { useNavigate, useParams } from "react-router";
import RegisterAndLinkAccountPopup from "../../components/AuthPopup";
import Link from "../../components/Link";
import Loader from "../../components/Loader";
import PlanUpgrade from "../../components/PlanUpgrade";
import { PLANS_CONFIG } from "../../constants";
import EventContextProvider from "../../context/event";
import { changePlan } from "../../db/utils";
import useEventData from "../../hooks/useEventData";
import useTrack from "../../hooks/useTrack";
import useUser from "../../hooks/useUser";
import { PLANS } from "../../types";
import { getStatsFromGuests } from "../../utils";

enum TABS {
    MESSAGES = "messages",
    SCHEDULES = "schedules",
    GUESTS = "guests",
}

export default function Event() {
    const { eventId } = useParams();
    const { eventData, loading, error } = useEventData({ eventId });
    const { user, loading: userLoading, error: userError } = useUser();

    const { logEvent } = useTrack();

    useEffect(() => {
        logEvent({
            data: {
                event: "page_view",
                page: "event",
                eventId: eventId,
            },
        });
    }, [logEvent, eventId]);

    const daysTillNextSchedule = useMemo(() => {
        if (!eventData) return 0;
        const { rsvp, reminder, thanks, plan, paidPlan } = eventData;
        const planToCheck = paidPlan ? paidPlan : plan;
        const nextSchedule = [
            rsvp.schedules[0],
            ...(planToCheck !== PLANS.BASIC ? [rsvp.schedules[1]] : []),
            ...(planToCheck !== PLANS.BASIC ? [reminder.schedules[0]] : []),
            ...(planToCheck === PLANS.PREMIUM ? [thanks.schedules[0]] : []),
        ]
            .filter((date) => date && date.toDate() >= new Date())
            .sort((a, b) => (a && b ? a.toMillis() - b.toMillis() : 0))
            .find((schedule) => schedule && schedule.toDate() > new Date());

        return nextSchedule
            ? Math.round(
                  (nextSchedule.toDate().getTime() - new Date().getTime()) /
                      (1000 * 60 * 60 * 24)
              )
            : undefined;
    }, [eventData]);

    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            console.error(error);
            navigate("/login");
        }
    }, [error, navigate]);

    if (loading) return <Loader fullScreen />;

    if (error) {
        console.error(error);
        return null;
    }

    if (!eventData || !eventId)
        return (
            <div className="w-full h-full grid place-content-center">
                האירוע לא נמצא
            </div>
        );

    const {
        plan,
        paidPlan,
        active,
        brideName,
        groomName,
        guests: guestsObject,
    } = eventData;

    const isEventActive = active;
    const hasChanges = plan !== paidPlan;
    const hasNoGuests = Object.keys(guestsObject).length === 0;

    const {
        numOfGuests,
        numOfContacts,
        numOfArriving,
        numOfMaybe,
        numOfNotArriving,
    } = getStatsFromGuests({
        guestsObject,
    });

    return (
        <EventContextProvider eventId={eventId}>
            <div className="bg-slate-50 flex flex-col justify-between pt-14 px-3 sm:px-5 min-h-screen">
                <div>
                    <h1 className="text-slate-700 text-2xl flex flex-col mt-5 lg:max-w-[352px] mx-auto">
                        האירוע של
                        <span className="font-semibold">
                            {brideName ? (
                                brideName.trim()
                            ) : (
                                <span className="text-slate-300 font-medium">
                                    שם הכלה חסר
                                </span>
                            )}{" "}
                            ו
                            {groomName ? (
                                groomName.trim()
                            ) : (
                                <span className="text-slate-300 font-medium">
                                    שם החתן חסר
                                </span>
                            )}
                        </span>
                    </h1>
                    <div className="flex gap-4 justify-center mt-10">
                        <div className="flex flex-col gap-4">
                            <div className="bg-gradient-primary text-white w-[9rem] xs:w-[10rem] sm:w-[10.5rem] h-[14.5rem] sm:h-60 shadow-md rounded-2xl p-3 flex flex-col justify-between">
                                <ChartBarIcon className="w-6 h-6" />
                                {user?.isAnonymous ? (
                                    <RegisterAndLinkAccountPopup
                                        buttonClassName="rounded-full border-white text-white border-2 font-medium p-1 w-28 items-center"
                                        buttonText="התחברות"
                                    />
                                ) : null}
                                {/* {isEventActive ? null : (
                                    <Link
                                        to={`/payment/${eventId}`}
                                        className="px-2 py-0.5 flex justify-between border-2 border-salte-50 text-slate-50 text-center rounded-full p-1 w-28 items-center"
                                    >
                                        להפעלה
                                        <ArrowLeftIcon className="w-5 h-5" />
                                    </Link>
                                )} */}
                                <Link
                                    to={`/event/${eventId}/guests`}
                                    className="flex flex-col gap-2"
                                >
                                    <div className="grid grid-cols-2 gap-y-0.5 justify-end">
                                        <div className="flex flex-col mb-2">
                                            <span className="text-xl font-medium -mb-1">
                                                <CountUp
                                                    className="font-poppins"
                                                    end={numOfGuests}
                                                    delay={0.2}
                                                />
                                            </span>
                                            <span className="text-base text-slate-50/90">
                                                מוזמנים
                                            </span>
                                        </div>
                                        <div className="flex flex-col mb-2">
                                            <span className="text-xl font-medium -mb-1">
                                                <CountUp
                                                    className="font-poppins"
                                                    end={numOfArriving}
                                                    delay={0.2}
                                                />
                                            </span>
                                            <span className="text-base text-slate-50/90">
                                                מגיעים
                                            </span>
                                        </div>
                                        <div className="flex flex-col justify-end">
                                            <span className="text-lg font-medium">
                                                <CountUp
                                                    className="font-poppins"
                                                    end={numOfNotArriving}
                                                    delay={0.2}
                                                />
                                            </span>
                                            <span className="text-sm -mt-1 text-slate-50/90">
                                                לא מגיעים
                                            </span>
                                        </div>
                                        <div className="flex flex-col justify-end">
                                            <span className="text-lg font-medium">
                                                <CountUp
                                                    className="font-poppins"
                                                    end={numOfMaybe}
                                                    delay={0.2}
                                                />
                                            </span>
                                            <span className="text-sm -mt-1  text-slate-50/90">
                                                אולי
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <Card
                                to={`/event/${eventId}/scheduler`}
                                title={"תזמונים"}
                                subtitle={[
                                    daysTillNextSchedule === undefined
                                        ? "אין תזמונים קרובים"
                                        : daysTillNextSchedule === 0
                                        ? "שליחה היום"
                                        : `${daysTillNextSchedule} ימים לשליחה`,
                                ]}
                                logo={<ClockIcon className="w-6 h-6" />}
                            />
                        </div>
                        <div className="flex flex-col gap-4">
                            <Card
                                to={`/event/${eventId}/messages`}
                                title={"הודעות"}
                                subtitle={[
                                    `${
                                        plan === PLANS.BASIC
                                            ? "1"
                                            : plan === PLANS.PLUS
                                            ? "3"
                                            : "4"
                                    } מתוזמנות`,
                                ]}
                                logo={
                                    <ChatBubbleLeftIcon className="w-6 h-6" />
                                }
                            />
                            <Card
                                to={`/event/${eventId}/guests`}
                                title={"מוזמנים"}
                                subtitle={[`${numOfContacts} רשומות`]}
                                className=""
                                light
                                logo={<HiUsers className="w-6 h-6" />}
                            />
                            {isEventActive ? (
                                <div className="rounded-2xl flex items-center gap-2 shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-gradient-white px-3 py-0.5 w-full h-[3.25rem] text-slate-700 font-medium text-lg">
                                    {hasChanges ? (
                                        <Link
                                            className="flex gap-2 items-center"
                                            to={`/contact/?eid=${eventId}`}
                                        >
                                            <ExclamationCircleIcon className="w-6 h-6 text-secondary" />
                                            בוצעו שינויים
                                        </Link>
                                    ) : (
                                        <>
                                            <CheckCircleIcon className="w-6 h-6 text-secondary" />
                                            האירוע פעיל{" "}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className="rounded-2xl flex items-center shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-gradient-white px-3 py-0.5 w-full h-[3.5rem] text-slate-700 font-medium text-lg">
                                    <PlanUpgrade
                                        setPlan={(plan: PLANS) => {
                                            changePlan({
                                                eventId,
                                                plan,
                                            });
                                        }}
                                        button={
                                            <div className="flex items-center justify-between gap-2 w-full">
                                                <div className="flex flex-col text-start text-lg font-semibold">
                                                    חבילות
                                                    <span className="text-sm -mt-1.5 text-gray-600 font-normal">
                                                        {
                                                            PLANS_CONFIG[plan]
                                                                .hebName
                                                        }
                                                    </span>
                                                </div>
                                                <ArrowLeftIcon className="w-5 h-5" />
                                            </div>
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mb-4 !max-w-[352px] w-full mx-auto mt-6 sm:mt-4">
                    {isEventActive ? (
                        hasChanges ? (
                            <Link
                                to={`/contact/?eid=${eventId}`}
                                className="btn-primary y"
                            >
                                להפעלת השינויים
                            </Link>
                        ) : null
                    ) : !hasNoGuests && user?.isAnonymous ? (
                        <RegisterAndLinkAccountPopup />
                    ) : (
                        <Link
                            to={
                                hasNoGuests
                                    ? `/event/${eventId}/guests`
                                    : `/payment/${eventId}`
                            }
                            className="btn-primary"
                        >
                            {hasNoGuests ? (
                                <span className="animate-pulse">
                                    הוספת מוזמנים
                                </span>
                            ) : (
                                "להפעלה ושריון התאריכים"
                            )}
                        </Link>
                    )}
                    <div className="text-center mt-2">
                        חסר לכם משהו?{" "}
                        <a
                            className="underline"
                            href="https://wa.me/972587501168"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            דברו איתנו
                        </a>{" "}
                        ונוסיף אותו
                    </div>
                </div>
            </div>
        </EventContextProvider>
    );
}

const Card = ({
    title,
    subtitle,
    to,
    small = false,
    className = "",
    textClassName = "",
    light = false,
    logo,
}: {
    title: string;
    subtitle?: string[];
    to: string;
    small?: boolean;
    className?: string;
    textClassName?: string;
    logo: React.ReactNode;
    light?: boolean;
}) => {
    return (
        <Link
            to={to}
            className={`${className} flex flex-col ${
                light
                    ? "text-white bg-gradient-to-tr to-[#ff73b4] from-[#F7CCBB]"
                    : "text-[#fb508a] bg-gradient-white"
            } w-[9rem] xs:w-[10rem] sm:w-[10.5rem] ${
                small
                    ? "h-[3.25rem] items-center justify-center"
                    : "h-[10rem] sm:h-[10.5rem] items-start justify-between"
            } rounded-2xl p-3 text-xl font-semibold  shadow-[0_8px_30px_rgb(0,0,0,0.12)] transition-all duration-300 ease-in-out cursor-pointer`}
        >
            {small ? null : logo}
            <div
                className={`${textClassName} flex justify-between items-end w-full ${
                    light ? "text-white" : "text-slate-700"
                }`}
            >
                <div>
                    {title}
                    {subtitle ? (
                        <div
                            className={`flex ${
                                light ? "text-slate-50/90" : "text-slate-600"
                            } font-normal`}
                        >
                            {subtitle.map((s, i, self) => (
                                <div key={s} className="text-sm -mt-1">
                                    {s}
                                    {i !== self.length - 1 ? (
                                        <span>&bull;</span>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
                <ArrowLeftIcon className="w-5 h-5" />
            </div>
        </Link>
    );
};
