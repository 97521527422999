import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function Layout({ children }: { children: React.ReactNode }) {
    return (
        <div className="bg-slate-50 font-heebo w-screen">
            <Navbar />
            <main
                dir="rtl"
                // style={{
                //     background:
                //         "conic-gradient(from 119deg at -19% 36%, transparent 50%, #9ADE7B 55%, transparent 92%)",
                // }}
                className="h-full  min-h-[90vh] w-full"
                // className="h-full pt-20 font-heebo min-h-[90vh] bg-white px-4 lg:px-60"
            >
                {children}
            </main>
            <Footer />
        </div>
    );
}
