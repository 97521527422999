import { isBrowser } from "react-device-detect";
import { CUSTOMER_SERVICE_PHONE_NUMBER } from "../constants";

export default function Contact() {
    const customerServiceWhatsAppLink = `${
        isBrowser ? "https://wa.me/" : "whatsapp://"
    }send?phone=${CUSTOMER_SERVICE_PHONE_NUMBER}`;

    return (
        <div className="px-5 flex flex-col gap-5 items-center justify-center h-screen">
            <div className="mt-10 px-5 flex flex-col gap-2">
                <h1 className="text-2xl font-semibold text-center">
                    יצירת קשר
                </h1>
                <p>צריכים לעשות שינויים? יש לכם שאלה?</p>
            </div>
            <a
                href={customerServiceWhatsAppLink}
                target="_blank"
                rel="noreferrer"
                className="btn-primary"
            >
                דברו איתנו בוואטסאפ
            </a>
        </div>
    );
}
