import { Dialog, Transition } from "@headlessui/react";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAsync } from "react-use";
import RegisterAndLinkAccountPopup from "../components/AuthPopup";
import CustomDisclosure from "../components/CustomDisclosure";
import Link from "../components/Link";
import Loader from "../components/Loader";
import { NIS, PLANS_CONFIG } from "../constants";
import { getBillDetails, makePayment } from "../firebase";
import useEventData from "../hooks/useEventData";
import useTrack from "../hooks/useTrack";
import useUser from "../hooks/useUser";
import { PLANS } from "../types";
import { getStatsFromGuests, valueToPrice } from "../utils";

export default function Payment() {
    const { eventId } = useParams();
    const { eventData, loading, error } = useEventData({ eventId });
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const navigate = useNavigate();
    const { user } = useUser();

    const { logEvent } = useTrack();

    useEffect(() => {
        logEvent({
            data: {
                event: "page_view",
                page: "payment",
            },
        });
    }, [logEvent]);

    const {
        value: billDetails,
        loading: billDetailsLoading,
        error: billDetailsError,
    } = useAsync(async () => {
        try {
            const res = await getBillDetails({ eventId });
            return res.data as {
                price: number;
                numberOfIncludedContacts: number;
                numberOfExtraContacts: number;
                numOfContactsPaidFor: number;
                isMinimumPrice: boolean;
            };
        } catch (e) {
            console.error(e);
        }
    }, [eventId, eventData?.plan]);

    if (loading || billDetailsLoading) return <Loader fullScreen />;

    if (!eventData || !eventId || billDetailsError || error) {
        console.error(billDetailsError, error);
        return (
            <div className="pt-20 text-center">
                קרתה שגיאה, יש לנסות שנית מאוחר יותר
            </div>
        );
    }

    if (!billDetails) {
        console.error("getBillDetails is not defined");
        return (
            <div className="pt-20 text-center">
                קרתה תקלה בחישוב המחיר, יש לנסות שנית מאוחר יותר
            </div>
        );
    }

    const {
        plan,
        rsvp,
        guests,
        brideName,
        groomName,
        weddingDate,
        reminder,
        thanks,
    } = eventData;

    const filledRequiredDetails = brideName && groomName && weddingDate;
    const areSchedulesValid =
        plan === PLANS.BASIC
            ? rsvp.schedules[0] && rsvp.schedules[0].toDate() > new Date()
            : plan === PLANS.PLUS
            ? rsvp.schedules.every(
                  (date) => date && date.toDate() > new Date()
              ) &&
              reminder.schedules.every(
                  (date) => date && date?.toDate() > new Date()
              )
            : rsvp.schedules.every(
                  (date) => date && date?.toDate() > new Date()
              ) &&
              reminder.schedules.every(
                  (date) => date && date?.toDate() > new Date()
              ) &&
              thanks.schedules.every(
                  (date) => date && date?.toDate() > new Date()
              );

    const { numOfContacts } = getStatsFromGuests({
        guestsObject: guests,
    });

    const onSuccefullPayment = async () => {
        setPaymentLoading(true);
        try {
            logEvent({
                data: {
                    event: "payment started",
                    eventId,
                },
            });
            const isTooEarly =
                rsvp.schedules[0] &&
                rsvp.schedules[0].toDate() <= new Date("2024-05-16");
            if (isTooEarly) {
                alert("מועדי השליחה קרובים מידי, אנא צרו איתנו קשר");
                setPaymentLoading(false);
                logEvent({
                    data: {
                        event: "payment too early",
                        firstRSVP: rsvp.schedules[0]
                            ? rsvp.schedules[0].toDate().toISOString()
                            : null,
                        eventId,
                    },
                });
                return;
            }
            await makePayment({ eventId });
            setShowSuccessPopup(true);
            // alert("התשלום בוצע בהצלחה, האירוע פעיל!");
            // navigate(`/event/${eventId}`);
        } catch (e) {
            console.error(e);
            alert("משהו השתבש, נסה שוב מאוחר יותר");
        }

        setPaymentLoading(false);
    };

    return (
        <>
            <SuccessPaymentPopup
                show={showSuccessPopup}
                onConfirm={() => navigate(`/event/${eventId}`)}
            />
            <div className="flex flex-col justify-between gap-4 pt-20 pb-5 px-5 min-h-[93vh] text-slate-900 bg-slate-50">
                <div className="flex flex-col gap-6">
                    <div className="relative flex items-center justify-center">
                        <button
                            className="absolute right-0 top-0 h-full flex items-center justify-center"
                            onClick={() => window.history.back()}
                        >
                            <ChevronRightIcon className="h-6 w-6 inline-block" />
                        </button>
                        <h1 className="text-2xl font-semibold text-center">
                            תשלום והפעלת השירות
                        </h1>
                    </div>
                    {user?.isAnonymous ? (
                        <div className="flex flex-col gap-3 font-medium text-red-500 text-lg">
                            נראה שעוד לא יצרתם חשבון אצלנו, יש להתחבר לפני
                            שתוכלו להמשיך
                            <RegisterAndLinkAccountPopup />
                        </div>
                    ) : null}
                    <div className="w-full mt-10 mx-auto max-w-[500px]">
                        <CustomDisclosure
                            button={
                                <div className="px-2 w-full">
                                    <CheckoutItem
                                        title={`חבילה - ${PLANS_CONFIG[plan].hebName}`}
                                        value={`${NIS}${PLANS_CONFIG[plan].price} לרשומה`}
                                    />
                                </div>
                            }
                            children={
                                <ul className="flex flex-col my-2.5 h-64 gap-1 px-2 text-base text-start list-disc list-inside">
                                    {PLANS_CONFIG[plan].features.map(
                                        (feature) => (
                                            <li
                                                key={feature}
                                                className="flex gap-2 items-center"
                                            >
                                                <span className="w-[5px] h-[5px] rounded-full bg-slate-900" />

                                                {feature}
                                            </li>
                                        )
                                    )}
                                </ul>
                            }
                        />
                        <div className="pr-2 pl-8 flex flex-col gap-3 mt-3">
                            {/* <CheckoutItem
                                title={`רשומות נוספות - ${billDetails.numberOfExtraContacts}`}
                                value={
                                    billDetails.numberOfExtraContacts
                                        ? valueToPrice({
                                              value:
                                                  billDetails.numberOfExtraContacts *
                                                  PLANS_CONFIG[plan]
                                                      .pricePerExtraGuest,
                                          })
                                        : undefined
                                }
                            /> */}
                            <CheckoutItem
                                title='סה"כ רשומות'
                                value={numOfContacts.toString()}
                                // value={
                                //     billDetails &&
                                //     numOfContacts !==
                                //         billDetails.numOfContactsPaidFor
                                //         ? `${numOfContacts} (${billDetails.numOfContactsPaidFor} כלול)`
                                //         : numOfContacts.toString()
                                // }
                            />
                            <CheckoutItem
                                title="שליחה ראשונה"
                                value={rsvp.schedules[0]
                                    ?.toDate()
                                    .toLocaleDateString("he-IL")}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-4 items-center">
                    <Notice show={!filledRequiredDetails} className="w-full">
                        <div className="flex flex-col gap-2">
                            יש למלא את כל הפרטים: שם כלה, שם חתן, תאריך חתונה
                            <Link
                                to={`/event/${eventId}/messages`}
                                className="underline"
                            >
                                למילוי הפרטים
                                <ChevronLeftIcon className="h-4 w-4 inline-block" />
                            </Link>
                        </div>
                    </Notice>
                    <Notice show={!areSchedulesValid} className="w-full">
                        <div className="flex flex-col gap-2">
                            חלק מזמני השליחה אינם תקינים
                            <Link
                                to={`/event/${eventId}/scheduler`}
                                className="underline"
                            >
                                לשינוי זמני השליחה
                                <ChevronLeftIcon className="h-4 w-4 inline-block" />
                            </Link>
                        </div>
                    </Notice>
                    <div className="text-center flex flex-col gap-2 items-center justify-center">
                        <div className="text-xl font-semibold text-center flex gap-2 items-center justify-center">
                            לתשלום:
                            <span>
                                {valueToPrice({ value: billDetails?.price })}
                            </span>
                        </div>
                        {billDetails.isMinimumPrice ? (
                            <span className="text-sm text-slate-600">
                                מחיר מינימום. {billDetails.numOfContactsPaidFor}{" "}
                                רשומות כלולות במחיר
                            </span>
                        ) : null}
                    </div>
                    <button
                        disabled={
                            !filledRequiredDetails ||
                            !areSchedulesValid ||
                            user?.isAnonymous
                        }
                        className="btn-primary"
                        onClick={onSuccefullPayment}
                    >
                        {paymentLoading ? <Loader small /> : "לתשלום מאובטח"}
                    </button>
                </div>
            </div>
        </>
    );
}

const CheckoutItem = ({
    title,
    value,
}: {
    title: string;
    value?: string | null;
}) =>
    value ? (
        <div className="flex w-full justify-between text-lg">
            <span className="font-semibold">{title}</span>
            <span>{value}</span>
        </div>
    ) : null;

export const Notice = ({
    show,
    children,
    className = "",
}: {
    show: boolean;
    children: ReactNode;
    className?: string;
}) => {
    return show ? (
        <div
            className={`${className} border-red-500 p-3 lg:px-5 border rounded-xl flex flex-row gap-2 items-center max-w-[400px]`}
        >
            <ExclamationCircleIcon className="h-6 w-6 flex-shrink-0 text-red-500" />
            {children}
        </div>
    ) : null;
};

const SuccessPaymentPopup = ({
    show,
    onConfirm,
}: {
    show: boolean;
    onConfirm: () => void;
}) => {
    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog
                dir="rtl"
                as="div"
                className="font-heebo relative z-50"
                onClose={() => {}}
                // onClose={onClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-start align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-2xl text-center font-medium leading-6 text-gray-900"
                                >
                                    איזה כיף - האירוע פעיל! 🎉
                                </Dialog.Title>
                                <div className="mt-6 flex flex-col gap-4 text-lg text-slate-700">
                                    <p className="font-medium ">
                                        נציג מטעמנו ייצור איתכם קשר בימים
                                        הקרובים ללקיחת פרטי התשלום.
                                    </p>
                                    <p className="">
                                        שליחת ההודעות תוזמנה ומכאן אנחנו ניקח את
                                        העניינים
                                    </p>
                                </div>

                                <div className="text-base mt-4 flex gap-3 justify-end">
                                    <button
                                        type="button"
                                        className="btn-primary"
                                        onClick={onConfirm}
                                    >
                                        חזרה לעמוד האירוע
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
