import { WeddingValues } from "../types";
import { CoupleNames } from "./rsvp";

export const BasicThanks = ({
    groomName,
    brideName,
    weddingDate,
    functionHallName,
    functionHallAddress,
}: Partial<WeddingValues>) => {
    return (
        <div className="p-1">
            אורחים יקרים!
            <br />
            רצינו להודות לכם שהגעתם ושמחתם איתנו.
            <br />
            <br />
            אוהבים,
            <br />
            <CoupleNames
                {...{
                    brideName,
                    groomName,
                }}
                withEndEmoji={false}
            />
            ❤️
        </div>
    );
};
export const ExcitingThanks = ({
    groomName,
    brideName,
    weddingDate,
    functionHallName,
    functionHallAddress,
}: Partial<WeddingValues>) => {
    return (
        <div className="p-1">
            אורחים יקרים!
            <br />
            רק רצינו להגיד תודה שבאתם לחגוג איתנו באירוע בלתי נשכח, מקווים
            שנהניתם כמונו 😄
            <br />
            <br />
            אוהבים,
            <br />
            <CoupleNames
                {...{
                    brideName,
                    groomName,
                }}
                withEndEmoji={false}
            />
            ❤️
        </div>
    );
};
