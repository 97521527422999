import { Dialog, Tab, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { Fragment, useEffect, useState } from "react";
import { PLANS_CONFIG } from "../constants";
import useEvent from "../hooks/useEvent";
import useTrack from "../hooks/useTrack";
import { PLANS } from "../types";
import Loader from "./Loader";

export default function PlanUpgrade({
    setPlan,
    button,
}: {
    setPlan: (plan: PLANS) => void;
    button?: React.ReactNode;
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<PLANS>();
    const { event, loading } = useEvent();

    const { logEvent } = useTrack();

    useEffect(() => {
        const currentPlan =
            event?.plan ?? localStorage.getItem("plan") ?? PLANS.PLUS;
        // if (event) {
        setSelectedPlan(currentPlan as PLANS);
        // }
    }, [event]);

    if (loading) return <Loader small />;

    const closeModal = () => {
        setIsOpen(false);
    };
    return (
        <>
            <button onClick={() => setIsOpen(true)} className="w-full">
                {button ?? (
                    <div className="w-full h-full btn-primary">בחירת חבילה</div>
                )}
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    dir="rtl"
                    as="div"
                    className="relative z-50 font-heebo"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full relative max-w-md transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all">
                                    <button
                                        className="absolute left-4 top-4"
                                        onClick={closeModal}
                                    >
                                        <XMarkIcon className="w-5 h-5" />
                                    </button>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl font-semibold leading-6 text-gray-900"
                                    >
                                        בחירת חבילה
                                    </Dialog.Title>
                                    <div className="mt-5">
                                        <Tab.Group
                                            defaultIndex={Object.keys(
                                                PLANS
                                            ).indexOf(selectedPlan as PLANS)}
                                        >
                                            <div className="flex flex-col justify-between w-full">
                                                <Tab.List className="flex justify-between">
                                                    {Object.keys(PLANS).map(
                                                        (plan) => {
                                                            const isCurrentPlan =
                                                                event?.plan ===
                                                                plan;
                                                            return (
                                                                <Tab
                                                                    key={plan}
                                                                    onClick={async () => {
                                                                        setSelectedPlan(
                                                                            plan as PLANS
                                                                        );
                                                                    }}
                                                                    className={`${
                                                                        isCurrentPlan
                                                                            ? "ring-2 ring-primary ring-offset-0"
                                                                            : ""
                                                                    } ui-selected:bg-primary ui-selected:text-white bg-transparent text-primary rounded-full border-primary border px-4 py-0.5`}
                                                                >
                                                                    {
                                                                        PLANS_CONFIG[
                                                                            plan as PLANS
                                                                        ]
                                                                            .hebName
                                                                    }
                                                                </Tab>
                                                            );
                                                        }
                                                    )}
                                                </Tab.List>

                                                <Tab.Panels className="mt-5">
                                                    {Object.values(
                                                        PLANS_CONFIG
                                                    ).map((plan) => (
                                                        <Tab.Panel
                                                            key={plan.hebName}
                                                        >
                                                            <PlanCard
                                                                title={
                                                                    plan.hebName
                                                                }
                                                                price={
                                                                    plan.price
                                                                }
                                                                // description={
                                                                //     plan.description
                                                                // }
                                                                features={
                                                                    plan.features
                                                                }
                                                            />
                                                        </Tab.Panel>
                                                    ))}
                                                </Tab.Panels>
                                            </div>
                                        </Tab.Group>

                                        {/* <div className="w-full flex flex-col gap-3">
                                            <div>
                                                plan:
                                                <select
                                                    value={selectedPlan}
                                                    onChange={async (e) => {
                                                        setPlan(
                                                            e.target
                                                                .value as PLANS
                                                        );
                                                        closeModal();
                                                    }}
                                                >
                                                    {Object.values(PLANS).map(
                                                        (plan) => (
                                                            <option
                                                                key={plan}
                                                                value={plan}
                                                            >
                                                                {plan}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="flex flex-col gap-1.5">
                                        <button
                                            className="btn-primary"
                                            onClick={() => {
                                                setPlan(selectedPlan as PLANS);
                                                logEvent({
                                                    data: {
                                                        event: "page_view",
                                                        page: "select_plan",
                                                        plan:
                                                            selectedPlan ??
                                                            "none",
                                                    },
                                                });
                                                closeModal();
                                            }}
                                        >
                                            בחירה
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

export const PlanCard = ({
    title,
    price,
    // description,
    features,
}: {
    title: string;
    price: number;
    // description: string;
    features: string[];
}) => {
    return (
        <div
            className={`flex flex-col gap-4 flex-1 h-full w-full items-start justify-start p-6 text-center rounded-2xl`}
        >
            <div className="flex justify-between text-start w-full">
                <div className="flex flex-col gap-0">
                    <h5 className="text-xl font-semibold">{title}</h5>
                    {/* <p className="text-base">{description}</p> */}
                </div>
                <div className="flex flex-col">
                    <p className="text-xl font-semibold font-manrope">
                        ₪{price}
                    </p>
                    <p className="text-sm">לרשומה</p>
                </div>
            </div>
            <ul className="flex flex-col min-h-64 gap-1 text-base text-start list-disc list-inside">
                {features.map((feature) => (
                    <li
                        key={feature + title}
                        className="flex gap-2 items-center"
                    >
                        <span className="w-[5px] h-[5px] rounded-full bg-slate-900" />

                        {feature}
                    </li>
                ))}
                <li className="flex gap-2 items-center">
                    <span className="w-[5px] h-[5px] rounded-full bg-slate-900" />

                    <span className="text-slate-600 text-sm">
                        מחיר מינימום של 109 ₪
                    </span>
                </li>
            </ul>
        </div>
    );
};
