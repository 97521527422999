import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { EVENTS_COLLECTION } from "../../constants";
import { db } from "../../firebase";
import useAlert from "../../hooks/useAlert";
import useEvent from "../../hooks/useEvent";
import { GUESTS_RELATIONSHIPS } from "../../types";
import {
    convertPhoneToWhatsApp,
    isValidIsraeliPhoneNumber,
    isWhatsAppId,
} from "../../utils";
import LabeledInput from "../LabeledInput";
import Loader from "../Loader";
import PhoneInput from "../PhoneInput";

export default function AddGuestForm() {
    const { event } = useEvent();
    const [values, setValues] = useState<{
        name: string;
        phone: string;
        expectedAmount: number;
        relationship: GUESTS_RELATIONSHIPS | null;
    }>({
        name: "",
        phone: "",
        expectedAmount: 1,
        relationship: null,
    });
    const [loading, setLoading] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const { isAlerted } = useAlert();

    useEffect(() => {
        if (showSuccessMsg) {
            const timeout = setTimeout(() => setShowSuccessMsg(false), 2800);
            return () => clearTimeout(timeout);
        }
    }, [showSuccessMsg]);

    const onAddGuest = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMsg(undefined);
        if (!event) return;
        const { guests, eventId } = event;
        if (!guests || !eventId) return;

        const { name, phone, expectedAmount, relationship } = values;

        if (!isValidIsraeliPhoneNumber(phone)) {
            // await isAlerted("מספר טלפון לא תקין");
            setErrorMsg("מספר טלפון לא תקין");
            // setLoading(false);
            return;
        }

        const guest = {
            name: name.trim(),
            sent: false,
            validPhoneNumber: false,
            arriving: null,
            amount: null,
            expectedAmount: expectedAmount < 1 ? 1 : expectedAmount,
            sendRSVP: true,
            relationship: relationship ?? null,
            tableNumber: null,
        };

        const wa_id = convertPhoneToWhatsApp(phone);
        const isValidPhone = isWhatsAppId(wa_id);
        guest.validPhoneNumber = isValidPhone;

        const alreadyExists = Boolean(guests[wa_id]);
        if (alreadyExists) {
            // await isAlerted("איש קשר עם מספר טלפון זה כבר קיים");
            setErrorMsg("איש קשר עם מספר טלפון זה כבר קיים");
            // alert("איש קשר עם מספר טלפון זה כבר קיים");
            // setLoading(false);
            return;
        }
        setLoading(true);

        await updateDoc(doc(db, EVENTS_COLLECTION, eventId), {
            [`guests.${wa_id}`]: guest,
        });

        setValues({
            name: "",
            phone: "",
            expectedAmount: 1,
            relationship: null,
        });
        setShowSuccessMsg(true);
        setLoading(false);
    };

    const canSubmit = useMemo(
        () =>
            values.name?.length > 0 &&
            values.phone?.length > 0 &&
            values.expectedAmount >= 1,
        [values]
    );

    return (
        <div>
            <h5 className="text-xl font-medium">הוספת איש קשר</h5>
            <form className="flex flex-col gap-6 mt-4" onSubmit={onAddGuest}>
                {errorMsg ? (
                    <span className="text-red-500">{errorMsg}</span>
                ) : showSuccessMsg ? (
                    <span className="text-green-500">איש קשר נוסף בהצלחה!</span>
                ) : null}
                <LabeledInput
                    label="שם"
                    value={values.name}
                    type="text"
                    placeholder="שם"
                    id="name"
                    onChange={(e) =>
                        setValues({ ...values, name: e.target.value })
                    }
                />
                <PhoneInput
                    phone={values.phone}
                    setPhone={(newPhone) =>
                        setValues({ ...values, phone: newPhone })
                    }
                />
                <LabeledInput
                    label="מוזמנים"
                    type="number"
                    value={values.expectedAmount.toString()}
                    placeholder="מוזמנים"
                    id="expectedAmount"
                    onChange={(e) => {
                        if (isNaN(Number(e.target.value))) return;
                        setValues({
                            ...values,
                            expectedAmount: Number(e.target.value),
                        });
                    }}
                />
                <div className="flex flex-col gap-1">
                    <label
                        htmlFor={`relationship`}
                        className="text-sm text-gray-500 w-10"
                    >
                        קרבה
                    </label>
                    <select
                        value={values.relationship ?? undefined}
                        id={`relationship`}
                        className="bg-transparent underline max-w-32 focus:outline-none text-slate-700"
                        onChange={(e) =>
                            setValues({
                                ...values,
                                relationship: e.target
                                    .value as GUESTS_RELATIONSHIPS,
                            })
                        }
                    >
                        <option value={undefined} className="">
                            לא נבחר
                        </option>
                        <option value="family">משפחה</option>
                        <option value="friend">חברים</option>
                        <option value="work">עבודה</option>
                        <option value="other">אחר</option>
                    </select>
                </div>

                <button
                    className="mt-5 btn-primary"
                    disabled={!canSubmit || loading || showSuccessMsg}
                    type="submit"
                >
                    {loading ? <Loader small /> : "הוספה"}
                </button>
            </form>
        </div>
    );
}
