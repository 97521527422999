import { Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import { NIS } from "./constants";
import { Guest } from "./types";

export const countryPhoneCodes: {
    [key: string]: string;
} = {
    AF: "93",
    AX: "358",
    AL: "355",
    DZ: "213",
    AS: "1-684",
    AD: "376",
    AO: "244",
    AI: "1-264",
    AQ: "672",
    AG: "1-268",
    AR: "54",
    AM: "374",
    AW: "297",
    AU: "61",
    AT: "43",
    AZ: "994",
    BS: "1-242",
    BH: "973",
    BD: "880",
    BB: "1-246",
    BY: "375",
    BE: "32",
    BZ: "501",
    BJ: "229",
    BM: "1-441",
    BT: "975",
    BO: "591",
    BQ: "599",
    BA: "387",
    BW: "267",
    BV: "47",
    BR: "55",
    IO: "246",
    BN: "673",
    BG: "359",
    BF: "226",
    BI: "257",
    KH: "855",
    CM: "237",
    CA: "1",
    CV: "238",
    KY: "1-345",
    CF: "236",
    TD: "235",
    CL: "56",
    CN: "86",
    CX: "61",
    CC: "61",
    CO: "57",
    KM: "269",
    CD: "243",
    CG: "242",
    CK: "682",
    CR: "506",
    HR: "385",
    CU: "53",
    CW: "599",
    CY: "357",
    CZ: "420",
    DK: "45",
    DJ: "253",
    DM: "1-767",
    DO: "1",
    EC: "593",
    EG: "20",
    SV: "503",
    GQ: "240",
    ER: "291",
    EE: "372",
    ET: "251",
    FK: "500",
    FO: "298",
    FJ: "679",
    FI: "358",
    FR: "33",
    GF: "594",
    PF: "689",
    TF: "262",
    GA: "241",
    GM: "220",
    GE: "995",
    DE: "49",
    GH: "233",
    GI: "350",
    GR: "30",
    GL: "299",
    GD: "1-473",
    GP: "590",
    GU: "1-671",
    GT: "502",
    GG: "44-1481",
    GN: "224",
    GW: "245",
    GY: "592",
    HT: "509",
    HM: "672",
    HN: "504",
    HK: "852",
    HU: "36",
    IS: "354",
    IN: "91",
    ID: "62",
    IR: "98",
    IQ: "964",
    IE: "353",
    IM: "44-1624",
    IL: "972",
    IT: "39",
    JM: "1-876",
    JP: "81",
    JE: "44-1534",
    JO: "962",
    KZ: "7",
    KE: "254",
    KI: "686",
    KW: "965",
    KG: "996",
    LA: "856",
    LV: "371",
    LB: "961",
    LS: "266",
    LR: "231",
    LY: "218",
    LI: "423",
    LT: "370",
    LU: "352",
    MO: "853",
    MK: "389",
    MG: "261",
    MW: "265",
    MY: "60",
    MV: "960",
    ML: "223",
    MT: "356",
    MH: "692",
    MR: "222",
    MU: "230",
    YT: "262",
    MX: "52",
    FM: "691",
    MD: "373",
    MC: "377",
    MN: "976",
    ME: "382",
    MS: "1-664",
    MA: "212",
    MZ: "258",
    MM: "95",
    NA: "264",
    NR: "674",
    NP: "977",
    NL: "31",
    AN: "599",
    NC: "687",
    NZ: "64",
    NI: "505",
    NE: "227",
    NG: "234",
    NU: "683",
    NF: "672",
    KP: "850",
    MP: "1-670",
    NO: "47",
    OM: "968",
    PK: "92",
    PW: "680",
    PS: "970",
    PA: "507",
    PG: "675",
    PY: "595",
    PE: "51",
    PH: "63",
    PN: "870",
    PL: "48",
    PT: "351",
    PR: "1-787",
    QA: "974",
    RE: "262",
    RO: "40",
    RU: "7",
    RW: "250",
    BL: "590",
    SH: "290",
    KN: "1-869",
    LC: "1-758",
    MF: "590",
    PM: "508",
    VC: "1-784",
    WS: "685",
    SM: "378",
    ST: "239",
    SA: "966",
    SN: "221",
    RS: "381",
    SC: "248",
    SL: "232",
    SG: "65",
    SX: "1-721",
    SK: "421",
    SI: "386",
    SB: "677",
    SO: "252",
    ZA: "27",
    KR: "82",
    SS: "211",
    ES: "34",
    LK: "94",
    SD: "249",
    SR: "597",
    SJ: "47",
    SE: "46",
    CH: "41",
    SY: "963",
    TW: "886",
    TJ: "992",
    TZ: "255",
    TH: "66",
    TG: "228",
    TK: "690",
    TO: "676",
    TT: "1-868",
    TN: "216",
    TR: "90",
    TM: "993",
    TC: "1-649",
    TV: "688",
    UG: "256",
    UA: "380",
    AE: "971",
    GB: "44",
    US: "1",
    UY: "598",
    UZ: "998",
    VU: "678",
    VA: "379",
    VE: "58",
    VN: "84",
    WF: "681",
    EH: "212",
    YE: "967",
    ZM: "260",
    ZW: "263",
};

export const isEmptyObject = (obj: object) => {
    return Object.keys(obj).length === 0;
};

export const localStorageDateToTimestamp = (
    date: null | {
        seconds: number;
        nanoseconds: number;
    }
) => {
    if (!date) {
        return null;
    }
    const { seconds, nanoseconds } = date;

    if (seconds === undefined || nanoseconds === undefined) {
        return null;
    }

    return new Timestamp(seconds, nanoseconds);
};

export const isValidIsraeliPhoneNumber = (phone: string) => {
    return /^05[0-9]{8}$/.test(phone);
};

export const makeJerusalemTime = (date: Date, hour?: string) => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newHour = (hour ?? date.getHours()).toString().padStart(2, "0");
    const minute = "00";
    // const minute = date.getMinutes().toString().padStart(2, "0");

    const dateAsJerualem = moment.tz(
        `${year}-${month}-${day} ${newHour}:${minute}`,
        "YYYY-MM-DD HH:mm",
        "Asia/Jerusalem"
    );

    return dateAsJerualem.toDate();
};

export const timeInJerusalem = (date?: Date) => {
    return date ? moment(date).tz("Asia/Jerusalem").toDate() : undefined;
};

export const modulo = (n: number, m: number) => {
    return ((n % m) + m) % m;
};

export const convertPhoneToWhatsApp = (phone: string) => {
    const digitsOnly = phone.replace(/\D/g, "");
    const startsWithILCode = digitsOnly.startsWith("972");
    const startsWithMobile = digitsOnly.startsWith("05");

    if (startsWithILCode) {
        return digitsOnly;
    } else if (startsWithMobile) {
        return `972${digitsOnly.slice(1)}`;
    } else if (digitsOnly.startsWith("0")) {
        return `972${digitsOnly.slice(1)}`;
    } else if (digitsOnly.startsWith("5") && digitsOnly.length === 9) {
        return `972${digitsOnly}`;
    } else {
        return digitsOnly;
    }
};

export const isWhatsAppId = (phone: string) => {
    return /^9725[0-9]{8}$/.test(phone) && phone.length === 12;
};

export const formatDate = (date: Date | null | undefined) => {
    return date
        ? date.toLocaleDateString("he-IL", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
          })
        : null;
};

export const addDaysToDate = ({
    date,
    days,
    weekdaysOnly = false,
}: {
    date: Date;
    days: number;
    weekdaysOnly?: boolean;
}) => {
    let newDate = moment(date).add(days, "days");
    let dateInJerusalem = moment.tz(
        newDate.format("YYYY-MM-DD") + " 13:00",
        "Asia/Jerusalem"
    );
    const day = dateInJerusalem.day();
    const isAddOperation = days > 0;
    if (weekdaysOnly) {
        if (isAddOperation) {
            if (day === 6) {
                return dateInJerusalem.add(1, "days").toDate();
            } else {
                return dateInJerusalem.toDate();
            }
        } else {
            // if (day === 5) {
            //     return dateInJerusalem.add(-1, "days").toDate();
            // } else
            if (day === 6) {
                return dateInJerusalem.add(1, "days").toDate();
            } else {
                return dateInJerusalem.toDate();
            }
        }
    } else {
        return dateInJerusalem.toDate();
    }
};

export const formatPhoneNumber = ({ phoneNumber }: { phoneNumber: string }) => {
    const isIsraelyNumber = phoneNumber?.startsWith("972");
    return isIsraelyNumber
        ? "0" + phoneNumber.substring(3, 5) + "-" + phoneNumber.substring(5)
        : phoneNumber; // remove 972 and add 05X-XXXXXXX
};

export const getStatsFromGuests = ({
    guestsObject,
}: {
    guestsObject?: { [key: string]: Guest };
}) => {
    const guests = guestsObject
        ? Object.entries(guestsObject).map(([phone, guest]) => {
              return { phone, ...guest } as Guest & { phone: string };
          })
        : [];

    const results = guests.reduce(
        (acc, guest) => {
            acc.numOfGuests += guest.expectedAmount ?? 0;
            acc.numOfContacts += FILTERS.contacts(guest) ? 1 : 0;
            // guest.sendRSVP && guest.validPhoneNumber ? 1 : 0;
            acc.numOfArriving += FILTERS.arriving(guest)
                ? // guest.arriving === "yes" && guest.amount
                  guest.amount ?? 1
                : 0;
            acc.numOfMaybe += FILTERS.maybe(guest)
                ? guest.expectedAmount ?? 1
                : 0;
            acc.numOfNotArriving +=
                guest.arriving === "no"
                    ? guest.expectedAmount ?? 1
                    : guest.arriving === "yes" &&
                      guest.amount &&
                      guest.amount < guest.expectedAmount
                    ? guest.expectedAmount - guest.amount
                    : 0;
            acc.numOfNotSent += guest.sent ? 0 : 1;
            acc.numOfNotAnswered += FILTERS.notAnswered(guest) ? 1 : 0;
            // guest.sendRSVP &&
            // (guest.arriving === null ||
            //     (guest.arriving === "yes" && guest.amount === null))
            //     ? 1
            //     : 0;
            return acc;
        },
        {
            numOfGuests: 0,
            numOfArriving: 0,
            numOfMaybe: 0,
            numOfNotArriving: 0,
            numOfNotSent: 0,
            numOfNotAnswered: 0,
            numOfContacts: 0,
        }
    );

    return {
        guests,
        ...results,
    };
};

export const FILTERS = {
    all: (guest: Guest) => guest,
    arriving: (guest: Guest) =>
        guest.arriving === "yes" && guest.amount !== null,
    maybe: (guest: Guest) => guest.arriving === "maybe",
    notArriving: (guest: Guest) =>
        guest.arriving === "no" ||
        (guest.arriving === "yes" &&
            guest.amount &&
            guest.amount < guest.expectedAmount),
    notAnswered: (guest: Guest) =>
        guest.sendRSVP &&
        guest.validPhoneNumber &&
        (guest.arriving === null ||
            (guest.arriving === "yes" && guest.amount === null)),
    contacts: (guest: Guest) => guest.sendRSVP && guest.validPhoneNumber,
};

export const filterGuests = ({
    guests,
    filter,
}: {
    guests: Array<Guest & { phone: string }>;
    filter: keyof typeof FILTERS;
}) => {
    return guests
        .filter(FILTERS[filter])
        .sort((a, b) =>
            !a.name ? -1 : !b.name ? 1 : a.name.localeCompare(b.name)
        );
};

export const createSchedulesDates = ({
    weddingDate,
}: {
    weddingDate: Timestamp;
}) => {
    const weddingDateAsDate = weddingDate.toDate();
    const twoWeeksBefore = addDaysToDate({
        date: weddingDateAsDate,
        days: -14,
        weekdaysOnly: true,
    });

    const oneWeekBefore = addDaysToDate({
        date: weddingDateAsDate,
        days: -7,
        weekdaysOnly: true,
    });
    const oneDayBefore = addDaysToDate({
        date: weddingDateAsDate,
        days: -1,
        weekdaysOnly: true,
    });
    const oneDayAfter = addDaysToDate({
        date: weddingDateAsDate,
        days: 1,
        weekdaysOnly: true,
    });

    return {
        twoWeeksBefore,
        oneWeekBefore,
        oneDayBefore,
        oneDayAfter,
    };
};

export const valueToPrice = ({
    value,
    withSymbol = true,
}: {
    value: number;
    withSymbol?: boolean;
}) => {
    const formattedValue = Number.isInteger(value)
        ? value.toString()
        : value.toFixed(2);
    return `${formattedValue}${withSymbol ? ` ${NIS}` : ""}`;
};

export const changeUndefinedToNull = (obj: any) => {
    return JSON.parse(
        JSON.stringify(obj, (key, value) =>
            value === undefined ? null : value
        )
    );
};
